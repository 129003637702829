<div [ngSwitch]="klubmodulStatus">
  <div *ngSwitchCase="myKlubmodulStatus.KlubmodulStatusNone" title="Klubmodul status missing">
    <mat-icon>warning</mat-icon>
  </div>

  <div *ngSwitchCase="myKlubmodulStatus.Member">
    <mat-icon color="primary">person</mat-icon>
  </div>

  <div *ngSwitchCase="myKlubmodulStatus.NotMember" title="{{klubModulHelpers.getKmStatusText(klubmodulStatus)}}">
    <mat-icon color="accent">person_outline</mat-icon>
  </div>

  <div *ngSwitchCase="myKlubmodulStatus.Deleted" title="{{klubModulHelpers.getKmStatusText(klubmodulStatus)}}">
    <mat-icon color="warn">person_outline</mat-icon>
  </div>
  <div *ngSwitchDefault>{{klubmodulStatus}}</div>
</div>
