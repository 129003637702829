<div class="pageHeader">
  <h1>Resultater kredsoversigt Brugsprøver</h1>

  <div class="flex-container wrap">
    <app-dch-select style="flex-grow: 2; min-width: 350px" [(selectedValue)]="regionSelectedValue" [selectItems]="regionSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
    <app-dch-select style="flex-grow: 1; min-width: 250px" [(selectedValue)]="klasseSelectedValue" [selectItems]="klasseSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>

  <h4>Periode: {{startDate | dcHFullDate: endDate}}</h4>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="pivotResults"></app-dch-data-table>
</div>
