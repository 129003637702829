import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { Observable } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { HttpDataService } from '../../../services/http-data.service';

@Component({
  selector: 'app-admin-front-page-news',
  templateUrl: './front-page-news.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, AngularEditorModule, ReactiveFormsModule]
})
export class AdminFrontPageNewsComponent implements OnInit {
  htmlContent: string;
  htmlContentFormControl: FormControl;
  editorConfig: AngularEditorConfig = { editable: true, sanitize: true };
  disableSaveButton = true;

  constructor(readonly httpDataService: HttpDataService, private readonly dialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {
    const url = 'api/lkuInformation/GetInformation';
    this.httpDataService.getData<string>(url)
      .subscribe((response: string) => {
        this.htmlContentFormControl = new FormControl(response);
        this.htmlContentFormControl.valueChanges.subscribe(() => this.disableSaveButton = false);
        this.htmlContent = response
      });
  }

  saveClick(): void {
    const msg = 'Gem ændringer';
    this.dialogService.showDialog(msg, 'Forside nyheder', 'OkCancel')
      .subscribe(a => {
        if (a) {
          this.postText$(this.htmlContent).subscribe(() => this.disableSaveButton = true);
        }
      });
  }

  private postText$(text: string): Observable<number> {
    const url = 'api/lkuInformation/UpdateInformation';
    const content = { content: text };
    return this.httpDataService.postData<object, number>(url, content);
  }
}
