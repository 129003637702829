<div class="pageHeader">
  <h1>Forside nyheder</h1>
</div>

<div *ngIf="htmlContent" class="pageContent">
  <angular-editor [formControl]="htmlContentFormControl" [config]="editorConfig"></angular-editor>
</div>

<div style="margin-top: 1em; float: right">
  <button mat-raised-button color="primary" [disabled]="disableSaveButton || (httpDataService.loading$ | async)" (click)="saveClick()"><mat-icon>save</mat-icon> Gem</button>
</div>
