import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { DcHKreds, KonkurrenceType } from '../../../models.generated/dchEnums';
import { RallyDmCandidateResponse } from '../../../models.generated/rally';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { RallyDmCandidateWrapper } from './rally-dm-candidate-wrapper';

@Component({
  selector: 'app-rally-dm-candidates',
  templateUrl: './rally-dm-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, DcHDataTableComponent]
})
export class RallyDmCandidatesComponent implements OnInit {
  rallyDmCandidateResponse: RallyDmCandidateResponse;
  rallyDmCandidates: RallyDmCandidateWrapper[];
  regionSelectItems = new RegionHelpers().getRegionSelectItems(true);
  regionSelectedValue = this.regionSelectItems[0].value;
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.fetchData$().subscribe(a => {
      this.rallyDmCandidateResponse = a;
      this.rallyDmCandidates = this.getFilteredData(this.regionSelectItems[0].value);
    });
  }

  selectionChangeHandler(): void {
    this.rallyDmCandidates = this.getFilteredData(this.regionSelectedValue);
  }

  private fetchData$(): Observable<RallyDmCandidateResponse> {
    const url = 'api/RallyData/GetRallyDmCandidates';
    return this.httpDataService.getData<RallyDmCandidateResponse>(url);
  }

  private getFilteredData(dchKreds: DcHKreds): RallyDmCandidateWrapper[] {
    let candidates = this.rallyDmCandidateResponse.candidates;

    if (dchKreds !== DcHKreds.DcHLands) {
      candidates = this.rallyDmCandidateResponse.candidates.filter(a => a.dchEkvipage?.dchKreds === dchKreds);
    }

    return candidates.map(a => (a as any) as RallyDmCandidateWrapper);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.RA;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Point', field: 'dmResults[0].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Point', field: 'dmResults[1].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Point', field: 'dmResults[2].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Sum', field: 'sumPoint', textalign: 'center', bold: true, dataType: 'number', format: '1.0-0' },
      { headerText: 'Udtaget', field: 'dchEkvipage.ekvipageNote' }
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.RA;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Point', field: 'point', textalign: 'center', bold: true, dataType: 'number', format: '1.0-0' }
    ]

    return dataTable;
  }
}
