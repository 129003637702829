import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ModalResultComponent } from '../../../components.shared/modal-result/modal-result.component';
import { MatDialog,} from '@angular/material/dialog';
import { ModalResultDialogData } from '../../../models/modal-result-dialog-data';
import { LydighedCompetitionResponse, LydighedKlasseDataResults, LydighedResult } from '../../../models.generated/lydighed';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHLydighedKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { SelectItem } from '../../../models/select-item';
import { DcHLydighedKlasseHelpers } from '../../../helpers/dch-lydighed-klasse-helpers';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHDataTable } from '../../../models/dch-data-table';

@Component({
  selector: 'app-lydighed-results',
  templateUrl: './lydighed-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHKonkurrenceArtText, DcHSelectComponent, SelectCompetitionComponent, DcHDataTableComponent]
})
export class LydighedResultaterComponent implements OnInit {
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  competitionResponse: LydighedCompetitionResponse;
  klasseResults: LydighedKlasseDataResults | undefined;
  klasseSelectItems: SelectItem<DcHLydighedKlasse>[];
  klasseSelectedValue: DcHLydighedKlasse;
  dataTableDef = this.getDcHDataTable();
  private prevSelectedKonkId = 0;
  private dcHLydighedKlasseHelpers = new DcHLydighedKlasseHelpers();

  @Input()
  importMode = false;

  @Input()
  competitionResponses: LydighedCompetitionResponse[];

  constructor(private readonly dialog: MatDialog, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.klasseResults = this.competitionResponse.klasseDataResults[0];
      this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.dcHLydighedKlasseHelpers.getKlasseSelectItem(item.klasse));
      this.klasseSelectedValue = this.klasseSelectItems[0].value;
    }
  }

  selectionChangeHandler(): void {

    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasse === this.klasseSelectedValue);
    }
    else if (this.importMode) {
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.dcHLydighedKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      } else {
        this.klasseSelectItems = [];
        this.klasseSelectedValue = DcHLydighedKlasse.DcHLydighedKlasseNone;
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchLydighedCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.klasseResults = a.klasseDataResults[0];
        this.klasseSelectItems = a.klasseDataResults.map(item => this.dcHLydighedKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      });
    }
  }

  private openModal(data: LydighedResult): void {
    const modalResultDialogData: ModalResultDialogData = { ekvipage: data.dchEkvipage, eventId: this.competionDataSelectedValue.konkId, klasse: DcHLydighedKlasse[this.klasseSelectedValue], konkurrenceType: KonkurrenceType.LY, live: false };

    this.dialog.open(ModalResultComponent, {
      data: modalResultDialogData
    });
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Total', field: 'total', textalign: 'center', dataType: 'number', format: '1.2-2', fnCellClicked: (a: LydighedResult) => { this.openModal(a) } },
    ]

    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: LydighedCompetitionResponse[]) {
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId
      }
    });
  }
}
