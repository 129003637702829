import { DcHNoseWorkKlasse } from "../models.generated/dchEnums";
import { NoseWorkKlasseDataResults } from "../models.generated/nosework";
import { SelectItem } from "../models/select-item";
import { DcHNoseWorkKlasseHelpers } from "./dch-nosework-klasse-helpers";

export class NoseWorkResultaterHelpers {

  noseWorkKlasseHelpers = new DcHNoseWorkKlasseHelpers();

  constructor() {
  }

  getKlasseSelectItems(items: NoseWorkKlasseDataResults[], totalOnly: boolean): SelectItem<[DcHNoseWorkKlasse, number]>[] {
    let selectItems = new Array<SelectItem<[DcHNoseWorkKlasse, number]>>();
    const klasses = items.flatMap(a => a.klasse);
    const uniqueKlasses = Array.from(new Set(klasses));

    for (let i = 0; i < uniqueKlasses.length; i++) {
      for (let n = 0; n <= 4; n++) {
        if (totalOnly && n > 0) {
          continue;
        }

        let selectItem = new SelectItem<[DcHNoseWorkKlasse, number]>(this.noseWorkKlasseHelpers.getNoseWorkKlasseTextAndMomentText(uniqueKlasses[i], n), [uniqueKlasses[i], n]);
        selectItems.push(selectItem);
      }
    }

    return selectItems;
  }

  sortKlasseDataResults(klasseDataResults: NoseWorkKlasseDataResults, resIndex: number, sortOrder: 'placer' | 'startNo'): void {

    if (sortOrder == 'placer') {
      klasseDataResults.noseWorkResults = klasseDataResults.noseWorkResults.sort((a, b) => {
        if (a.noseWorkResultMoments[resIndex].placer > b.noseWorkResultMoments[resIndex].placer) {
          return 1;
        }

        if (a.noseWorkResultMoments[resIndex].placer < b.noseWorkResultMoments[resIndex].placer) {
          return -1;
        }

        return 0;
      });
    } else {
      klasseDataResults.noseWorkResults = klasseDataResults.noseWorkResults.sort((a, b) => {
        let startNoA = a.noseWorkResultMoments[resIndex].startNo as number;
        let startNoB = b.noseWorkResultMoments[resIndex].startNo as number;

        if (startNoA > startNoB) {
          return 1;
        }

        if (startNoA < startNoB) {
          return -1;
        }

        return 0;
      });
    }
  }
}
