import { KonkurrenceType } from "../models.generated/dchEnums";

export class WebSockectGroupInfo {
  constructor(konkurrenceType: KonkurrenceType, eventId: number) {
    this.konkurrenceType = konkurrenceType;
    this.eventId = eventId;
  }

  konkurrenceType: KonkurrenceType;
  eventId: number;
  action: 'liveResultater' = 'liveResultater';
}
