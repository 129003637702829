import { DcHNordiskKlasseHelpers } from './dch-nordisk-klasse-helpers';
import { DcHNoseWorkKlasseHelpers } from './dch-nosework-klasse-helpers';
import { DcHRallyKlasseHelpers } from './dch-rally-klasse-helpers';
import { DcHAgilityKlasseHelpers } from './dch-agility-klasse-helpers';
import { DcHHoopersKlasseHelpers } from './dch-hoopers-klasse-helpers';
import { KonkurrenceType } from '../models.generated/dchEnums';

export class DchKlasseHelpers {

  getKlasseText(klasse: string, konkurrenceType: KonkurrenceType | undefined,  shortFormat: boolean): string {

    switch (konkurrenceType) {
      case KonkurrenceType.AG:
        return new DcHAgilityKlasseHelpers().klasseKeyToText(klasse, shortFormat);
      case KonkurrenceType.HO:
        return new DcHHoopersKlasseHelpers().klasseKeyToText(klasse, shortFormat);
      case KonkurrenceType.NO:
        return new DcHNordiskKlasseHelpers().getDcHNordiskKlasseText(klasse);
      case KonkurrenceType.NW:
        return new DcHNoseWorkKlasseHelpers().getNoseWorkKlasseText(klasse);
      case KonkurrenceType.RA:
        return new DcHRallyKlasseHelpers().getRallyKlasseText(klasse);
      case KonkurrenceType.LY:
        if (shortFormat) {
          return klasse;
        }
        return klasse + ' klassen';
      case KonkurrenceType.BR:
        if (shortFormat) {
          return klasse;
        }
        return klasse + ' klassen';
      default:
        return klasse;
    }
  }
}
