import { CommonModule, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { DcHKonkurrenceArt } from '../../../models.generated/dchEnums';
import { DcHCompetitionDataResponse } from '../../../models.generated/misc';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { HttpDataService } from '../../../services/http-data.service';
import { MatSnackBarService } from '../../../services/mat-snack-bar.service';
import { KonkurrenceTypeHelpers } from '../../../helpers/konkurrenceTypeHelpers';
import { AppMaterialModule } from '../../../app-material.module';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';

@Component({
  selector: 'app-admin-konkurrencer',
  templateUrl: './konkurrencer.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHDataTableComponent, AppMaterialModule]
})
export class AdminKonkurrencerComponent implements OnInit {
  konkurrenceTypeHelpers = new KonkurrenceTypeHelpers();
  dchCompetitionDatas: DcHCompetitionData[] | undefined;
  dcHKonkurrenceArt: DcHKonkurrenceArt;
  yearSelectItems: SelectItem<number>[];
  yearSelectedValue: number;
  dataTableDef = this.getDcHDataTable();

  constructor(readonly httpDataService: HttpDataService, private readonly matSnackBarService: MatSnackBarService, private readonly dialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.yearSelectItems = this.getYearSelectItems();
    this.yearSelectedValue = this.yearSelectItems[0].value;

    this.fetchData$(this.yearSelectedValue)
      .subscribe(a => {
        this.dchCompetitionDatas = a.competitionDatas;
      });
  }

  deleteClicked(konkurrenceData: DcHCompetitionData): void {
    if (konkurrenceData == undefined || konkurrenceData.konkurrenceDato == undefined) {
      return;
    }

    const konkurrenceType = this.konkurrenceTypeHelpers.getKonkurrenceTypeText(konkurrenceData.konkurrenceType);
    const myDate = new Date(konkurrenceData.konkurrenceDato.seconds * 1000);
    const dato = formatDate(myDate, 'dd. MMM yyyy', 'da-DK');
    const konkInfo = `${konkurrenceType} i ${konkurrenceData.forening} d. ${dato}`;

    this.dialogService.showDialog([konkInfo, 'Slet konkurrence'], 'Admin konkurrencer', 'OkCancel')
      .subscribe(a => {
        if (a) {
          this.deleteCompetition$(konkurrenceData.konkId)
            .subscribe(() => {
              this.dchCompetitionDatas = this.dchCompetitionDatas?.filter(a => a.konkId != konkurrenceData.konkId);
              this.matSnackBarService.showOkMsg(`${konkInfo} slettet`);
            });
        }
      });
  }

  selectionChangeHandler(): void {
    this.dchCompetitionDatas = undefined;
    this.fetchData$(this.yearSelectedValue)
      .subscribe(a => {
        this.dchCompetitionDatas = a.competitionDatas;
      });
  }

  private fetchData$(year: number): Observable<DcHCompetitionDataResponse> {
    const url = `api/KonkurrenceData/GetCompetitionDatasByYear/${year}`;
    return this.httpDataService.getData<DcHCompetitionDataResponse>(url);
  }

  private getYearSelectItems(): SelectItem<number>[] {
    const minYear = 2020;
    const maxYear = new Date().getFullYear();
    const yearArr = new Array<SelectItem<number>>();

    for (let i = maxYear; i >= minYear; i--) {
      yearArr.push(new SelectItem(i.toString(), i));
    }

    return yearArr;
  }

  private getDcHDataTable(): DcHDataTable {
    const dataTable = new DcHDataTable();
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '', field: 'delete', dataType: 'rowCommand', width: '15px', fnRowCommandClicked: (data: DcHCompetitionData) => this.deleteClicked(data) },
      { headerText: 'Dato', field: 'konkurrenceDato', dataType: 'timeStamp', textalign: 'center', freeze: true, format: 'd.MMM' },
      { headerText: 'Arrangør', field: 'forening' },
      { headerText: 'Konkurrencenavn', field: 'konkurrenceNavn', width: '30%', whiteSpace: 'normal' },
      { headerText: 'Type', field: 'konkurrenceType', dataType: 'dchKonkurrenceType', hideSmallScreen: true },
      { headerText: 'Art', field: 'konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true }
    ]

    return dataTable;
  }

  private deleteCompetition$(konkId: number): Observable<number> {
    const url = `api/KonkurrenceData/deleteCompetition/${konkId}`;
    return this.httpDataService.deleteData<number>(url);
  }
}
