import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { ImportFileResponse } from '../../../models.generated/importfile';
import { DcHFileModel } from '../../../models.generated/misc';
import { HttpDataService } from '../../../services/http-data.service';
import { AgilityResultaterComponent } from '../../agility/agility-resultater/agility-resultater.component';
import { BrugsResultaterComponent } from '../../brugs/brugs-resultater/brugs-resultater.component';
import { HoopersResultaterComponent } from '../../hoopers/hoopers-resultater/hoopers-resultater.component';
import { LydighedResultaterComponent } from '../../lydighed/lydighed-resultater/lydighed-resultater.component';
import { NordiskResultaterComponent } from '../../nordisk/nordisk-resultater/nordisk-resultater.component';
import { NoseWorkResultaterComponent } from '../../nosework/nosework-resultater/nosework-resultater.component';
import { RallyResultaterComponent } from '../../rally/rally-resultater/rally-resultater.component';
import { SelectDcHFileComponent } from '../select-dchfile/select-dchfile.component';

@Component({
  selector: 'app-admin-import-konkurrencer',
  templateUrl: './import-konkurrencer.component.html',
  standalone: true,
  imports: [CommonModule, SelectDcHFileComponent, AgilityResultaterComponent, BrugsResultaterComponent, LydighedResultaterComponent, NordiskResultaterComponent, NoseWorkResultaterComponent, RallyResultaterComponent, HoopersResultaterComponent]
})
export class AdminImportKonkurrencerComponent {
  width: number;
  height: number;
  selectedIndex = -1;
  importFileResponse: ImportFileResponse | undefined;

  constructor(readonly httpDataService: HttpDataService) {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  }

  updateData(dchFile: DcHFileModel): void {
    this.importFileResponse = undefined;

    if (dchFile?.competitionData == undefined) {
      console.error(dchFile);
      return;
    }

    this.selectedIndex = -1;
  
    if (dchFile === undefined) {
      return;
    }

    const url = `api/DcHFile/GetCompetitionResults/${dchFile.year}/${dchFile.competitionData.konkurrenceType}/${dchFile.fileName}`;

    this.httpDataService.getData<ImportFileResponse>(url)
      .subscribe(response => {
        this.importFileResponse = response;
      });
  }
}
