<div class="pageHeader">
  <h1>Admin live konkurrencer</h1>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="dcHLiveDataTokens" [rowMenuItems]="rowMenuItems"></app-dch-data-table>
</div>

<ng-template #rowMenuItems let-liveDataToken>
  <button mat-menu-item *ngIf="!liveDataToken.active" (click)="togleStatus(liveDataToken)"><mat-icon>check_circle</mat-icon>Aktiver</button>
  <button mat-menu-item *ngIf="liveDataToken.active" (click)="togleStatus(liveDataToken)"><mat-icon>close</mat-icon>De-aktiver</button>
  <button mat-menu-item (click)="copyClipBoardClicked(liveDataToken)"><mat-icon>file_copy</mat-icon>Kopier token</button>
  <button mat-menu-item (click)="newTokenClicked(liveDataToken)"><mat-icon>autorenew</mat-icon>Ny token</button>
</ng-template>
