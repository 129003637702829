<div class="pageHeader">
  <h1>Stamdata fra dch klubmodul</h1>
</div>

<div class="pageBody">
  <mat-form-field>
    <input #searchBox="ngModel" id="searchBox" matInput type="text" (keyup)="search(searchBox.value)" required minlength="3" [(ngModel)]="searchText" />
    <mat-icon matSuffix>search</mat-icon>
    <mat-error *ngIf="(searchBox.invalid && (searchBox.dirty || searchBox.touched)) && searchBox.errors?.['minlength']">
      min. 3 tegn
    </mat-error>
  </mat-form-field>

  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="stamDatas" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="flex-container">
    <table>
      <tr><td><b>KlubModul status:</b></td><td>{{klubModulHelpers.getKmStatusText(data.klubModulStatus)}}</td></tr>
      <tr><td><b>Race:</b></td><td>{{data.race}}</td></tr>
      <tr><td><b>Chipnr:</b></td><td>{{data.dogChipNumber}}</td></tr>
    </table>
    <table>
      <tr><td><b>Agility:</b></td><td>{{data | agilityPropertiesText}}</td></tr>
      <tr><td><b>Hoopers:</b></td><td>{{data.hoopersSize | hoopersSizeText}}</td></tr>
      <tr><td><b>Rally:</b></td><td>{{data.rallyHeight | rallyHeightText}}</td></tr>
    </table>
  </div>
</ng-template>

