<div class="pageHeader">
  <h1 *ngIf="ekvipageResultsResponse != undefined && ekvipageResultsResponse.dchEkvipage != undefined">
    <span class="hide-sm">Resultater for </span>
    {{ekvipageResultsResponse.dchEkvipage.hundeforer}} m. {{ekvipageResultsResponse.dchEkvipage.hund}}
  </h1>
  <div class="flex-container">
    <app-select-konkurrence-type #selectKonkurrenceType (selectionChange)="updateData($event);"></app-select-konkurrence-type>
  </div>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="competitionResults"></app-dch-data-table>
</div>
