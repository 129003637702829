export class MenuSection {
  constructor(text: string) {
    this.text = text;
  }

  text: string;
  menuItems: MenuItem[];
  isSelected: boolean;
}

export class MenuItem {

  constructor(text: string, routerLink: string) {
    this.text = text;
    this.routerLink = routerLink;
  }

  text: string;
  routerLink: string;
  isSelected: boolean;
}
