import { Pipe, PipeTransform } from '@angular/core';
import { AgilitySize, AgilityAlder } from '../models.generated/dchEnums';
import { DcHEkvipage } from '../models.generated/shared';

@Pipe({
    name: 'agilityPropertiesText',
    standalone: true
})
export class AgilityPropertiesText implements PipeTransform {
  transform(dchEkvipage: DcHEkvipage): string {

    if (dchEkvipage.agilitySize == undefined || dchEkvipage.agilitySize === AgilitySize.AgilitySizeNone) {
      return '';
    }

    let text = AgilitySize[dchEkvipage.agilitySize];

    const dogKeeperBirthday = dchEkvipage.dogKeeperBirthDate == undefined ? undefined : new Date(dchEkvipage.dogKeeperBirthDate.seconds * 1000);
    const dogBirthDate = dchEkvipage.dogBirthDate == undefined ? undefined : new Date(dchEkvipage.dogBirthDate.seconds * 1000);

    const allowedAgilityAlders = this.getAllowedAgilityAlders(dogKeeperBirthday, dogBirthDate);

    for (const agilityAlder of allowedAgilityAlders) {

      if (text !== '') {
        text += ', ';
      }

      text += AgilityAlder[agilityAlder];
    }

    return text;
  }

  private getAllowedAgilityAlders(dogKeeperBirthday: Date | undefined, dogBirthDay: Date | undefined): AgilityAlder[] {

    const agilityAlders: AgilityAlder[] = [];
    const hfAge = dogKeeperBirthday == undefined ? 0 : this.getAge(dogKeeperBirthday);

    if (hfAge >= 8 && hfAge <= 18) {

      agilityAlders.push(AgilityAlder.Junior);
    }

    if (this.isDogSenior(dogBirthDay)) {

      agilityAlders.push(AgilityAlder.Senior);
    }

    return agilityAlders;
  }

  private isDogSenior(birthDate: Date | undefined): boolean {
    if (birthDate == undefined) {
      return false;
    }

    const year = birthDate.getFullYear();
    const month = birthDate.getMonth();
    const day = birthDate.getDay();
    return new Date(year + 7, month - 1, day) <= new Date();
  }

  private getAge(birthDate: Date): number {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }
}
