import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../app-material.module';
import { WebSocketService } from '../../services/web-socket-service';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-hub-connection-status',
  templateUrl: './hub-connection-status.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule]
})
export class HubConnectionStatusComponent implements OnInit, OnDestroy {

  HubConnectionState = signalR.HubConnectionState;
  hubConnectionState: signalR.HubConnectionState | undefined;

  private stopSubscribtion$ = new Subject<void>();

  constructor(readonly webSocketService: WebSocketService) {
  }

  ngOnInit(): void {
    this.getHubConnectionState$().pipe(takeUntil(this.stopSubscribtion$)).subscribe();
  }

  ngOnDestroy(): void {
    this.stopSubscribtion$.next();
    this.stopSubscribtion$.complete();
  }

  private getHubConnectionState$(): Observable<signalR.HubConnectionState | undefined> {
    return this.webSocketService.hubConnectedState$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(a => this.hubConnectionState = a)
      );
  }
}
