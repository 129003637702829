import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../components.shared/dch-data-table/dch-data-table.component';
import { KonkurrenceType } from '../../models.generated/dchEnums';
import { ResultLineResponse } from '../../models.generated/shared';
import { DcHDataTable } from '../../models/dch-data-table';
import { ModalResultDialogData } from '../../models/modal-result-dialog-data';
import { HttpDataService } from '../../services/http-data.service';

@Component({
  selector: 'app-modal-results',
  templateUrl: './modal-result.component.html',
  styleUrls: ['./modal-result.component.css'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule, DcHDataTableComponent]
})
export class ModalResultComponent implements OnInit {
  tableHeader: string;
  resultLineResponse: ResultLineResponse | undefined;
  myData: ModalResultDialogData
  dataTableDef: DcHDataTable;

  constructor(@Inject(MAT_DIALOG_DATA) data: ModalResultDialogData,
    readonly httpDataService: HttpDataService) {
    this.myData = data;
  }

  ngOnInit(): void {
    this.dataTableDef = this.getDcHDataTable(this.myData.klasse, this.myData.konkurrenceType);;
    this.tableHeader = this.getHeaderText(this.myData.konkurrenceType);

    if (this.myData.ekvipage == undefined) {
      throw new Error('Ekvipage is undefined');
    }

    this.fetchData$(this.myData.live, this.myData.konkurrenceType, this.myData.eventId, this.myData.ekvipage.profileId, this.myData.ekvipage.dogProfileId, this.myData.klasse)
      .subscribe(a => 
        this.resultLineResponse = a
      );
  }

  private getHeaderText(konkType: KonkurrenceType): string {
    if (konkType === KonkurrenceType.AG || konkType === KonkurrenceType.RA) {
      return 'Resultat';
    }

    return 'Øvelse';
  }

  private fetchData$(live: boolean, konkurrenceType: KonkurrenceType, eventId: number, profileId: number, dogProfileId: number, klasse: string): Observable<ResultLineResponse> {
    const url = `${this.getBaseUrl(konkurrenceType, live)}/${eventId}/${profileId}/${dogProfileId}/${klasse}`;
    return this.httpDataService.getData<ResultLineResponse>(url);
  }

  private getBaseUrl(konkurrenceType: KonkurrenceType, live: boolean): string {

    if (live) {
      return 'api/LiveResults/GetLiveResultLines/';
    }

    const baseUrl = this.httpDataService.getControllerBaseUrl(konkurrenceType);
    return `${baseUrl}/GetResultLines/`;
  }

  private getDcHDataTable(klasse: string, konkType: KonkurrenceType): DcHDataTable {
    const headerText = konkType === KonkurrenceType.AG || konkType === KonkurrenceType.RA ? 'Resultat' : 'Øvelse';
    const dataTable = new DcHDataTable();
    dataTable.konkurrenceType = konkType;
    dataTable.color = 'secondary';
    dataTable.cssClass = 'modalResult';
    
    dataTable.columns = [
      { headerText: headerText, field: 'text'},
      { headerText: klasse, field: 'result', textalign: 'center', fnHeaderFormatter: () => '#'},
    ]

    return dataTable;
  }
}
