<div style="display: flex; justify-content: center; align-items: center; height: 60vh ">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Admin login</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()">
        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <mat-form-field appearance="fill">
            <mat-label>Username</mat-label>
            <input type="text" matInput autocomplete="username" formControlName="userName">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input type="password" matInput required autocomplete="current-password" formControlName="passWord">
          </mat-form-field>

          <mat-card-actions align="end">
            <button style="min-width: 100px" type="submit" mat-raised-button color="primary" [disabled]="!loginFormGroup.valid || submitClick">Login</button>
          </mat-card-actions>
          <mat-error *ngIf="error">
            <mat-icon>error_outline</mat-icon>Login fejlede
          </mat-error>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
