<div class="pageHeader">
  <h1 *ngIf="!importMode">DcH Agility konkurrence resultater</h1>
  <div class="flex-container wrap">
    <app-select-competition class="competitionSelector" baseUrl="/agilityResultater" [importMode]="importMode" konkurrenceType="AG" [(selectedValue)]="competionDataSelectedValue" [selectItems]="competionDataSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-select-competition>
    <app-dch-select class="dchKlasseSelector" [(selectedValue)]="klasseSelectedValue" [selectItems]="klasseSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>
  <div *ngIf="competionDataSelectedValue != undefined && competionDataSelectedValue.konkId>0 && klasseResults != undefined">
    <h2>
      {{competionDataSelectedValue.konkurrenceNavn}} ({{competionDataSelectedValue.konkurrenceArt | dcHKonkurrenceArtText}})
    </h2>
    <h4>
      <span *ngIf="klasseResults.dommer">{{klasseResults.dommer}}</span> -
      Banetid/MBT: {{klasseResults.baneTid | dcHDuration: '1.0-0'}}/{{klasseResults.maxBaneTid | dcHDuration: '1.0-0'}}s  -
      Længde: <span *ngIf="klasseResults.baneLaengde">{{klasseResults.baneLaengde}}m</span>
    </h4>
  </div>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="klasseResults?.agilityResult"></app-dch-data-table>
</div>
