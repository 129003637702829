<body>
<div style="height: 90vh;">
  <mat-toolbar style="background-color: black; color: WhiteSmoke">
    <mat-toolbar-row>
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon class="navicon">{{sidenav.opened ? 'close' : 'menu'}}</mat-icon>
      </button>
      <div>DcH Danmark</div>
      <button mat-button *ngIf="noOfNotImportedFiles$ | async as noOfNotImportedFiles" [routerLink]='["/admin/importKonkurrencer"]'>
        <span [matBadgeHidden]="noOfNotImportedFiles===0" matBadge="{{noOfNotImportedFiles}}" matBadgeColor="accent" matBadgePosition="before">&nbsp;</span>
      </button>
      <div style="flex: 1 1 auto"></div>
      <a class="loginLink" [routerLink]='["/admin/login"]'>admin</a>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="app-container" [ngStyle]="{'height.px': height}">
    <mat-sidenav #sidenav [mode]="sidenavMode" class="navigationMenu">
      <mat-nav-list>
        <mat-list-item class="parent" (click)="onHomeClick()">
          <mat-icon class="navicon" fontIcon="home"></mat-icon>
        </mat-list-item>

        <div *ngFor="let menuSection of menuSections; let i = index">
          <mat-list-item (click)="onMenuSectionClick(menuSection)" class="parent">
            <span class="full-width">{{menuSection.text}}</span>
            <mat-icon class="menu-button" [ngClass]="{'rotated' : menuSection.isSelected}">expand_more</mat-icon>
          </mat-list-item>

          <div class="submenu" [ngClass]="{'expanded' : menuSection.isSelected}">
            <div *ngFor="let menuItem of menuSection.menuItems;">
              <mat-list-item (click)="onMenuItemClick(menuItem)" [ngClass]="{'menuItemSelected': menuItem.isSelected }">{{menuItem.text}}</mat-list-item>
            </div>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-http-error></app-http-error>
      <div class="pageContainer">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
</body>
