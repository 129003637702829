import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorService } from '../../services/http-error.service';
import { MatSnackBarService } from '../../services/mat-snack-bar.service';

@Component({
    selector: 'app-http-error',
    template: '',
    standalone: true
})

export class HttpErrorComponent {

  alertMsg: string;
  subscribtion: Subscription;

  constructor(readonly httpErrorService: HttpErrorService, private readonly matSnackBarService: MatSnackBarService) {

    this.subscribtion = httpErrorService.errorMsg$.subscribe(
      (errorMsg: string) => {
        if (errorMsg?.length > 1) {
          this.matSnackBarService.showErrorMsg(errorMsg);
        } else {
          this.matSnackBarService.dismissAll();
        }
      }
    );
  }
}
