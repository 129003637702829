import { DcHRallyKlasse } from "../models.generated/dchEnums";
import { SelectItem } from "../models/select-item";

export class DcHRallyKlasseHelpers {
  getKlasseSelectItem(klasse: DcHRallyKlasse): SelectItem<DcHRallyKlasse> {
    return new SelectItem<DcHRallyKlasse>(this.getRallyKlasseText(klasse), klasse);
  }

  getKlasseSelectItems(): SelectItem<DcHRallyKlasse>[] {
    let dcHLydighedKlasseHelpers = new DcHRallyKlasseHelpers();

    return [
      new SelectItem<DcHRallyKlasse>(dcHLydighedKlasseHelpers.getRallyKlasseText(DcHRallyKlasse.Beginner), DcHRallyKlasse.Beginner),
      new SelectItem<DcHRallyKlasse>(dcHLydighedKlasseHelpers.getRallyKlasseText(DcHRallyKlasse.Practiced), DcHRallyKlasse.Practiced),
      new SelectItem<DcHRallyKlasse>(dcHLydighedKlasseHelpers.getRallyKlasseText(DcHRallyKlasse.Expert), DcHRallyKlasse.Expert),
      new SelectItem<DcHRallyKlasse>(dcHLydighedKlasseHelpers.getRallyKlasseText(DcHRallyKlasse.Champion), DcHRallyKlasse.Champion),
      new SelectItem<DcHRallyKlasse>(dcHLydighedKlasseHelpers.getRallyKlasseText(DcHRallyKlasse.Open), DcHRallyKlasse.Open)
    ];
  }

  stringToRallyKlasse(klasse: string): DcHRallyKlasse {
    return DcHRallyKlasse[klasse as keyof typeof DcHRallyKlasse];
  }

  getRallyKlasseText(rallyKlasse: string | DcHRallyKlasse): string {

    let myKlasse = typeof rallyKlasse === "string" ? DcHRallyKlasse[rallyKlasse as keyof typeof DcHRallyKlasse] : rallyKlasse;

    switch (myKlasse) {
      case DcHRallyKlasse.Beginner:
        return 'Begynder';
      case DcHRallyKlasse.Practiced:
        return 'Øvede';
      case DcHRallyKlasse.Expert:
        return 'Ekspert';
      case DcHRallyKlasse.Champion:
        return 'Champion';
      case DcHRallyKlasse.Open:
        return 'Åben';
      case DcHRallyKlasse.DMTotal:
        return 'Vinder DM';
      case DcHRallyKlasse.DM1Run1:
        return 'DM 1. løb dag';
      case DcHRallyKlasse.DM1Run2:
        return 'DM 2. løb dag 1';
      case DcHRallyKlasse.DMFinale:
        return 'DM finaleløb dag 2';
      case DcHRallyKlasse.DM1Sum:
        return 'DM total dag 1';
      default:
        return 'ukendt: ' + rallyKlasse?.toString();
    }
  }
}
