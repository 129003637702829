import { SelectItem } from "../models/select-item";

export class DcHAgilityKlasseHelpers {

  getKlasseSelectValue(items: SelectItem<string>[], currentItem: string): string {
    if (items.length === 0) {
      return '';
    }

    let myKlasse = items.find(a => a.value === currentItem)
    if (myKlasse == undefined) {
      return items[0].value;
    }
    else {
      return myKlasse.value;
    }
  }

  getKlasseSelectItem(klasseKey: string): SelectItem<string> {
    let displayText = this.klasseKeyToText(klasseKey, false);
    return new SelectItem(displayText, klasseKey);
  }

  klasseKeyToText(klasseKey: string, shortFormat: boolean): string {

    let klasse: string;

    if (shortFormat) {
      return DcHAgilityKlasseHelpers.klasseKeyToTextShortFormat(klasseKey);
    }
    if (klasseKey.includes('Agility')) {
      klasse = 'Agility';
    } else if (klasseKey.includes('Spring')) {
      klasse = 'Spring';
    } else if (klasseKey.includes('B2O')) {
      klasse = 'Bedst i 2 åbne';
    } else if (klasseKey.includes('DMAFinale')) {
      klasse = 'DM A finale';
    } else if (klasseKey.includes('DMBFinale')) {
      klasse = 'DM B finale';
    } else {
      return klasseKey;
    }

    if (klasseKey.includes('Klasse1')) {
      klasse += ' 1';
    } else if (klasseKey.includes('Klasse2')) {
      klasse += ' 2';
    } else if (klasseKey.includes('Klasse3')) {
      klasse += ' 3';
    } else if (klasseKey.includes('KlasseÅben')) {
      klasse += ' Åben';
    } else if (klasseKey.includes('B2O')) {
    } else if (klasseKey.includes('DMAFinale')) {
    } else if (klasseKey.includes('DMBFinale')) {
    } else {
      return klasseKey;
    }

    if (klasseKey.includes('X'))
    {
      klasse += 'X';
    }

    if (klasseKey.includes('Junior')) {
      klasse += ' Junior';
    } else if (klasseKey.includes('Senior')) {
      klasse += ' Senior';
    }

    if (klasseKey.includes('Lille')) {
      klasse += ' (Small)';
    } else if (klasseKey.includes('Mellem')) {
      klasse += ' (Medium)';
    }
    else if (klasseKey.includes('Intermediate')) {
      klasse += ' (Intermediate)';
    } else if (klasseKey.includes('Stor')) {
      klasse += ' (Large)';
    } else {
      return klasseKey;
    }

    return klasse;
  }

  private static klasseKeyToTextShortFormat(klasseKey: string): string {
    return klasseKey;
  }
}
