import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KonkurrenceType } from '../../models.generated/dchEnums';
import { CommonModule } from '@angular/common';
import { DcHSelectComponent } from '../dch-select/dch-select.component';
import { KonkurrenceTypeHelpers } from '../../helpers/konkurrenceTypeHelpers';

@Component({
  selector: 'app-select-konkurrence-type',
  templateUrl: './select-konkurrence-type.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent]
})
export class SelectKonkurrenceTypeComponent implements OnInit {

  selectItems = new KonkurrenceTypeHelpers().getKonkurrenceTypeSelectItems();
  selectedValue: KonkurrenceType;

  @Output()
  selectionChange = new EventEmitter<KonkurrenceType>();

  constructor(private readonly activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .subscribe(params => {
        const kType = params.get('konkurrenceType');

        if (kType != null && kType in KonkurrenceType) {
          const mySelectItem = this.selectItems.find(a => a.value === (KonkurrenceType as any)[kType])?.value;
          this.selectedValue = mySelectItem != undefined ? mySelectItem : this.selectItems[0].value;
        } else {
          this.selectedValue = this.selectItems[0].value;
        }

        this.selectionChange.emit(this.selectedValue);
      });
  }

  onSelectionChange(): void {
    this.selectionChange.emit(this.selectedValue);
  }
}
