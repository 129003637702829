<div *ngIf="lydighedETurCandidateResponse$ | async as lydighedETurCandidateResponse" class="pageHeader">
  <h1>Udtagelseplaceringer til E turnering</h1>
  <h4>Udtagelsesperiode: {{lydighedETurCandidateResponse.startDate | dcHFullDate: lydighedETurCandidateResponse.endDate}}</h4>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="eTurCandidates" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="dchTableResDetailsContainer">
    <app-dch-data-table [dataTable]="getDcHDataTableDetails()" [dataSource]="data?.eResults"></app-dch-data-table>
  </div>
</ng-template>
