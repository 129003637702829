import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../app-material.module';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { ConvertNewLines } from '../../pipes/convert-new-lines.pipe';

@Component({
  selector: 'app-live-message',
  templateUrl: './live-message.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule, SafeHtmlPipe, ConvertNewLines]
})
export class LiveMessageComponent implements OnInit, OnDestroy {

  @Input()
  liveText: string | undefined;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }
}
