import { DcHKreds } from "../models.generated/dchEnums";
import { SelectItem } from "../models/select-item";

export class RegionHelpers {

  getRegionSelectItems(includeLands: boolean): SelectItem<DcHKreds>[] {

    let items = [
      new SelectItem<DcHKreds>('Lands', DcHKreds.DcHLands),
      new SelectItem<DcHKreds>('Kreds 1', DcHKreds.Kreds1),
      new SelectItem<DcHKreds>('Kreds 2', DcHKreds.Kreds2),
      new SelectItem<DcHKreds>('Kreds 3', DcHKreds.Kreds3),
      new SelectItem<DcHKreds>('Kreds 4', DcHKreds.Kreds4),
      new SelectItem<DcHKreds>('Kreds 5', DcHKreds.Kreds5),
      new SelectItem<DcHKreds>('Kreds 6', DcHKreds.Kreds6)
    ];

    if (includeLands) {
      return items;
    }

    return items.splice(1);
  }
}
