<div class="pageHeader">
  <h1 *ngIf="!importMode">DcH Nose Work konkurrence resultater</h1>

  <div class="flex-container wrap">
    <app-select-competition class="competitionSelector" baseUrl="/noseWorkResultater" [importMode]="importMode" konkurrenceType="NW" [(selectedValue)]="competionDataSelectedValue" [selectItems]="competionDataSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-select-competition>
    <app-dch-select class="dchKlasseSelector" [(selectedValue)]="klasseSelectedValue" [selectItems]="klasseSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>

  <h2 *ngIf="competionDataSelectedValue != undefined && competionDataSelectedValue.konkId>0">
    {{competionDataSelectedValue.konkurrenceNavn}} ({{competionDataSelectedValue.konkurrenceArt | dcHKonkurrenceArtText}})
  </h2>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="klasseResults"></app-dch-data-table>
</div>
