
<div style="width: 250px; margin-bottom: 1em">
  <app-dch-select [(selectedValue)]="yearSelectedValue" [selectItems]="yearSelectItems" (selectedValueChange)="selectedYearChangeHandler()"></app-dch-select>
</div>

<div class="pageBody iosWorkAround" [ngStyle]="{'height.px': height}" style="overflow: auto">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="dchFiles" [rowMenuItems]="rowMenuItems" (selectedChanged)="selectedDcHFileChangeHandler($event)"></app-dch-data-table>
</div>

<ng-template #rowMenuItems let-data>
  <button mat-menu-item color="primary" (click)="importFileClicked(data)"><mat-icon>insert_drive_file</mat-icon> Indlæs</button>
  <button mat-menu-item (click)="downloadJsonFileClicked(data)"><mat-icon>pageview</mat-icon> Vis</button>
  <button mat-menu-item (click)="downloadDcHFileClicked(data)"><mat-icon>cloud_download</mat-icon> Download</button>
  <button mat-menu-item color="warn" (click)="deleteClicked(data)"><mat-icon>delete</mat-icon> Slet</button>
</ng-template>

