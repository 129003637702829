import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { HubConnectionStatusComponent } from '../../../components.shared/hub-connection-status/hub-connection-status.component';
import { LiveMessageComponent } from '../../../components.shared/live-message/live-message.component';
import { AgilityKlasseDataResults, AgilityLiveDataResponse } from '../../../models.generated/agility';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHDurationPipe } from '../../../pipes/dch-duration.pipe';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { WebSocketService } from '../../../services/web-socket-service';
import { AgilityLiveResultaterHelpers } from './agility-live-resultater.helpers';

@Component({
  selector: 'app-agility-live-results',
  templateUrl: './agility-live-resultater.component.html',
  standalone: true,
  imports: [CommonModule, MatRadioModule, DcHKonkurrenceArtText, LiveMessageComponent, DcHSelectComponent, HubConnectionStatusComponent, DcHDurationPipe, DcHDataTableComponent]
})
export class AgilityLiveResultaterComponent implements OnInit, OnDestroy {
  liveDataResponse: AgilityLiveDataResponse | undefined;
  competionDataSelectItems = new Array<SelectItem<DcHCompetitionData>>();
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<string>[];
  klasseSelectedValue = '';
  klasseResults: AgilityKlasseDataResults | undefined;
  sortField: 'placer' | 'startNo' = 'placer';
  dataTableDef = this.getDcHDataTable(this.sortField);
  private prevSelectedEventId = 0;
  private liveResultaterHelpers = new AgilityLiveResultaterHelpers();

  constructor(private readonly location: Location, private readonly webSocketService: WebSocketService, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    this.webSocketService.connectToHub$().subscribe();

    this.competitionDataService.fetchLiveCompetitionDatasAsSelectItems$(KonkurrenceType.AG)
      .pipe(
        mergeMap(selectItems => {
          if (selectItems.length > 0) {
            this.competionDataSelectItems = selectItems;
            this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
            this.prevSelectedEventId = this.competionDataSelectedValue.dchEventId;
            return this.webSocketService.initLiveResultater$(KonkurrenceType.AG, this.competionDataSelectedValue.dchEventId);
          }
          else {
            this.klasseResults = this.getEmptyKlasseResults();
            this.competionDataSelectItems = [];
            return of();
          }
        })
      ).subscribe();

    this.webSocketService.agilityLiveDataUpdated$
      .subscribe(liveDataReply => {
        console.log('AgilityLiveDataUpdated: ' + liveDataReply.liveDataResponse?.dchEventId);
        this.liveDataResponse = liveDataReply;
        this.klasseSelectItems = liveDataReply.klasseDataResults.map(item => this.liveResultaterHelpers.getKlasseSelectItem(item.klasseKey));
        this.klasseSelectedValue = this.liveResultaterHelpers.getKlasseSelectValue(this.klasseSelectItems, this.klasseSelectedValue);
        this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
      });
  }

  async ngOnDestroy(): Promise<void> {
    this.webSocketService.unsubscribeLiveResultater$().subscribe();
  }

  selectionChangeHandler(): void {
    let currentEventId = this.competionDataSelectedValue.dchEventId;
    if (this.prevSelectedEventId === currentEventId) {
      this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
    } else {
      this.liveDataResponse = undefined;
      this.klasseResults = this.getEmptyKlasseResults();
      this.webSocketService.changeLiveResultaterGroup$(currentEventId).subscribe();
      this.prevSelectedEventId = currentEventId;
    }

    this.updateUrl(currentEventId);
  }

  onClickMe(sortField: 'placer' | 'startNo' = 'placer'): void {
    this.sortField = sortField;
    this.dataTableDef = this.getDcHDataTable(this.sortField);
    this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
  }

  private updateUrl(dchEventId: number): void {
    const url = `/agilityLiveResultater/${dchEventId}`;
    this.location.replaceState(url);
  }


  private getEmptyKlasseResults(): AgilityKlasseDataResults {
    let a = {} as AgilityKlasseDataResults;
    a.agilityResult = [];
    return a;
  }

  private getFilteredData(klasse: string, sortOrder: 'placer' | 'startNo'): AgilityKlasseDataResults | undefined {

    if (this.liveDataResponse == undefined || klasse == undefined) {
      return undefined;
    }

    const klasseDataResults = this.liveDataResponse.klasseDataResults.find(a => a.klasseKey === klasse);

    if (klasseDataResults?.agilityResult != undefined) {
      this.liveResultaterHelpers.sortKlasseDataResults(klasseDataResults.agilityResult, sortOrder);
    }

    return klasseDataResults;
  }

  private getDcHDataTable(columnChooser: 'placer' | 'startNo'): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.AG;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen resultater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Fejl', field: 'fejl', textalign: 'center', dataType: 'number', format: '1.0-0' },
      { headerText: 'Tid', field: 'tid', textalign: 'center', dataType: 'duration', format: '1.2-2' },
      { headerText: 'Fejl Ialt', field: 'fejlIalt', bold: true, textalign: 'center', dataType: 'number', format: '1.2-2' }
    ]

    if (columnChooser === 'startNo') {
      dataTable.columns.unshift({ headerText: '#', field: 'startNo', dataType: 'number', textalign: 'center' });
    } else {
      dataTable.columns.unshift({ headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' });
    }

    return dataTable;
  }
}
