import { Injectable  } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, timer, Subject } from 'rxjs';
import { catchError, tap, switchMap, takeUntil, filter } from 'rxjs/operators';
import { HttpErrorData } from '../models/http-error-data';
import { NavigationEnd, Router, Event } from '@angular/router';
import { KonkurrenceType } from '../models.generated/dchEnums';

@Injectable({ providedIn: 'root' })
export class HttpDataService {

  private _httpErrorData$ = new Subject<HttpErrorData>();
  private _loading$ = new Subject<boolean>();
  private startTime: number;
  private stopPolling = new Subject();

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get httpErrorData$(): Observable<HttpErrorData> {
    return this._httpErrorData$.asObservable();
  }

  constructor(private readonly http: HttpClient, private readonly router: Router) {

    router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        tap(() => {
          const httpOkStatus: HttpErrorData = { status: 200, reason: '' };
          this._httpErrorData$.next(httpOkStatus);
        }))
      .subscribe();
  }

  stopPoll(): void {
    this.stopPolling.next(true);
  }

  pollData<T>(url: string): Observable<T> {
    return timer(1, 15000)
      .pipe(
        switchMap(() => {
          this.showProgress(true);
          return this.http.get<T>(url);
        }),
        tap(() => {
          this.showProgress(false);
        }),
        catchError((err: HttpErrorData) => {
          this._httpErrorData$.next(err);
          // this.stopPolling.next();
          this.showProgress(false);
          throw err;
        }),
        takeUntil(this.stopPolling)
      );
  }

  deleteData<T>(url: string): Observable<T> {
    return this.http.delete<T>(url)
      .pipe(
        tap(() => {
          this.showProgress(false);
        }),
        catchError((err: HttpErrorData) => {
          this._httpErrorData$.next(err);
          this.showProgress(false);
          return throwError(err);
        })
      );
  }

  postData<T1, T2>(url: string, body: T1): Observable<T2> {
    this.showProgress(true);

    return this.http.post<T2>(url, body)
      .pipe(
        tap(() => {
          this.showProgress(false);
        }),
        catchError((err: HttpErrorData) => {
          this._httpErrorData$.next(err);
          this.showProgress(false);
          return throwError(err);
        })
      );
  }

  getBlob(url: string): Observable<Blob> {
    this.showProgress(true);

    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        tap(() => {
          this.showProgress(false);
        }),
        catchError((err: HttpErrorData) => {
          this._httpErrorData$.next(err);
          this.showProgress(false);
          return throwError(err);
        })
      );
  }

  getDataWithParm<T>(url: string, httpParams: HttpParams): Observable<T> {
    this.showProgress(true);

    return this.http.get<T>(url, { params: httpParams })
      .pipe(
        tap(() => {
          this.showProgress(false);
        }),
        catchError((err: HttpErrorData) => {
          this._httpErrorData$.next(err);
          this.showProgress(false);
          return throwError(err);
        })
      );
  }

  getData<T>(url: string): Observable<T> {
    this.showProgress(true);

    return this.http.get<T>(url)
      .pipe(
        tap(() => {
          this.showProgress(false);
        }),
        catchError((err: HttpErrorData) => {
          this._httpErrorData$.next(err);
          this.showProgress(false);
          return throwError(err);
        })
      );
  }

  getControllerBaseUrl(konkurrenceType: KonkurrenceType): string {

    switch (konkurrenceType) {
      case KonkurrenceType.AG:
        return 'api/AgilityData/';
      case KonkurrenceType.HO:
        return 'api/HoopersData/';
      case KonkurrenceType.BR:
        return 'api/BrugsData';
      case KonkurrenceType.LY:
        return 'api/LydighedData/';
      case KonkurrenceType.NO:
        return 'api/NordiskData/';
      case KonkurrenceType.RA:
        return 'api/RallyData';
      case KonkurrenceType.NW:
        return 'api/NoseWorkData';

      default:
        throw new Error(`Invalid konkurrenceType: '${konkurrenceType}'`);
    }
  }

  private showProgress(start: boolean): void {

    if (start) {
      this.startTime = new Date().getTime();
      this._loading$.next(true);
      return;
    }

    const myTime = new Date().getTime() - this.startTime;

    if (myTime < 995) {
      setTimeout(() => {
        this._loading$.next(false);
      },
        1000 - myTime);
    } else {
      this._loading$.next(false);
    }
  }
}
