import { Pipe, PipeTransform } from '@angular/core';
import { KonkurrenceType } from '../models.generated/dchEnums';
import { KonkurrenceTypeHelpers } from '../helpers/konkurrenceTypeHelpers';

@Pipe({
    name: 'dcHKonkurrenceTypeText',
    standalone: true
})
export class DcHKonkurrenceTypeText implements PipeTransform {

  transform(konkurrenceType: KonkurrenceType | undefined): string {

    if (konkurrenceType == undefined) {
      return ''
    }

    return new KonkurrenceTypeHelpers().getKonkurrenceTypeText(konkurrenceType);
  }
}
