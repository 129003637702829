import { KlubmodulStatus } from '../models.generated/dchEnums';

export class DchKlubModulHelpers {

  getKmStatusText(klubmodulStatus: KlubmodulStatus): string {
    switch (klubmodulStatus) {
      case KlubmodulStatus.Member:
        return 'Medlem';
      case KlubmodulStatus.NotMember:
        return 'Ikke medlem af DcH';
      case KlubmodulStatus.Deleted:
        return 'Profil slettet i DcH KlubModul'
      default:
        return KlubmodulStatus[klubmodulStatus];
    }
  }
}
