import { Component } from '@angular/core';
import { HttpDataService } from '../../../services/http-data.service';
import { AgilityDmCandidate, AgilityDmCandidateResponse } from '../../../models.generated/agility';
import { AgilitySize, DcHKreds } from '../../../models.generated/dchEnums';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hoopers-dm-candidates',
  templateUrl: './hoopers-dm-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHFullDatePipe]
})
export class HoopersDmCandidatesComponent {

  agilityDmCandidateResponse: AgilityDmCandidateResponse;
  agilityDmCandidates: AgilityDmCandidate[];
  selectedAgility: AgilitySize;
  selectedDcHKreds: DcHKreds;

  constructor(private readonly httpDataService: HttpDataService) {

  }

  agilitySizeSelectionChange(args: AgilitySize): void {
    this.selectedAgility = args;
    this.agilityDmCandidates = this.getFilteredData(this.selectedDcHKreds, this.selectedAgility);
  }

  regionSelectionChange(args: DcHKreds): void {
    this.selectedDcHKreds = args;
    this.agilityDmCandidates = this.getFilteredData(this.selectedDcHKreds, this.selectedAgility);
  }

  private getFilteredData(dchKreds: DcHKreds, agility: AgilitySize): AgilityDmCandidate[] {

    if (this.agilityDmCandidateResponse == undefined) {
      return [];
    }

    let agilityDmCandidates = this.agilityDmCandidateResponse.agilityDmCandidates;

    if (dchKreds !== DcHKreds.DcHLands) {
      agilityDmCandidates = this.agilityDmCandidateResponse.agilityDmCandidates.filter(a => a.dchEkvipage?.dchKreds === this.selectedDcHKreds);
    }

    if (agility != undefined) {
      agilityDmCandidates = agilityDmCandidates.filter(a => a.dchEkvipage?.agilitySize === agility);
    }

    return agilityDmCandidates;
  }
}
