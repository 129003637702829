import { Pipe, PipeTransform } from '@angular/core';
import { KonkurrenceType } from '../models.generated/dchEnums';

@Pipe({
    name: 'dcHPlacerText',
    standalone: true
})
export class DcHPlacerText implements PipeTransform {
  transform(placer: number, konkurrenceType: string | KonkurrenceType): string {

    let myKonkurrenceType = typeof (konkurrenceType) === 'string' ? KonkurrenceType[konkurrenceType as keyof typeof KonkurrenceType] : konkurrenceType;

    if (placer < 250) {
      return placer.toString();
    }

    if (placer === 250) {
      return '---';
    }

    if (placer === 252 && (myKonkurrenceType === KonkurrenceType.AG || konkurrenceType === KonkurrenceType.HO)) {
      return 'MBT';
    }

    if (placer === 253) {
      if ((myKonkurrenceType === KonkurrenceType.AG) || (myKonkurrenceType === KonkurrenceType.HO)) {
        return 'D';
      }

      if (myKonkurrenceType === KonkurrenceType.BR) {
        return 'STOP';
      }

      return 'IB';
    }

    return 'Udb';
  }
}
