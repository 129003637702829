import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHLiveDataToken } from '../../../models.generated/misc';
import { DcHDataTable } from '../../../models/dch-data-table';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { MatSnackBarService } from '../../../services/mat-snack-bar.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';

@Component({
  selector: 'app-admin-live-konkurrencer',
  templateUrl: './live-konkurrencer.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, DcHDataTableComponent]
})
export class AdminLiveKonkurrencerComponent implements OnInit {
  dcHLiveDataTokens: DcHLiveDataToken[];
  selectedDcHLiveDataToken: DcHLiveDataToken | undefined;
  tokenData$: Observable<string>;
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly clipboard: Clipboard, private readonly matSnackBarService: MatSnackBarService, private readonly competitionDataService: CompetitionDataService, private readonly dialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.updateData();
  }

  updateData() {
    this.competitionDataService.getDcHLiveDataTokens()
      .subscribe(r => this.dcHLiveDataTokens = r);
  }

  copyClipBoardClicked(dcHLiveDataToken: DcHLiveDataToken): void {

    if (dcHLiveDataToken?.konkurrenceDato != undefined) {
      const dato = formatDate(dcHLiveDataToken.konkurrenceDato.seconds * 1000, 'dd. MMM yyyy', 'da-DK');
      const msg = `Token for ${dcHLiveDataToken.forening} d. ${dato} kopieret til klipbordet`;
      this.matSnackBarService.showInfoMsg(msg);
      this.clipboard.copy(dcHLiveDataToken.guid);
    }
  }

  newTokenClicked(dcHLiveDataToken: DcHLiveDataToken): void {
    if (dcHLiveDataToken?.konkurrenceDato == undefined) {
      throw new Error("Competition date is missing");
    }

    const dato = formatDate(dcHLiveDataToken.konkurrenceDato.seconds * 1000, 'dd. MMM yyyy', 'da-DK');
    let msg = `${dcHLiveDataToken.forening} d. ${dato}`;

    this.dialogService.showDialog([msg, 'Lav ny token'], 'Admin live konkurrencer', 'OkCancel')
      .subscribe(a => {
        if (a) {
          this.competitionDataService.newLiveDataToken(dcHLiveDataToken.dcHEventId)
            .subscribe(a => {
              msg += ' opdateret';
              this.matSnackBarService.showOkMsg(msg);
              this.updateLiveDataToken(dcHLiveDataToken, a);
            });
        }
      });
  }

  togleStatus(dcHLiveDataToken: DcHLiveDataToken): void {

    dcHLiveDataToken.active = !dcHLiveDataToken.active;
    this.competitionDataService.updateLiveDataToken(dcHLiveDataToken)
      .subscribe(a => {
        this.updateLiveDataToken(dcHLiveDataToken, a);
        const msg = this.getActivationConfirmationText(dcHLiveDataToken);
        this.matSnackBarService.showOkMsg(msg, 99999);
      });
  }

  private getActivationConfirmationText(dcHLiveDataToken: DcHLiveDataToken): string {
    if (dcHLiveDataToken?.konkurrenceDato == undefined) {
      throw new Error("Competition date is missing");
    }

    const dato = formatDate(dcHLiveDataToken.konkurrenceDato.seconds * 1000, 'dd. MMM yyyy', 'da-DK');

    let msg = `${dcHLiveDataToken.forening} d. ${dato}`;
    if (dcHLiveDataToken.active) {
      msg += ' aktiveret';
    } else {
      msg += ' de-aktiveret';
    }

    return msg;
  }

  private getDcHDataTable(): DcHDataTable {
    const dataTable = new DcHDataTable();
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen konkurrencer';

    dataTable.columns = [
      { headerText: 'Dato', field: 'konkurrenceDato', dataType: 'timeStamp', textalign: 'center', freeze: true, format: 'd.MMM YYYY' },
      { headerText: 'Type', field: 'konkurrenceType', dataType: 'dchKonkurrenceType', hideSmallScreen: true },
      { headerText: 'Art', field: 'konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Konkurrencenavn', field: 'konkurrenceNavn', width: '30%', whiteSpace: 'normal' },
      { headerText: 'Arrangør', field: 'forening' },
      { headerText: 'Token', field: 'guid', hideSmallScreen: true },
      { headerText: 'Aktiv', field: 'active', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: boolean) => a ? 'done' : '' },
    ];

    return dataTable;
  }

  private updateLiveDataToken(destLiveDataToken: DcHLiveDataToken, srcLiveDataToken: DcHLiveDataToken) {
    destLiveDataToken.forening = srcLiveDataToken.forening;
    destLiveDataToken.foreningId = srcLiveDataToken.foreningId;
    destLiveDataToken.konkurrenceArt = srcLiveDataToken.konkurrenceArt;
    destLiveDataToken.konkurrenceDato = srcLiveDataToken.konkurrenceDato;
    destLiveDataToken.konkurrenceDato2 = srcLiveDataToken.konkurrenceDato2;
    destLiveDataToken.konkurrenceNavn = srcLiveDataToken.konkurrenceNavn;
    destLiveDataToken.konkurrenceType = srcLiveDataToken.konkurrenceType;
    destLiveDataToken.active = srcLiveDataToken.active;
    destLiveDataToken.guid = srcLiveDataToken.guid;
  }
}
