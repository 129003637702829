import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { LydighedOprykkereResponse } from '../../../models.generated/lydighed';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { LydighedOprykkereWrapper } from './lydighed-oprykkere-wrapper';

@Component({
  selector: 'app-lydighed-oprykkere',
  templateUrl: './lydighed-oprykkere.component.html',
  standalone: true,
  imports: [CommonModule, DcHFullDatePipe, DcHDataTableComponent]
})
export class LydighedOprykkereComponent implements OnInit {
  lydighedOprykkereResponse$: Observable<LydighedOprykkereResponse>;
  oprykkere: LydighedOprykkereWrapper[];
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.lydighedOprykkereResponse$ = this.fetchData$().pipe(
      tap(a => {
        this.oprykkere = a.oprykkere.map(b => (b as any) as LydighedOprykkereWrapper);
      })
    );
  }

  private fetchData$(): Observable<LydighedOprykkereResponse> {
    const url = 'api/LydighedData/GetOprykkereLydC';
    return this.httpDataService.getData<LydighedOprykkereResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Total', field: 'total', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' }
    ]

    return dataTable;
  }
}
