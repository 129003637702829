import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppMaterialModule } from '../../app-material.module';
import { DcHDataTableComponent } from '../../components.shared/dch-data-table/dch-data-table.component';
import { DchKlubModulHelpers } from '../../helpers/dch-klub-modul-helpers';
import { DcHEkvipageResponse, DcHStamData } from '../../models.generated/shared';
import { DcHDataTable } from '../../models/dch-data-table';
import { AgilityPropertiesText } from '../../pipes/agility-properties-text.pipe';
import { HoopersSizeText } from '../../pipes/hoopers-size-text.pipe';
import { RallyText } from '../../pipes/rally-text.pipe';
import { HttpDataService } from '../../services/http-data.service';

@Component({
  selector: 'app-stamdata',
  templateUrl: './stam-data.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule, DcHDataTableComponent, HoopersSizeText, AgilityPropertiesText, RallyText]
})

export class StamDataComponent implements OnInit {
  klubModulHelpers = new DchKlubModulHelpers();
  dataTableDef = this.getDcHDataTable();
  stamDatas = new Array<DcHStamData>();
  stamDatas$: Observable<DcHStamData[]>;
  searchText = '';
  private searchTerms: Subject<string> = new Subject();

  constructor(readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.stamDatas$ = this.getData().pipe(tap(a => this.stamDatas = a));
    this.stamDatas$.subscribe();
  }

  private getData(): Observable<DcHStamData[]> {
    return this.searchTerms
      .pipe(
        filter(text => text.length > 2),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => this.fetchData(term))
      );
  }

  private fetchData(term: string): Observable<DcHStamData[]> {
    const url = 'api/StamData/GetStamDatasBySearchText';
    const params = new HttpParams().set('searchTerm', term);
    return this.httpDataService.getDataWithParm<DcHEkvipageResponse>(url, params)
      .pipe(map(a => a.ekvipages));
  }

  search(term: string): void {
    this.searchTerms.next(term);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen stamdata, brug søge funktion';

    dataTable.columns = [
      { headerText: '', field: 'klubModulStatus', dataType: 'klubModulStatus', textalign: 'center', width: '15px' },
      { headerText: 'MdlNr', field: 'profileId', textalign: 'center' },
      { headerText: 'Ekvipage', field: '', dataType: 'ekvipageLink' },
      { headerText: 'Forening', field: 'forening' }
    ]

    return dataTable;
  }
}
