import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { BrugsIgp3DmCandidateResponse } from '../../../models.generated/brugs';
import { DcHKreds, KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { BrugsIgp3DmCandidateWrapper } from './brugs-igp3dm-candidate-wrapper';

@Component({
  selector: 'app-brugs-ipg3dm-candidates',
  templateUrl: './brugs-ipg3dm-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, AppMaterialModule, DcHDataTableComponent]
})
export class BrugsIpg3DmCandidatesComponent implements OnInit {
  dmResponse: BrugsIgp3DmCandidateResponse;
  brugsDmCandidates: BrugsIgp3DmCandidateWrapper[];
  regionSelectItems = new RegionHelpers().getRegionSelectItems(true);
  regionSelectedValue = this.regionSelectItems[0].value;
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.fetchData$()
      .subscribe(a => {
        this.dmResponse = a
        this.brugsDmCandidates = this.getFilteredData(this.regionSelectItems[0].value);
      });
  }

  selectionChangeHandler(): void {
    this.brugsDmCandidates = this.getFilteredData(this.regionSelectedValue);
  }

  private getFilteredData(dchKreds: DcHKreds): BrugsIgp3DmCandidateWrapper[] {

    if (this.dmResponse == undefined) {
      return [];
    }

    let brugsDmCandidates = this.dmResponse.brugsDmCandidates;

    if (dchKreds !== DcHKreds.DcHLands) {
      brugsDmCandidates = this.dmResponse.brugsDmCandidates.filter(a => a.dchEkvipage?.dchKreds === dchKreds);
    }

    return brugsDmCandidates.map(a => (a as any) as BrugsIgp3DmCandidateWrapper);
  }

  private fetchData$(): Observable<BrugsIgp3DmCandidateResponse> {
    const url = 'api/BrugsData/GetIpg3DmCandidates';
    return this.httpDataService.getData<BrugsIgp3DmCandidateResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.BR;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'GrpA', field: 'dmResult.spor', headerToolTip: 'Gruppe A - Spor', textalign: 'center', dataType: 'number', format: '1.2-2' },
      { headerText: 'GrpB', field: 'dmResult.lydighed', headerToolTip: 'Gruppe B - Lydighed', textalign: 'center', dataType: 'number', format: '1.2-2' },
      { headerText: 'GrpC', field: 'dmResult.forsvar', headerToolTip: 'Gruppe C - Forsvar', textalign: 'center', dataType: 'number', format: '1.2-2' },
      { headerText: 'Total', field: 'dmResult.total', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' },
      { headerText: 'Udtaget', field: 'dchEkvipage.ekvipageNote' }
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.BR;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Total', field: 'total', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' }
    ]

    return dataTable;
  }
}
