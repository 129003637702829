import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieHelpers } from '../helpers/cookieHelpers';
import { JwtHelper } from '../helpers/jwtHelper';
import { UserInfo } from '../models/user-info';

@Injectable()
export class AuthenticationService {

  private _userInfo$: BehaviorSubject<UserInfo>;

  get userInfo$(): Observable<UserInfo> {
    return this._userInfo$.asObservable();
  }

  private jwtHelper = new JwtHelper();

  constructor(private readonly http: HttpClient) {
    this._userInfo$ = new BehaviorSubject(this.getUserInfo());
  }

  login(username: string, password: string): Observable<boolean> {
    const body = { username, password };
    const url = '/api/login';

    return this.http.post<boolean>(url, body)
      .pipe(
        tap(() => {
          this._userInfo$.next(this.getUserInfo());
        })
      );
  }

  logout(): void {
    const url = '/api/logout';
    this.http.get(url)
      .subscribe(() => {
        this._userInfo$.next(new UserInfo());
      }
      );
  }

  getIsLoggedIn(): boolean {
    return this.getUserInfo().username?.length > 1;
  }

  getUserInfo(): UserInfo {
    const token = new CookieHelpers().getCookie('X-Access-Token');
    const userInfo = this.jwtHelper.getUserInfo(token);
    if (userInfo == undefined) {
      return new UserInfo();
    } else {
      return userInfo;
    }
  }
}
