import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertNewLines',
    standalone: true
})
export class ConvertNewLines implements PipeTransform {
  transform(text: string | undefined): string {
    if (text == undefined) {
      return '';
    }

    return text?.replace(/\n/g, '<br />');
  }
}
