import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../app-material.module';
import { SelectItem } from '../../models/select-item';

@Component({
  selector: 'app-dch-select',
  templateUrl: './dch-select.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule, ReactiveFormsModule]
})
export class DcHSelectComponent {

  @Input()
  selectItems: SelectItem<any>[];

  @Output()
  selectedValueChange = new EventEmitter<any>();

  @Input()
  selectedValue: any;

  @Input()
  selectLabel: string;

  @Input()
  myFormControl: FormControl;

  selectionChangeHandler(): void {
    this.selectedValueChange.emit(this.selectedValue);
  }
}
