import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { HubConnectionStatusComponent } from '../../../components.shared/hub-connection-status/hub-connection-status.component';
import { LiveMessageComponent } from '../../../components.shared/live-message/live-message.component';
import { ModalResultComponent } from '../../../components.shared/modal-result/modal-result.component';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { HoopersKlasseDataResults, HoopersLiveDataResponse, HoopersResult } from '../../../models.generated/hoopers';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { ModalResultDialogData } from '../../../models/modal-result-dialog-data';
import { SelectItem } from '../../../models/select-item';
import { DcHDurationPipe } from '../../../pipes/dch-duration.pipe';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { WebSocketService } from '../../../services/web-socket-service';
import { HoopersLiveResultaterHelpers } from './hoopers-live-resultater.helpers';

@Component({
  selector: 'app-hoopers-live-results',
  templateUrl: './Hoopers-live-resultater.component.html',
  standalone: true,
  imports: [CommonModule, MatRadioModule, DcHKonkurrenceArtText, LiveMessageComponent, DcHSelectComponent, HubConnectionStatusComponent, DcHDurationPipe, DcHDataTableComponent]
})
export class HoopersLiveResultaterComponent implements OnInit, OnDestroy {
  liveDataResponse: HoopersLiveDataResponse | undefined;
  competionDataSelectItems = new Array<SelectItem<DcHCompetitionData>>();
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<string>[];
  klasseSelectedValue = '';
  klasseResults: HoopersKlasseDataResults | undefined;
  dcHHoopersKlasses: string[] = [];
  sortField: 'placer' | 'startNo' = 'placer';
  dataTableDef = this.getDcHDataTable(this.sortField);
  selectedKlasse: string;
  private prevSelectedEventId = 0;
  private liveResultaterHelpers = new HoopersLiveResultaterHelpers();

  constructor(private readonly location: Location, private readonly webSocketService: WebSocketService, private readonly dialog: MatDialog, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    this.webSocketService.connectToHub$().subscribe();

    this.competitionDataService.fetchLiveCompetitionDatasAsSelectItems$(KonkurrenceType.HO)
      .pipe(
        mergeMap(selectItems => {
          if (selectItems.length > 0) {
            this.competionDataSelectItems = selectItems;
            this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
            this.prevSelectedEventId = this.competionDataSelectedValue.dchEventId;
            return this.webSocketService.initLiveResultater$(KonkurrenceType.HO, this.competionDataSelectedValue.dchEventId);
          }
          else {
            this.klasseResults = this.getEmptyKlasseResults();
            this.competionDataSelectItems = [];
            return of();
          }
        })
      ).subscribe();

    this.webSocketService.hoopersLiveDataUpdated$
      .subscribe(liveDataReply => {
        console.log('HoopersLiveDataUpdated: ' + liveDataReply.liveDataResponse?.dchEventId);

        this.liveDataResponse = liveDataReply;
        this.klasseSelectItems = liveDataReply.klasseDataResults.map(item => this.liveResultaterHelpers.getKlasseSelectItem(item.klasseKey));
        this.klasseSelectedValue = this.liveResultaterHelpers.getKlasseSelectValue(this.klasseSelectItems, this.klasseSelectedValue);
        this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
      });
  }

  async ngOnDestroy(): Promise<void> {
    this.webSocketService.unsubscribeLiveResultater$().subscribe();
  }

  selectionChangeHandler(): void {
    let currentEventId = this.competionDataSelectedValue.dchEventId;

    if (this.prevSelectedEventId === currentEventId) {
      this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
    } else {
      this.liveDataResponse = undefined;
      this.klasseResults = this.getEmptyKlasseResults();
      this.webSocketService.changeLiveResultaterGroup$(currentEventId).subscribe();
      this.prevSelectedEventId = currentEventId;
    }

    this.updateUrl(currentEventId);
  }

  private getEmptyKlasseResults(): HoopersKlasseDataResults {
    let a = {} as HoopersKlasseDataResults;
    a.hoopersResult = new Array<HoopersResult>();
    return a;
  }

  onClickMe(sortField: 'placer' | 'startNo' = 'placer'): void {
    this.sortField = sortField;
    this.klasseResults = this.getFilteredData(this.selectedKlasse, this.sortField);
    this.dataTableDef = this.getDcHDataTable(this.sortField);
  }

  private updateUrl(konkId: number): void {
    const url = `/hoopersLiveResultater/${konkId}`;
    this.location.replaceState(url);
  }

  private getFilteredData(klasse: string, sortOrder: 'placer' | 'startNo'): HoopersKlasseDataResults | undefined {

    if (this.liveDataResponse == undefined || klasse == undefined) {
      return undefined;
    }

    const klasseDataResults = this.liveDataResponse.klasseDataResults.find(a => a.klasseKey === klasse);

    if (klasseDataResults?.hoopersResult != undefined) {
      this.liveResultaterHelpers.sortKlasseDataResults(klasseDataResults.hoopersResult, sortOrder);
    }

    return klasseDataResults;
  }

  private openModal(data: HoopersResult): void {
    const modalResultDialogData: ModalResultDialogData = { ekvipage: data.dchEkvipage, eventId: this.competionDataSelectedValue.dchEventId, klasse: this.klasseSelectedValue, konkurrenceType: KonkurrenceType.HO, live: true };
    this.dialog.open(ModalResultComponent, {
      data: modalResultDialogData
    });
  }

  private getDcHDataTable(columnChooser: 'placer' | 'startNo'): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.HO;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen resultater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Tid', field: 'tid', textalign: 'center', dataType: 'duration', format: '1.2-2' },
      { headerText: 'Point i alt', field: 'pointIalt', textalign: 'center', dataType: 'number', format: '1.0-0', fnCellClicked: (a: HoopersResult) => { this.openModal(a) } }
    ];

    if (columnChooser === 'startNo') {
      dataTable.columns.unshift({ headerText: '#', field: 'startNo', dataType: 'number', textalign: 'center' });
    } else {
      dataTable.columns.unshift({ headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' });
    }

    return dataTable;
  }
}
