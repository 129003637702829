import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { DcHAgilityKlasseHelpers } from '../../../helpers/dch-agility-klasse-helpers';
import { AgilityCompetitionResponse, AgilityKlasseDataResults } from '../../../models.generated/agility';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { DcHDurationPipe } from '../../../pipes/dch-duration.pipe';

@Component({
  selector: 'app-agility-results',
  templateUrl: './agility-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHKonkurrenceArtText, DcHSelectComponent, SelectCompetitionComponent, DcHDataTableComponent, DcHDurationPipe]
})
export class AgilityResultaterComponent implements OnInit {
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<string>[];
  klasseSelectedValue: string;
  competitionResponse: AgilityCompetitionResponse;
  klasseResults: AgilityKlasseDataResults | undefined;
  dataTableDef = this.getDcHDataTable();
  private agilityKlasseHelpers = new DcHAgilityKlasseHelpers();
  private prevSelectedKonkId = 0;

  @Input()
  importMode = false;

  @Input()
  competitionResponses: AgilityCompetitionResponse[];

  constructor(private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.klasseResults = this.competitionResponse.klasseDataResults[0];
      this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.agilityKlasseHelpers.getKlasseSelectItem(item.klasseKey));
      this.klasseSelectedValue = this.klasseSelectItems[0].value;
    }
  }

  selectionChangeHandler(): void {

    if (this.competionDataSelectedValue == undefined) {
      console.log('competionDataSelectedValue was undefined')
      return;
    }

    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasseKey === this.klasseSelectedValue);
    }
    else if (this.importMode) {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.agilityKlasseHelpers.getKlasseSelectItem(item.klasseKey));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
        this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasseKey === this.klasseSelectedValue);
      } else {
        this.klasseSelectItems = [];
        this.klasseSelectedValue = '';
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchAgilityCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.klasseResults = a.klasseDataResults[0];
        this.klasseSelectItems = a.klasseDataResults.map(item => this.agilityKlasseHelpers.getKlasseSelectItem(item.klasseKey));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      });
    }
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.AG;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Fejl', field: 'fejl', textalign: 'center', dataType: 'number', format: '1.0-0' },
      { headerText: 'Tid', field: 'tid', textalign: 'center', dataType: 'duration', format: '1.2-2' },
      { headerText: 'Fejl Ialt', field: 'fejlIalt', bold: true, textalign: 'center', dataType: 'number', format: '1.2-2' }
    ]

    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: AgilityCompetitionResponse[]) {
    let dummy = -999;
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId > 0 ? a.dchEventId : dummy++;
      }
    });
  }
}
