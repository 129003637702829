<div class="pageHeader">
  <h1>Udtagelseplaceringer til IGP FH DM</h1>

  <div class="flex-container">
    <app-dch-select [(selectedValue)]="regionSelectedValue" [selectItems]="regionSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>

  <h4 *ngIf="dmResponse">Udtagelsesperiode: {{dmResponse.startDate | dcHFullDate: dmResponse.endDate}}</h4>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="brugsDmCandidates" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>


<ng-template #dataRowDetails let-data>
  <div class="dchTableResDetailsContainer">
    <app-dch-data-table [dataTable]="getDcHDataTableDetails()" [dataSource]="[data?.dmResult]"></app-dch-data-table>
  </div>
</ng-template>
