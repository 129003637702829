<div [ngStyle]="dataTable.height!='auto'? {'height':dataTable.height, 'overflow-y':'scroll'}:{}" [ngStyle]="dataTable.width!='auto'? {'width':dataTable.width, 'overflow-x':'scroll'}:{}">

  <table *ngIf="dataTable != undefined; else noDataOrProgress"
         [style.table-layout]="dataTable.tableLayout"
         [ngClass]="dataTable.cssClass"
         [class.dchDataTablePrimary]="dataTable.color==='primary'"
         [class.dchDataTableSecondary]="dataTable.color==='secondary'"
         [class.dataTableSticky]="dataTable.stickyHeader">
    <thead>
    <tr>
      <th *ngIf="dataRowDetails != undefined" style="width: 15px" freezeColumn></th>
      <th *ngIf="rowMenuItems != undefined" style="width: 15px" freezeColumn> </th>

      <th *ngFor="let column of dataTable.columns" style="white-space: pre-line"
          [ngStyle]="{'width': column.width}"
          [attr.title]="column.headerToolTip"
          [attr.freezeColumn]="column.freeze"
          [class.hide-sm]="column.hideSmallScreen"
          [style.text-align]="column.textalign">
        <ng-container *ngIf="column.fnHeaderFormatter==undefined">{{column.headerText}}</ng-container>
        <ng-container *ngIf="column.fnHeaderFormatter!=undefined">{{column.fnHeaderFormatter(column)}}</ng-container>
      </th>
    </tr>
    </thead>
    <tbody [ngClass]="{'dchTableAnimation' : updating}" *ngIf="dataSource != undefined && dataSource.length>0; else noDataOrProgress">
      <ng-container *ngFor="let rowData of dataSource; index as i">
        <tr class="datarow" (click)="dataTable.allowRowSelection && rowSelectionHandler(i, rowData)" [ngClass]="{'highlightRow' : i===selectedIndex}">
          <td *ngIf="dataRowDetails != undefined" freezeColumn>
            <span class="table-details-button" [ngClass]="{'rotated' : rowData.isExpand}" (click)="rowData.isExpand=!rowData.isExpand; detailsClicked(i)"><mat-icon>expand_more</mat-icon></span>
          </td>

          <td *ngIf="rowMenuItems != undefined" freezeColumn>
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="rowMenuClicked($event,i, rowData)">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container #rowMenuContainerRef class="rowMenu"></ng-container>
            </mat-menu>
          </td>

          <ng-container *ngFor="let column of dataTable.columns">
            <td [attr.freezeColumn]="column.freeze"
                [class.hide-sm]="column.hideSmallScreen"
                [class.text-bold]="column.bold"
                [style.text-align]="column.textalign"
                [style.white-space]="column.whiteSpace">

              <ng-container [ngSwitch]="column.dataType">

                <ng-container *ngSwitchCase="'klubModulStatus'">
                  <app-dch-tilmeld-status [klubmodulStatus]="getValue(rowData, column)"></app-dch-tilmeld-status>
                </ng-container>

                <ng-container *ngSwitchCase="'ekvipageLink'">
                  <app-ekvipage-link [ekvipage]="getValue(rowData, column)" [konkurrenceType]="dataTable.konkurrenceType"></app-ekvipage-link>
                </ng-container>

                <ng-container *ngSwitchCase="'dchKonkurrenceArt'">
                  {{getValue(rowData, column) | dcHKonkurrenceArtText}}
                </ng-container>

                <ng-container *ngSwitchCase="'dchKonkurrenceType'">
                  {{getValue(rowData, column) | dcHKonkurrenceTypeText}}
                </ng-container>

                <ng-container *ngSwitchCase="'timeStamp'">
                  {{getValue(rowData, column) | dcHFullDate: undefined:column.format}}
                </ng-container>

                <ng-container *ngSwitchCase="'duration'">
                  {{getValue(rowData, column) | dcHDuration: column.format}}
                </ng-container>

                <ng-container *ngSwitchCase="'dchPlacer'">
                  {{getValue(rowData, column) | dcHPlacerText: dataTable.konkurrenceType}}
                </ng-container>

                <ng-container *ngSwitchCase="'number'">
                  <ng-container *ngIf="column.fnCellClicked != undefined">
                    <a href="#" (click)="$event.preventDefault(); column.fnCellClicked(rowData)">{{getValue(rowData, column) | number : column.format}}</a>
                  </ng-container>

                  <span *ngIf="column.fnCellClicked == undefined">
                    {{getValue(rowData, column) | number : column.format}}
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'rowCommand'">
                  <ng-container *ngIf="column.fnRowCommandClicked == undefined">
                    <mat-icon (click)="rowData.isExpand=!rowData.isExpand; rowCommandClicked(i)" style="cursor: pointer">{{column.field}}</mat-icon>
                  </ng-container>

                  <ng-container *ngIf="column.fnRowCommandClicked != undefined">
                    <mat-icon (click)="column.fnRowCommandClicked(rowData)" style="cursor: pointer">{{column.field}}</mat-icon>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'mat-icon'">
                  <mat-icon [innerHTML]="getValue(rowData, column)"></mat-icon>
                </ng-container>

                <ng-container *ngSwitchCase="'string'">
                  <ng-container *ngIf="column.fnCellClicked != undefined">
                    <a href="#" (click)="$event.preventDefault(); column.fnCellClicked(rowData)">{{getValue(rowData, column)}}</a>
                  </ng-container>

                  <span *ngIf="column.fnCellClicked == undefined">
                    {{getValue(rowData, column)}}
                  </span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{getValue(rowData, column)}}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
        </tr>
        <tr class="datarowdetails" *ngIf="rowData.isExpand && dataRowDetails!=undefined">
          <td [attr.colspan]="dataTable.columns.length + 1">
            <ng-container *ngTemplateOutlet="dataRowDetails; context: {$implicit: rowData}"></ng-container>
          </td>
        </tr>
        <tr class="datarowdetails" *ngIf="rowData.isExpand && editRowData!=undefined">
          <td [attr.colspan]="dataTable.columns.length + 1">
            <ng-container *ngTemplateOutlet="editRowData; context: {$implicit: rowData}"></ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #showProgressBar>
  <div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>

<ng-template #noDataOrProgress>
  <tbody *ngIf="dataTable!=undefined; else showProgressBar">
    <tr>
      <td [attr.colspan]="dataTable.columns.length + 1">
        <div *ngIf="dataSource != undefined; else showProgressBar" class="noDataToShow">{{dataTable.noDataText}}</div>
      </td>
    </tr>
  </tbody>
</ng-template>
