import { CommonModule, formatDate } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AppMaterialModule } from './app-material.module';
import { HttpErrorComponent } from './components.shared/http-error/http-error.component';
import { buildNavigationMenuHelpers } from './helpers/build-navigation-menu-helpers';
import { KonkurrenceType } from './models.generated/dchEnums';
import { DcHFileUploadData } from './models/dch-file-upload-data';
import { MenuItem, MenuSection } from './models/menu-item';
import { AuthenticationService } from './services/authentication.service';
import { MatSnackBarService } from './services/mat-snack-bar.service';
import { WebSocketService } from './services/web-socket-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, HttpErrorComponent, RouterLink, RouterOutlet]
})

export class AppComponent implements OnInit, OnDestroy {

  title = 'app';
  fileUploadedSubscribtion: Subscription;
  noOfNotImportedFiles$: Observable<number>;
  menuSections: MenuSection[];
  height: number;
  sidenavMode: MatDrawerMode;
  
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(readonly authenticationService: AuthenticationService, private readonly webSocketService: WebSocketService, private readonly matSnackBarService: MatSnackBarService, private readonly router: Router) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.height = window.innerHeight - 100;

    if (window.window.innerWidth > 960) {
      this.sidenavMode = "side";
    } else {
      this.sidenavMode = "over"
    }
  }

  ngOnInit(): void {
    this.noOfNotImportedFiles$ = this.webSocketService.numberOfNotImportedFiles$;

    this.authenticationService.userInfo$
      .subscribe(userInfo => {
        if (userInfo.username?.length > 1) {
          this.menuSections = new buildNavigationMenuHelpers().getNavigationMenu(userInfo);
          this.webSocketService.connectToHub$().subscribe();
          this.fileUploadedSubscribtion = this.webSocketService.fileUploaded$
            .subscribe((dcHFileUploadData: DcHFileUploadData) => {
              const msg = KonkurrenceType[dcHFileUploadData.konkurrenceType] + ' ' + dcHFileUploadData.arrangør + ' ' + formatDate(dcHFileUploadData.konkurrenceDato, 'd. MMM', 'da-DK');
              this.matSnackBarService.showInfoMsg(`Ny fil uploaded: ${msg}`, Number.MAX_SAFE_INTEGER);
            });
        }
        else {
          this.menuSections = new buildNavigationMenuHelpers().getNavigationMenu(null);
          this.webSocketService.stopConnection$().subscribe();
          this.fileUploadedSubscribtion?.unsubscribe();
        }
      });

    this.onResize();
  }

  onHomeClick(): void {
    this.sidenav.close();
    this.deSelectAllMenuItems();
    this.router.navigate(["/"]);
  }

  onMenuItemClick(menuitem: MenuItem): void {
    if (menuitem.text.includes("Logout")) {
      this.authenticationService.logout();
    }

    this.deSelectAllMenuItems();

    if (window.window.innerWidth < 600) {
      this.sidenav.close();
    }

    menuitem.isSelected = true;
    this.router.navigate([menuitem.routerLink]);
  }

  onMenuSectionClick(menuSection: MenuSection): void {
    for (let i = 0; i < this.menuSections.length; i++) {
      if (menuSection.text == this.menuSections[i].text) {
        this.menuSections[i].isSelected = !this.menuSections[i].isSelected;
      }
      else {
        this.menuSections[i].isSelected = this.menuSections[i].text == menuSection.text;
      }
    }
  }

  private deSelectAllMenuItems(): void {
    for (let i = 0; i < this.menuSections.length; i++) {
      const menuSection = this.menuSections[i];

      for (let n = 0; n < menuSection.menuItems.length; n++) {
        menuSection.menuItems[n].isSelected = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.fileUploadedSubscribtion?.unsubscribe();
    this.webSocketService?.stopConnection$().subscribe();
  }
}
