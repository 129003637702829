import { Platform } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DcHDataTableComponent } from '../../components.shared/dch-data-table/dch-data-table.component';
import { BeregnerProgram, BeregnerProgramResponse } from '../../models.generated/misc';
import { DcHDataTable } from '../../models/dch-data-table';
import { HttpDataService } from '../../services/http-data.service';

// https://www.dotnettricks.com/learn/angular/token-based-authentication-json-web-token-aspnet-core

@Component({
  templateUrl: './beregner-programmer.component.html',
  standalone: true,
  imports: [CommonModule, DcHDataTableComponent]
})
export class BeregnerProgrammerComponent implements OnInit {

  allowDownload: boolean;
  beregnerPrograms: BeregnerProgram[];
  dataTableDef = this.getDcHDataTable();

  constructor(readonly platform: Platform, private readonly httpDataService: HttpDataService) {

  }

  ngOnInit(): void {
    this.allowDownload = !(this.platform.ANDROID || this.platform.IOS)
    const url = 'api/beregnerprogrammer/getberegnerprogrammer';
    this.httpDataService.getData<BeregnerProgramResponse>(url).subscribe(a => this.beregnerPrograms = a.beregnerPrograms);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: 'Beregnerprogram', field: 'name' },
      { headerText: 'Version', field: 'version' },
      { headerText: 'Opdateret', field: 'lastUpdated', dataType: 'timeStamp' },
      { headerText: '', field: 'url', textalign: 'center', dataType: 'string', fnCellClicked: ((a: BeregnerProgram) => {  window.location.href = a.url }), fnFormatter: ((a) => 'setup') }
    ];

    return dataTable;
  }
}
