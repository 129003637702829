import { Pipe, PipeTransform } from '@angular/core';
import { DcHDurationHelpers } from '../helpers/duration.helpers';
import { Duration } from '../models.generated/google/protobuf/duration';

@Pipe({
  name: 'dcHDuration',
  standalone: true
})
export class DcHDurationPipe implements PipeTransform {

  transform(duration: Duration | undefined, format = 'ss.SS'): string {
    return new DcHDurationHelpers().getFormattedDuration(duration, format)
  }
}
