import { CommonModule, Location } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../components.shared/dch-data-table/dch-data-table.component';
import { ModalResultComponent } from '../../components.shared/modal-result/modal-result.component';
import { SelectKonkurrenceTypeComponent } from '../../components.shared/select-konkurrence-type/select-konkurrence-type.component';
import { DcHDateHelpers } from '../../helpers/date.helpers';
import { DchKlasseHelpers } from '../../helpers/dch-klasse-helpers';
import { EkvipageResultsMappers } from '../../mappers/ekvipage-results.mappers';
import { KonkurrenceType } from '../../models.generated/dchEnums';
import { DcHCompetitionData, EkvipageCompetitionResults, EkvipageResults, EkvipageResultsResponse } from '../../models.generated/shared';
import { DataColumn, DcHDataTable } from '../../models/dch-data-table';
import { ModalResultDialogData } from '../../models/modal-result-dialog-data';
import { HttpDataService } from '../../services/http-data.service';

@Component({
  selector: 'app-ekvipage-results',
  templateUrl: './ekvipage-results.component.html',
  standalone: true,
  imports: [CommonModule, SelectKonkurrenceTypeComponent, DcHDataTableComponent]
})
export class EkvipageResultsComponent {

  stamDataInfo: string;
  profileId = 0;
  dogProfileId = 0;
  konkurrenceType: KonkurrenceType;
  ekvipageResultsResponse: EkvipageResultsResponse;
  competitionResults: EkvipageCompetitionResults[];
  dchClasses = new Array<string>();
  dataTableDef = this.getDcHDataTable();

  constructor(readonly httpDataService: HttpDataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly dialog: MatDialog) {

    this.activatedRoute.paramMap.subscribe(paramMap => {

      this.profileId = Number(paramMap.get('profileId'));
      if (isNaN(this.profileId)) {
        return;
      }

      this.dogProfileId = Number(paramMap.get('dogProfileId'));
      if (isNaN(this.dogProfileId)) {
        return;
      }

      const kType = paramMap.get('konkurrenceType');
      if (kType != null && kType in KonkurrenceType) {
        this.konkurrenceType = (KonkurrenceType as any)[kType];
      }
    });
  }

  updateData(konkurrenceType: KonkurrenceType): void {
    this.konkurrenceType = konkurrenceType;
    this.updateUrl();

    this.fetchData$(this.profileId, this.dogProfileId, konkurrenceType).subscribe(a => {
      this.ekvipageResultsResponse = a;
      this.competitionResults = EkvipageResultsMappers.mapToEkvipageCompetitionResults(a.competitionResults);
      this.dchClasses = this.competitionResults[0].results.map(a => a.klasse);
      if (this.competitionResults?.length > 0) {
        this.dataTableDef = this.getDcHDataTable(this.competitionResults[0]);
      }
    });
  }

  private openModal(result: EkvipageCompetitionResults, ekvipageResults: EkvipageResults): void {
    const competitionData = result.competitionData;
    if (competitionData != undefined) {
      const modalResultDialogData: ModalResultDialogData = { ekvipage: this.ekvipageResultsResponse.dchEkvipage, eventId: competitionData.konkId, klasse: ekvipageResults.klasse, konkurrenceType: competitionData.konkurrenceType, live: false };

      this.dialog.open(ModalResultComponent, {
        data: modalResultDialogData
      });
    }
  }

  private getDcHDataTable(result: EkvipageCompetitionResults | undefined = undefined): DcHDataTable {
    let klasseHelpers = new DchKlasseHelpers();
    let dateHelpers = new DcHDateHelpers();
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.None;
    dataTable.color = 'primary';

    dataTable.columns = [
      {
        headerText: 'Konkurrence', field: 'competitionData', fnFormatter: ((a: DcHCompetitionData) => {
          return a.forening + ' d. ' + dateHelpers.getFormattedDate(a.konkurrenceDato);
        })
      },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn', hideSmallScreen: true }
    ];

    if (result == undefined) {
      return dataTable;
    }

    for (let i = 0; i < result.results.length; i++) {
      let d = new DataColumn();
      d.fnHeaderFormatter = (a: DataColumn) => { return klasseHelpers.getKlasseText(a.headerText, result.competitionData?.konkurrenceType, false) };
      d.headerText = result.results[i].klasse;
      d.dataType = "string";
      d.field = `results[${i}].resultat`
      d.fnCellClicked = (a: EkvipageCompetitionResults) => { this.openModal(a, a.results[i]) };
      dataTable.columns.push(d);
    }

    return dataTable;
  }

  private fetchData$(profileId: number, dogProfileId: number, konkurrenceType: KonkurrenceType): Observable<EkvipageResultsResponse> {
    const url = `api/EkvipageResults/GetResults/${profileId}/${dogProfileId}/${KonkurrenceType[konkurrenceType]}`;
    return this.httpDataService.getData<EkvipageResultsResponse>(url);
  }

  private updateUrl(): void {
    const url = `/ekvipageResultater/${this.profileId}/${this.dogProfileId}/${KonkurrenceType[this.konkurrenceType]}`;
    this.location.replaceState(url);
  }
}
