import { DcHLydighedKlasse } from "../models.generated/dchEnums";
import { SelectItem } from "../models/select-item";

export class DcHLydighedKlasseHelpers {

  getKlasseSelectItem(klasse: DcHLydighedKlasse): SelectItem<DcHLydighedKlasse> {
    return new SelectItem<DcHLydighedKlasse>(this.getKlasseText(klasse), klasse);
  }

  getKlasseSelectItems(): SelectItem<DcHLydighedKlasse>[] {
    let dcHLydighedKlasseHelpers = new DcHLydighedKlasseHelpers();

    return [
      new SelectItem<DcHLydighedKlasse>(dcHLydighedKlasseHelpers.getKlasseText(DcHLydighedKlasse.C), DcHLydighedKlasse.C),
      new SelectItem<DcHLydighedKlasse>(dcHLydighedKlasseHelpers.getKlasseText(DcHLydighedKlasse.B), DcHLydighedKlasse.B),
      new SelectItem<DcHLydighedKlasse>(dcHLydighedKlasseHelpers.getKlasseText(DcHLydighedKlasse.A), DcHLydighedKlasse.A),
      new SelectItem<DcHLydighedKlasse>(dcHLydighedKlasseHelpers.getKlasseText(DcHLydighedKlasse.E), DcHLydighedKlasse.E)
    ];
  }

  getKlasseText(klasse: string | DcHLydighedKlasse): string {

    let myKlasse = typeof klasse === "string" ? klasse : DcHLydighedKlasse[klasse];

    return myKlasse + ' klassen';
  }
}
