import { Injectable } from '@angular/core';
import { DcHEkvipage, DcHKredsOverView } from '../models.generated/shared';
import { DataColumn, DcHDataTable } from '../models/dch-data-table';
import { KonkurrenceType } from '../models.generated/dchEnums';
import { DcHDateHelpers } from './date.helpers';

export class PivotRow {
  dchEkvipage: DcHEkvipage;
  cols: any[] = [];
}

@Injectable({ providedIn: 'root' })
export class KredsOverViewPivotHelper {

  private dateHelpers = new DcHDateHelpers();

  getDcHDataTable(konkurrenceType: KonkurrenceType, headerRow: PivotRow | undefined = undefined): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = konkurrenceType;
    dataTable.color = 'primary';
    dataTable.noDataText = "Ingen resultater";

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink', freeze: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true }
    ]

    if (headerRow != undefined &&  headerRow.cols?.length > 0) {
      for (let i = 0; i < headerRow.cols.length; i++) {
        let headerText = headerRow.cols[i].arrangoer + '\n' + this.dateHelpers.getFormattedDate(headerRow.cols[i].konkurrenceDato, 'd.MMM');
        let dataColumn: DataColumn = { headerText: headerText, field: `cols[${i}]`, textalign: 'center' };
        dataTable.columns.push(dataColumn);
      }
    }

    return dataTable;
  }

  getPivot(kredsOverView: DcHKredsOverView[] | undefined): PivotRow[] {

    const pivotRows: PivotRow[] = [];

    if (kredsOverView == undefined) {
      return new Array<PivotRow>();
    }

    const competitions = this.getUniqueCompetitions(kredsOverView);
    const headerRow = this.getHeaderRow(competitions);

    if (kredsOverView.length <= 0) {
      return pivotRows;
    }

    for (let i = 0; i < kredsOverView.length; i++) {
      let dchEkvipage = kredsOverView[i].dchEkvipage as DcHEkvipage;
      const found = pivotRows.filter(a => a.dchEkvipage.dogProfileId === dchEkvipage.dogProfileId);

      if (found.length === 0) {
        const ekvipage = kredsOverView.filter(a => a.dchEkvipage?.dogProfileId === dchEkvipage.dogProfileId);

        if (ekvipage.length > 0) {
          const dataRow = this.getDataRow(headerRow, ekvipage, competitions.length);
          dataRow.dchEkvipage = kredsOverView[i].dchEkvipage as DcHEkvipage;
          pivotRows.push(dataRow);
        }
      }
    }

    pivotRows.unshift(headerRow);
    return pivotRows;
  }


  private getHeaderRow(competitions: any[]): PivotRow {
    const pivotRow = new PivotRow();
    pivotRow.cols = new Array(competitions.length);

    for (let j = 0; j < competitions.length; j++) {
      pivotRow.cols[j] = competitions[j];
    }

    return pivotRow;
  }

  private getKonkColIndex(cols: any[], konkId: number): number {
    for (let i = 0; i < cols.length; i++) {
      if (cols[i].konkId === konkId) {
        return i;
      }
    }

    return -1;
  }

  private getDataRow(headerRow: PivotRow, kredsOverView: DcHKredsOverView[], noOfCompetitions: number): PivotRow {
    const pivotRow = new PivotRow();
    pivotRow.cols = new Array(noOfCompetitions);

    for (let i = 0; i < kredsOverView.length; i++) {
      const colIdx = this.getKonkColIndex(headerRow.cols, kredsOverView[i].konkId);
      pivotRow.cols[colIdx] = kredsOverView[i].resultat;
    }

    return pivotRow;
  }

  private getUniqueCompetitions(kredsOverView: DcHKredsOverView[]): any[] {
    const grouped: any[] = [];

    const sortedkredsOverView = kredsOverView.sort((n1, n2) => {
      if (n1.konkurrenceDato == undefined || n2.konkurrenceDato == undefined) {
        return 0;
      }

      if (n1.konkurrenceDato.seconds > n2.konkurrenceDato?.seconds) {
        return 1;
      }

      if (n1.konkurrenceDato?.seconds < n2.konkurrenceDato?.seconds) {
        return -1;
      }

      return 0;
    }
    );

    for (let i = 0; i < sortedkredsOverView.length; i++) {

      const found = grouped.filter(a => a.konkId === sortedkredsOverView[i].konkId);

      if (found.length === 0) {
        const item = {
          konkId: sortedkredsOverView[i].konkId,
          konkurrenceDato: sortedkredsOverView[i].konkurrenceDato,
          arrangoer: sortedkredsOverView[i].arrangoer,
        };

        grouped.push(item);
      }
    }

    return grouped;
  }
}
