<div *ngIf="rallyDmCandidateResponse">
  <div class="pageHeader">
    <h1>Udtagelseplaceringer til DM</h1>

    <div class="flex-container wrap">
      <app-dch-select style="width: 350px" [(selectedValue)]="regionSelectedValue" [selectItems]="regionSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
    </div>

    <h4>Udtagelsesperiode: {{rallyDmCandidateResponse.startDate | dcHFullDate: rallyDmCandidateResponse.endDate}}</h4>
  </div>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="rallyDmCandidates" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="dchTableResDetailsContainer">
    <app-dch-data-table [dataTable]="getDcHDataTableDetails()" [dataSource]="data?.dmResults"></app-dch-data-table>
  </div>
</ng-template>

