<div class="pageHeader">
  <h1>Udtagelseplaceringer til DM</h1>

  <div class="flex-container wrap">
    <app-dch-select style="flex-grow: 2; min-width: 350px" [(selectedValue)]="regionSelectedValue" [selectItems]="regionSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
    <app-dch-select style="flex-grow: 1; min-width: 250px" [(selectedValue)]="agilitySizeSelectedValue" [selectItems]="agilitySizeSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>

  <h4 *ngIf="agilityDmCandidateResponse">
    Udtagelsesperiode: {{agilityDmCandidateResponse.startDate | dcHFullDate: agilityDmCandidateResponse.endDate}}
  </h4>

</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="agilityDmCandidates" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="dchTableResDetailsContainer">
    <app-dch-data-table [dataTable]="getDcHDataTableDetails()" [dataSource]="data?.dmResults"></app-dch-data-table>
  </div>
</ng-template>
