import { Pipe, PipeTransform } from '@angular/core';
import { DcHKonkurrenceArt } from '../models.generated/dchEnums';


@Pipe({
    name: 'dcHKonkurrenceArtText',
    standalone: true
})
export class DcHKonkurrenceArtText implements PipeTransform {

  transform(konkurrenceArt: DcHKonkurrenceArt | undefined): string {
    if (konkurrenceArt == undefined || konkurrenceArt == DcHKonkurrenceArt.DcHKonkurrenceArtNone) {
      return '';
    }

    return DcHKonkurrenceArt[konkurrenceArt];
  }
}
