import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpErrorData } from '../models/http-error-data';
import { HttpDataService } from './http-data.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorService {

  errorMsg$: Observable<string>;

  constructor(private readonly httpDataService: HttpDataService) {

    this.errorMsg$ = this.httpDataService.httpErrorData$
      .pipe(
        map((httpErrorData: HttpErrorData) => {
          return this.getErrorMsg(httpErrorData);
        }));
  }

  private getErrorMsg(httpErrorData: HttpErrorData): string {

    if (httpErrorData == undefined) {
      return '';
    }

    if (httpErrorData.status === 200) {
      return '';
    }

    if (httpErrorData.status === 204) {
      return 'Ingen data';
    }

    if (httpErrorData.status >= 400 && httpErrorData.status < 500) {

      if (httpErrorData.reason) {
        return httpErrorData.reason;
      }

      return 'Ugyldig forespørgsel';
    }

    if (httpErrorData.status >= 500) {
      if (httpErrorData.reason?.length > 0) {
        return httpErrorData.reason;
      }
      return 'Fejl i kommunikation med server';
    }

    return `Uventet fejl (code:${httpErrorData.status})`;
  }
}
