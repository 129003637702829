import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { AgilitySizeHelpers } from '../../../helpers/agility-size.helpers';
import { KlasseTypeHelpers } from '../../../helpers/klasse-type-helpers';
import { AgilityDogOfTheYearResponse } from '../../../models.generated/agility';
import { AgilitySize, DcHKlasseType, KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { AgilityDogOfTheYearWrapper } from './agility-dog-of-the-year-wrapper';

@Component({
  selector: 'app-agility-dog-of-the-year',
  templateUrl: './agility-dog-of-the-year.component.html',
  styleUrls: ['./agility-dog-of-the-year.component.css'],
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, MatExpansionModule, DcHFullDatePipe, DcHDataTableComponent]
})

export class AgilityDogOfTheYearComponent implements OnInit {
  agilityDogOfTheYearResponse: AgilityDogOfTheYearResponse;
  agilityDogOfTheYear: AgilityDogOfTheYearWrapper[];
  klasseTypeSelectItems = new KlasseTypeHelpers().getAgilityKlasseTypeSelectItems();
  klasseTypeSelectedValue = this.klasseTypeSelectItems[0].value;
  prevKlasseTypeSelectValue = this.klasseTypeSelectedValue;
  agilitySizeSelectItems = new AgilitySizeHelpers().getAgilitySelectItems();
  agilitySizeSelectedValue = this.agilitySizeSelectItems[0].value;
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.fetchData$(this.klasseTypeSelectItems[0].value).subscribe(a => {
      this.agilityDogOfTheYearResponse = a;
      this.agilityDogOfTheYear = this.getFilteredData(this.agilityDogOfTheYearResponse, this.agilitySizeSelectItems[0].value);
    });
  }

  selectionChangeHandler(): void {

    if (this.prevKlasseTypeSelectValue === this.klasseTypeSelectedValue) {
      this.agilityDogOfTheYear = this.getFilteredData(this.agilityDogOfTheYearResponse, this.agilitySizeSelectedValue);
    } else {
      this.fetchData$(this.klasseTypeSelectedValue).subscribe(a => {
        this.prevKlasseTypeSelectValue = this.klasseTypeSelectedValue;
        this.agilityDogOfTheYearResponse = a;
        this.agilityDogOfTheYear = this.getFilteredData(this.agilityDogOfTheYearResponse, this.agilitySizeSelectedValue);
      });
    }
  }

  private fetchData$(dcHKlasseType: DcHKlasseType): Observable<AgilityDogOfTheYearResponse> {
    const url = `api/AgilityData/GetAgilityDogOfTheYear/${DcHKlasseType[dcHKlasseType]}`;
    return this.httpDataService.getData<AgilityDogOfTheYearResponse>(url);
  }

  private getFilteredData(agilityDogOfTheYearResponse: AgilityDogOfTheYearResponse, agilitySize: AgilitySize): AgilityDogOfTheYearWrapper[] {
    return agilityDogOfTheYearResponse.agilityDogOfTheYear.filter(a => a.dchEkvipage?.agilitySize === agilitySize).map(a => (a as any) as AgilityDogOfTheYearWrapper);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.AG;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Point1', field: 'results[0].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Point2', field: 'results[1].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Point3', field: 'results[2].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Sum', field: 'sum3', bold: true, textalign: 'center', dataType: 'number', format: '1.0-0' },
      { headerText: 'Point4', field: 'results[3].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Point5', field: 'results[4].point', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Hundfødt', field: 'dchEkvipage.dogBirthDate', textalign: 'center', dataType: 'timeStamp' }
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.AG;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Point', field: 'point', textalign: 'center', bold: true, dataType: 'number', format: '1.0-0' }
    ]

    return dataTable;
  }
}
