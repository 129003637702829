import { AgilitySize } from '../models.generated/dchEnums';
import { SelectItem } from '../models/select-item';

export class AgilitySizeHelpers {
  getAgilitySelectItems(): SelectItem<AgilitySize>[] {

    return [new SelectItem<AgilitySize>(this.getAgilitySizeText(AgilitySize.Lille), AgilitySize.Lille),
    new SelectItem<AgilitySize>(this.getAgilitySizeText(AgilitySize.Mellem), AgilitySize.Mellem),
    new SelectItem<AgilitySize>(this.getAgilitySizeText(AgilitySize.Intermediate), AgilitySize.Intermediate),
    new SelectItem<AgilitySize>(this.getAgilitySizeText(AgilitySize.Stor), AgilitySize.Stor)
    ];
  }

  private getAgilitySizeText(agilitySize: AgilitySize): string {

    switch (agilitySize) {
      case AgilitySize.Lille:
        return 'Small';
      case AgilitySize.Mellem:
        return 'Medium';
      case AgilitySize.Intermediate:
        return 'Intermediate';
      case AgilitySize.Stor:
        return 'Large';

      default:
        return agilitySize.toString();
    }
  }
}
