import { DcHKlasseType } from '../models.generated/dchEnums';
import { SelectItem } from '../models/select-item';

export class KlasseTypeHelpers {
  getAgilityKlasseTypeSelectItems(): SelectItem<DcHKlasseType>[] {
    return [
      new SelectItem<DcHKlasseType>(this.getKlasseTypeText(DcHKlasseType.Agility3Year), DcHKlasseType.Agility3Year),
      new SelectItem<DcHKlasseType>(this.getKlasseTypeText(DcHKlasseType.AgilitySenYear), DcHKlasseType.AgilitySenYear)
    ];
  }

  getKlasseTypeText(dcHKlasseType: DcHKlasseType): string {

    switch (dcHKlasseType) {
      case DcHKlasseType.Agility3Year:
        return 'Årest klasse 3 hund';
      case DcHKlasseType.AgilitySenYear:
        return 'Årest senior hund';
      case DcHKlasseType.LydABCE:
        return 'DcH-Program klasser (A, B, C og E)';
      case DcHKlasseType.LydETurUdtag:
        return 'Udtagelse til E turnering';
      case DcHKlasseType.LydIPG3:
        return 'Brugsprøve IGP3';
      case DcHKlasseType.LydIGPFH:
        return 'Brugsprøve IGP FH';
      case DcHKlasseType.LydCOprykkere:
        return 'C oprykkere';
      case DcHKlasseType.NoseWorkKlasse:
        return 'Nose work';
      default:
        return DcHKlasseType[dcHKlasseType];
    }
  }
}
