import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { AgilitySizeHelpers } from '../../../helpers/agility-size.helpers';
import { DchKlasseHelpers } from '../../../helpers/dch-klasse-helpers';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { AgilityDmCandidateResponse } from '../../../models.generated/agility';
import { AgilitySize, DcHKreds, KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { AgilityDmCandidateWrapper } from './agility-dm-candidate-wrapper';

@Component({
  selector: 'app-agility-dm-candidates',
  templateUrl: './agility-dm-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, DcHDataTableComponent]
})
export class AgilityDmCandidatesComponent implements OnInit {
  agilityDmCandidateResponse: AgilityDmCandidateResponse;
  agilityDmCandidates: AgilityDmCandidateWrapper[] | undefined;
  regionSelectItems = new RegionHelpers().getRegionSelectItems(true);
  regionSelectedValue = this.regionSelectItems[0].value;
  agilitySizeSelectItems = new AgilitySizeHelpers().getAgilitySelectItems();
  agilitySizeSelectedValue = this.agilitySizeSelectItems[0].value;
  private prevAgilitySizeSelectedValue = this.agilitySizeSelectedValue;
  dataTableDef = this.getDcHDataTable();
  

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.fetchData$(this.agilitySizeSelectItems[0].value)
      .subscribe(a => {
        this.agilityDmCandidateResponse = a;
        this.agilityDmCandidates = this.getFilteredData(this.agilityDmCandidateResponse, this.regionSelectItems[0].value);
      });
  }

  selectionChangeHandler(): void {
    if (this.agilitySizeSelectedValue === this.prevAgilitySizeSelectedValue) {
      this.agilityDmCandidates = this.getFilteredData(this.agilityDmCandidateResponse, this.regionSelectedValue);
    }
    else {
      this.agilityDmCandidates = undefined;
      this.prevAgilitySizeSelectedValue = this.agilitySizeSelectedValue;
      this.fetchData$(this.agilitySizeSelectedValue).subscribe(a => {
        this.agilityDmCandidateResponse = a;
        this.agilityDmCandidates = this.getFilteredData(this.agilityDmCandidateResponse, this.regionSelectedValue);
      });
    }
  }

  private getFilteredData(dmCandidateResponse: AgilityDmCandidateResponse, dchKreds: DcHKreds): AgilityDmCandidateWrapper[] {

    if (dmCandidateResponse == undefined) {
      return [];
    }

    let agilityDmCandidates = dmCandidateResponse.agilityDmCandidates;

    if (dchKreds !== DcHKreds.DcHLands) {
      agilityDmCandidates = dmCandidateResponse.agilityDmCandidates.filter(a => a.dchEkvipage?.dchKreds === dchKreds);
    }

    return agilityDmCandidates.map(a => (a as any) as AgilityDmCandidateWrapper);
  }

  private fetchData$(agilitySize: AgilitySize): Observable<AgilityDmCandidateResponse> {
    const url = `api/AgilityData/GetAgilityDmCandidates/${AgilitySize[agilitySize]}`;
    return this.httpDataService.getData<AgilityDmCandidateResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.AG;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Udtaget', field: 'dchEkvipage.ekvipageNote' }
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let klasseHelpers = new DchKlasseHelpers();
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.AG;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Klasse', field: 'klasseKey', dataType: 'string', fnFormatter: ((a: string) => klasseHelpers.getKlasseText(a, KonkurrenceType.AG, false)) }
    ]

    return dataTable;
  }
}
