import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KonkurrenceType } from '../../models.generated/dchEnums';
import { DcHEkvipage } from '../../models.generated/shared';
import { ShortenHundeforer } from '../../pipes/shorten-hundeforer.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ekvipage-link',
  templateUrl: './ekvipage-link.component.html',
  standalone: true,
  imports: [CommonModule, ShortenHundeforer]
})
export class EkvipageLinkComponent implements OnInit {
  @Input()
  ekvipage: DcHEkvipage | undefined;

  @Input()
  konkurrenceType: string | KonkurrenceType;

  getScreenWidth: number;

  constructor(private readonly router: Router) {
  }

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }

  onClicked(): void {
    let myKonkurrenceType = typeof (this.konkurrenceType) === 'string' ? this.konkurrenceType : KonkurrenceType[this.konkurrenceType];

    if (this.ekvipage != undefined) {
      const url = '/ekvipageResultater';
      if (myKonkurrenceType == undefined) {
        this.router.navigate([url, this.ekvipage.profileId, this.ekvipage.dogProfileId]);
      }
      else {
        this.router.navigate([url, this.ekvipage.profileId, this.ekvipage.dogProfileId, myKonkurrenceType]);
      }
    }
  }
}
