import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortenHundeforer',
    standalone: true
})
export class ShortenHundeforer implements PipeTransform {
  transform(hundeforer: string | undefined, screenWidth: number): string {

    if (hundeforer == undefined) {
      return "-";
    }

    if (screenWidth < 450) {
      return this.shortenText(hundeforer);
    }

    return hundeforer;
  }

  private shortenText(text: string): string {
    let result = text;
    const splittedtext = text.replace(/\s+/g, ' ').trim().split(' ', 4);

    if (splittedtext.length > 2) {
      result = splittedtext[0] + ' '

      for (let i = 1; i < splittedtext.length - 1; i++) {
        result += splittedtext[i].substring(0, 1) + '. ';
      }

      result += splittedtext[splittedtext.length-1]
    }

    if (result.length > 18 && splittedtext.length> 1) {
      result = splittedtext[0] + ' ' + splittedtext[splittedtext.length -1];
    }

    if (result.length > 18 && splittedtext.length > 1) {
      result = splittedtext[0].substring(0, 1) + '. ' + splittedtext[splittedtext.length -1];
    }

    return result;
  }
}
