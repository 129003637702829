import { Component, Input } from '@angular/core';
import { KlubmodulStatus } from '../../models.generated/dchEnums';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { DchKlubModulHelpers } from '../../helpers/dch-klub-modul-helpers';

@Component({
  selector: 'app-dch-tilmeld-status',
  templateUrl: './dch-tilmeld-status.component.html',
  standalone: true,
  imports: [CommonModule, MatIconModule]
})

export class DcHTilmeldStatusComponent {

  klubModulHelpers = new DchKlubModulHelpers();
  @Input()
  klubmodulStatus: KlubmodulStatus;


  myKlubmodulStatus = KlubmodulStatus;
}
