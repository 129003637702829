<mat-form-field *ngIf="myFormControl==undefined" appearance="fill" style="width: 100%" subscriptSizing="dynamic">
  <mat-label>{{selectLabel}}</mat-label>
  <mat-select (selectionChange)="selectionChangeHandler()" [(ngModel)]="selectedValue">
    <mat-option *ngFor="let item of selectItems" [value]="item.value">
      {{ item.displayText}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field *ngIf="myFormControl!=undefined" appearance="fill" style="width: 100%" subscriptSizing="dynamic">
  <mat-label>{{selectLabel}}</mat-label>
  <mat-select (selectionChange)="selectionChangeHandler()" [formControl]="myFormControl" >
    <mat-option *ngFor="let item of selectItems" [value]="item.value">
      {{ item.displayText}}
    </mat-option>
  </mat-select>
</mat-form-field>
