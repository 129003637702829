import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { DcHBrugsKlasseHelpers } from '../../../helpers/dch-brugs-klasse-helpers';
import { KredsOverViewPivotHelper, PivotRow } from '../../../helpers/kreds-overview-pivot-helpers';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { DcHBrugsKlasse, DcHKreds, KonkurrenceType } from '../../../models.generated/dchEnums';
import { Timestamp } from '../../../models.generated/google/protobuf/timestamp';
import { DcHKredsOverViewResponse } from '../../../models.generated/shared';
import { SelectItem } from '../../../models/select-item';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';

@Component({
  selector: 'app-brugs-kreds-overview',
  templateUrl: './brugs-kreds-overview.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, DcHDataTableComponent]
})
export class BrugsKredsOverViewComponent implements OnInit {
  startDate: Timestamp | undefined;
  endDate: Timestamp | undefined;
  regionSelectItems = new RegionHelpers().getRegionSelectItems(false);
  regionSelectedValue = this.regionSelectItems[0].value;
  klasseSelectItems: SelectItem<DcHBrugsKlasse>[];
  klasseSelectedValue: DcHBrugsKlasse;
  pivotResults: PivotRow[];
  dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.BR);
  private brugsKlasseHelpers = new DcHBrugsKlasseHelpers();
  
  constructor(private readonly httpDataService: HttpDataService, private readonly kredsOverViewPivotHelper: KredsOverViewPivotHelper) {
    this.klasseSelectItems = this.brugsKlasseHelpers.getKlasseSelectItems();
    this.klasseSelectedValue = this.klasseSelectItems[0].value;
  }

  ngOnInit(): void {
    this.fetchData$(this.regionSelectedValue, this.klasseSelectedValue).subscribe(a => {
      this.startDate = a.startDate;
      this.endDate = a.endDate;
      this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a.dcHKredsOverViews);
      this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.BR, this.pivotResults[0]);
      this.pivotResults.shift();
    });
  }

  selectionChangeHandler(): void {
    this.fetchData$(this.regionSelectedValue, this.klasseSelectedValue).subscribe(a => {
      this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a.dcHKredsOverViews);
      this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.BR, this.pivotResults[0]);
      this.pivotResults.shift();
    });
  }

  private fetchData$(region: DcHKreds, klasse: DcHBrugsKlasse): Observable<DcHKredsOverViewResponse> {
    const url = `api/BrugsData/getBrugsKredsOverView/${DcHKreds[region]}/${DcHBrugsKlasse[klasse]}`;
    return this.httpDataService.getData<DcHKredsOverViewResponse>(url);
  }
}
