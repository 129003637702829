import { Pipe, PipeTransform } from '@angular/core';
import { HoopersSize } from '../models.generated/dchEnums';

@Pipe({
    name: 'hoopersSizeText',
    standalone: true
})
export class HoopersSizeText implements PipeTransform {
  transform(hoopersSize: HoopersSize | undefined): string {

    if (hoopersSize == undefined || hoopersSize === HoopersSize.HoopersSizeNone) {
      return '';
    }

    switch (hoopersSize) {
      case HoopersSize.HoopersSizeSmall:
        return "Lille";
      case HoopersSize.HoopersSizeLarge:
        return "Stor";
    }

    return hoopersSize?.toString();
  }
}
