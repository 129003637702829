<div class="pageHeader">
  <h1>Breaking news</h1>
</div>

  <div class="pageBody">
      <button mat-raised-button color="secondary" (click)="createRowHandler()" [disabled]="(httpDataService.loading$ | async)" style="margin: 10px;"><mat-icon>note_add</mat-icon> Create</button>
      <app-dch-data-table #datatable [dataTable]="dataTableDef" [dataSource]="bpMessages" [rowMenuItems]="rowMenuItems" [editRowData]="dataRowDetails" style="margin: 10px;"></app-dch-data-table>
  </div>

  <ng-template #rowMenuItems let-data>
    <button mat-menu-item color="primary" (click)="editClicked(data)"><mat-icon>edit</mat-icon> Edit</button>
    <button mat-menu-item color="warn" (click)="deleteClicked(data)"><mat-icon>delete</mat-icon> Delete</button>
  </ng-template>


<ng-template #dataRowDetails let-data>
    <div class="breakingNewsDetailsContainer">
      <h3>Breaking news</h3>
      <div class="breakingNewsDetailsForm">
        <form (ngSubmit)="onFormSubmit(data)" [formGroup]="getFormGroup(data)">
          <div>
            <div class="flex-container wrap">
              <mat-form-field appearance="fill">
                <mat-label>Severity</mat-label>
                <mat-select [formControl]="konkurrenceTypeFormControl">
                  <mat-option *ngFor="let item of konkurrenceTypeSelectItems" [value]="item.value">
                    {{ item.displayText }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Version</mat-label>
                <input matInput [formControl]="versionFormControl" required />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Severity</mat-label>
                <mat-select [formControl]="severityFormControl">
                  <mat-option *ngFor="let item of severitySelectItems" [value]="item.value">
                    {{ item.displayText }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="fill" style="width:300px">
                <mat-label>Header</mat-label>
                <input matInput [formControl]="headerFormControl" required />
              </mat-form-field>

              <mat-form-field appearance="fill" style="width:300px">
                <mat-label>Message</mat-label>
                <input matInput [formControl]="messageFormControl" required />
              </mat-form-field>
            </div>

            <mat-form-field appearance="fill" style="width:100%;">
              <mat-label>HtmlContent</mat-label>
              <textarea matInput [formControl]="htmlContentFormControl" rows="10"></textarea>
            </mat-form-field>
          </div>

          <div class="saveBreakingNewsDetailsButton">
            <button mat-raised-button color="secondary" type="submit" [disabled]="disableSaveButton || (httpDataService.loading$ | async)"><mat-icon>save</mat-icon> Gem</button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
