export class UserInfo {
  username: string;
  roles: string[] | string;

  hasRole(role: string): boolean {

    if (this.roles != undefined && Array.isArray(this.roles)) {
      return this.roles.some(a => a === role) == true;
    }

    return this.roles === role;

  }
}
