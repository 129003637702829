import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-story-404',
  template: `
    <article>
      <h4>Æv!</h4>
      <div>Siden {{router.url}} du søger findes ikke.</div>
    </article>
  `
})

export class PageNotFoundComponent {

  constructor(public router: Router) { }
}
