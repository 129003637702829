import { Pipe, PipeTransform } from '@angular/core';
import { RallyHeight } from '../models.generated/dchEnums';

@Pipe({
    name: 'rallyHeightText',
    standalone: true
})
export class RallyText implements PipeTransform {
  transform(rallyHeight: RallyHeight | undefined): string {

    if (rallyHeight == undefined || rallyHeight === RallyHeight.RallyHeightNone) {
      return '';
    }

    return RallyHeight[rallyHeight];
  }
}
