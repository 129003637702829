import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { HttpErrorData } from '../models/http-error-data';
import { Router } from '@angular/router';
import { ApiResponse } from '../models.generated/misc';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly authenticationService: AuthenticationService, private readonly router: Router) {}

  intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

    return newRequest.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // if 401 response returned from api, logout from application & redirect to login page.
          this.authenticationService.logout();
          this.router.navigate(['/admin/login']);
        }

        const httpErrorData = this.buildErrorData(err);
        return throwError(httpErrorData);
      }));
  }

  buildErrorData(err: HttpErrorResponse): HttpErrorData {
    const httpErrorData = new HttpErrorData();

    if (err.error instanceof ErrorEvent) {
      httpErrorData.reason = err.error.message ? err.error.message : 'no message from client';
      httpErrorData.status = err.status;
    } else { // server side error
      if (err.error) {
        const apiResponse = err.error as ApiResponse;

        if (apiResponse && apiResponse.statusCode) {
          httpErrorData.status = apiResponse.statusCode;
          httpErrorData.reason = apiResponse.statusText + ': ' + apiResponse.message;
          return httpErrorData;
        }

        if (err.error.message) {
          httpErrorData.reason = err.error.message;
        } else if (err.message) {
          httpErrorData.reason = err.message;
        } else {
          httpErrorData.reason = 'no message from server';
        }

        httpErrorData.status = err.status !== 0 ? err.status : 504;
      }
      else {
        httpErrorData.reason = err.message;
        httpErrorData.status = err.status;
      }
    }

    return httpErrorData;
  }
}
