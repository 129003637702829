  <h2 mat-dialog-title>
  {{myData.title}}
</h2>

  <mat-dialog-content>
    <div class="pageBody iosWorkAround">
      <div *ngFor="let line of lines">
        {{line}}
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
    <button *ngIf="myData.dialogType==='OkCancel'" mat-button [mat-dialog-close]="false">Cancel</button>
  </mat-dialog-actions>
