import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { ModalResultComponent } from '../../../components.shared/modal-result/modal-result.component';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { DcHBrugsKlasseHelpers } from '../../../helpers/dch-brugs-klasse-helpers';
import { BrugsCompetitionResponse, BrugsKlasseDataResults, BrugsResult } from '../../../models.generated/brugs';
import { DcHBrugsKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { ModalResultDialogData } from '../../../models/modal-result-dialog-data';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';

@Component({
  selector: 'app-brugs-results',
  templateUrl: './brugs-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHKonkurrenceArtText, DcHSelectComponent, SelectCompetitionComponent, DcHDataTableComponent]
})
export class BrugsResultaterComponent implements OnInit {
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  competitionResponse: BrugsCompetitionResponse;
  klasseResults: BrugsKlasseDataResults | undefined;
  klasseSelectItems: SelectItem<DcHBrugsKlasse>[];
  klasseSelectedValue: DcHBrugsKlasse;
  dataTableDef = this.getDcHDataTable();
  private prevSelectedKonkId = 0;
  private dcHBrugsKlasseHelpers = new DcHBrugsKlasseHelpers();

  @Input()
  importMode = false;

  @Input()
  competitionResponses: BrugsCompetitionResponse[];

  constructor(private readonly dialog: MatDialog, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.klasseResults = this.competitionResponse.klasseDataResults[0];
      this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.dcHBrugsKlasseHelpers.getKlasseSelectItem(item.klasse));
      this.klasseSelectedValue = this.klasseSelectItems[0].value;
      this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
    }
  }

  selectionChangeHandler(): void {

    this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);

    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasse === this.klasseSelectedValue);
    }
    else if (this.importMode) {
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.dcHBrugsKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
        this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
      } else {
        this.klasseSelectItems = [];
        this.klasseSelectedValue = DcHBrugsKlasse.DcHBrugsKlasseNone;
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchBrugsCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.klasseResults = a.klasseDataResults[0];
        this.klasseSelectItems = a.klasseDataResults.map(item => this.dcHBrugsKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
        this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
      });
    }
  }

  private openModal(data: BrugsResult): void {
    const modalResultDialogData: ModalResultDialogData = { ekvipage: data.dchEkvipage, eventId: this.competionDataSelectedValue.konkId, klasse: DcHBrugsKlasse[this.klasseSelectedValue], konkurrenceType: KonkurrenceType.BR, live: false };

    this.dialog.open(ModalResultComponent, {
      data: modalResultDialogData
    });
  }

  private getDcHDataTable(klasse: DcHBrugsKlasse | undefined = undefined): DcHDataTable {
    let isBestaetKlasse = klasse === DcHBrugsKlasse.BH || klasse === DcHBrugsKlasse.AD;
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.BR;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
    ];

    if (isBestaetKlasse) {
      dataTable.columns.push(
        { headerText: 'Bestået', field: 'total', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: number) => a === 1 ? 'done' : '' }
      );
    }
    else {
      dataTable.columns.unshift({ headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' });
      dataTable.columns.push({ headerText: 'Total', field: 'total', textalign: 'center', dataType: 'number', format: '1.2-2', fnCellClicked: (a: BrugsResult) => { this.openModal(a) } });
    }

    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: BrugsCompetitionResponse[]) {
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId
      }
    });
  }
}
