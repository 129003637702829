import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthorizationCheck  {

  constructor(private readonly router: Router, private readonly authenticationService: AuthenticationService) {}

// ReSharper disable once InconsistentNaming
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authenticationService.getIsLoggedIn();

    if (!isLoggedIn) {
      this.router.navigate(['/admin/login'], { queryParams: { returnUrl: state.url } });
    }

    return isLoggedIn;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthorizationCheck).canActivate(next, state);
}
