import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMaterialModule } from '../../app-material.module';
import { HttpErrorData } from '../../models/http-error-data';
import { AuthenticationService } from '../../services/authentication.service';

// https://www.dotnettricks.com/learn/angular/token-based-authentication-json-web-token-aspnet-core

@Component({
  templateUrl: 'login.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule, ReactiveFormsModule]
})
export class LoginComponent implements OnInit {

  submitClick = false;
  returnUrl: string;
  error = '';

  loginFormGroup = new FormGroup({
    userName: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    passWord: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit() {

    this.submitClick = true;

    if (this.loginFormGroup.value.userName == undefined || this.loginFormGroup.value.passWord == undefined) {
      return;
    }

    this.authenticationService.login(this.loginFormGroup.value.userName, this.loginFormGroup.value.passWord)
      .subscribe(() => { this.router.navigate([this.returnUrl]) },
        (err: HttpErrorData) => {
          console.error(err);
          this.error = err.reason;
          this.submitClick = false;
        },
        () => this.submitClick = false
      )
  }
}
