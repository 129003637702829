<div class="pageHeader">
  <h1>Udtagelseplaceringer til DM</h1>
  <h4 *ngIf="agilityDmCandidateResponse">
    Udtagelsesperiode: {{agilityDmCandidateResponse.startDate | dcHFullDate: agilityDmCandidateResponse.endDate}}
  </h4>
</div>
<div class="pageBody">
  <div class="noDataToShow">
    Her kommer snart dm kandidater
  </div>
</div>

