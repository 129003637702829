import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { KredsOverViewPivotHelper, PivotRow } from '../../../helpers/kreds-overview-pivot-helpers';
import { DcHLydighedKlasseHelpers } from '../../../helpers/dch-lydighed-klasse-helpers';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { DcHKreds, DcHLydighedKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { Timestamp } from '../../../models.generated/google/protobuf/timestamp';
import { DcHKredsOverViewResponse } from '../../../models.generated/shared';
import { SelectItem } from '../../../models/select-item';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';

@Component({
  selector: 'app-lydighed-kreds-overview',
  templateUrl: './lydighed-kreds-overview.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, DcHDataTableComponent]
})
export class LydighedKredsOverViewComponent implements OnInit {
  startDate: Timestamp | undefined;
  endDate: Timestamp | undefined;
  regionSelectItems = new RegionHelpers().getRegionSelectItems(false);
  regionSelectedValue = this.regionSelectItems[0].value;
  klasseSelectItems: SelectItem<DcHLydighedKlasse>[];
  klasseSelectedValue: DcHLydighedKlasse;
  pivotResults: PivotRow[];
  dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.LY);
  private lydighedKlasseHelpers = new DcHLydighedKlasseHelpers();

  constructor(private readonly httpDataService: HttpDataService, private readonly kredsOverViewPivotHelper: KredsOverViewPivotHelper) {
    this.klasseSelectItems = this.lydighedKlasseHelpers.getKlasseSelectItems();
    this.klasseSelectedValue = this.klasseSelectItems[0].value;
  }

  ngOnInit(): void {
    this.fetchData$(this.regionSelectedValue, this.klasseSelectedValue).subscribe(a => {
      this.startDate = a.startDate;
      this.endDate = a.endDate;
      this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a.dcHKredsOverViews);
      this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.LY, this.pivotResults[0]);
      this.pivotResults.shift();
    });
  }

  selectionChangeHandler(): void {
    this.fetchData$(this.regionSelectedValue, this.klasseSelectedValue).subscribe(a => {
      this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a.dcHKredsOverViews);
      this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.LY, this.pivotResults[0]);
      this.pivotResults.shift();
    });
  }

  private fetchData$(region: DcHKreds, klasse: DcHLydighedKlasse): Observable<DcHKredsOverViewResponse> {
    const url = `api/LydighedData/getLydighedKredsOverView/${DcHKreds[region]}/${DcHLydighedKlasse[klasse]}`;
    return this.httpDataService.getData<DcHKredsOverViewResponse>(url);
  }
}
