import { Component, OnInit } from '@angular/core';
import { HttpDataService } from '../../services/http-data.service';
import { Observable } from 'rxjs';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-lku-information',
  templateUrl: './lku-information.component.html',
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe]
})
export class LkuInformationComponent implements OnInit {
  lkuInformation$: Observable<string>;

  constructor(readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.lkuInformation$ = this.fetchData$();
  }

  private fetchData$(): Observable<string> {
    const url = 'api/lkuInformation/GetInformation';
    return this.httpDataService.getData<string>(url);
  }
}
