import { DcHHoopersKlasseHelpers } from '../../../helpers/dch-hoopers-klasse-helpers';
import { HoopersResult } from '../../../models.generated/hoopers';

export class HoopersLiveResultaterHelpers extends DcHHoopersKlasseHelpers {

  sortKlasseDataResults(results: HoopersResult[], sortOrder: 'placer' | 'startNo'): void {
    if (sortOrder === 'placer') {
      this.sortKlasseDataResultsByPlacer(results);
    } else {
      this.sortKlasseDataResultsByStartNo(results);
    }
  }

  private sortKlasseDataResultsByPlacer(results: HoopersResult[]): void {
    results.sort((a, b) => {
      if (a.placer === 250 && b.placer !== 250) {
        return 1;
      } else if (a.placer !== 250 && b.placer === 250) {
        return -1;
      } else if (a.placer < b.placer) {
        return -1;
      } else if (a.placer > b.placer) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private sortKlasseDataResultsByStartNo(results: HoopersResult[]): void {
    results.sort((a, b) => {
      if (a.startNo == undefined && b.startNo == undefined) {
        return 0;
      }

      if (a.startNo == undefined) {
        return -1;
      }

      if (b.startNo == undefined) {
        return 1;
      }

      if (a.startNo < b.startNo) {
        return -1;
      } else if (a.startNo > b.startNo) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
