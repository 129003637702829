  <h2 mat-dialog-title>
  {{myData.ekvipage?.hundeforer}} m. {{myData.ekvipage?.hund}}
</h2>

  <mat-dialog-content>
    <div class="pageBody iosWorkAround">
      <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="resultLineResponse?.resultLines"></app-dch-data-table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Luk</button>
  </mat-dialog-actions>
