import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, catchError, from, Observable, of, Subject, throwError } from 'rxjs';
import { map, mergeMap, skipWhile, takeUntil, tap } from 'rxjs/operators';
import { AgilityLiveDataResponse } from '../models.generated/agility';
import { BrugsLiveDataResponse } from '../models.generated/brugs';
import { KonkurrenceType } from '../models.generated/dchEnums';
import { HoopersLiveDataResponse } from '../models.generated/hoopers';
import { LydighedLiveDataResponse } from '../models.generated/lydighed';
import { NordiskLiveDataResponse } from '../models.generated/nordisk';
import { NoseWorkLiveDataResponse } from '../models.generated/nosework';
import { RallyLiveDataResponse } from '../models.generated/rally';
import { DcHFileUploadData } from '../models/dch-file-upload-data';
import { WebSockectGroupInfo } from '../models/web-socket-grop-info';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class WebSocketService {

  private hubConnection: signalR.HubConnection;
  private _fileUploaded$ = new Subject<DcHFileUploadData>();
  private _agilityLiveDataUpdated$ = new Subject<AgilityLiveDataResponse>();
  private _hoopersLiveDataUpdated$ = new Subject<HoopersLiveDataResponse>();
  private _brugsLiveDataUpdated$ = new Subject<BrugsLiveDataResponse>();
  private _lydighedLiveDataUpdated$ = new Subject<LydighedLiveDataResponse>();
  private _nordiskLiveDataUpdated$ = new Subject<NordiskLiveDataResponse>();
  private _noseworkLiveDataUpdated$ = new Subject<NoseWorkLiveDataResponse>();
  private _rallyLiveDataUpdated$ = new Subject<RallyLiveDataResponse>();
  private _hubConnectedState$ = new BehaviorSubject<signalR.HubConnectionState | undefined>(undefined);
  private _numberOfNotImportedFiles$ = new BehaviorSubject(0);
  private stopSubscribtion$ = new Subject<void>();
  private _groupInfo: WebSockectGroupInfo;

  get numberOfNotImportedFiles$(): Observable<number> {
    return this._numberOfNotImportedFiles$.asObservable();
  }

  get hubConnectedState$(): Observable<signalR.HubConnectionState | undefined> {
    return this._hubConnectedState$.asObservable();
  }

  get fileUploaded$(): Observable<DcHFileUploadData> {
    return this._fileUploaded$.asObservable();
  }

  get hoopersLiveDataUpdated$(): Observable<HoopersLiveDataResponse> {
    return this._hoopersLiveDataUpdated$.asObservable();
  }

  get agilityLiveDataUpdated$(): Observable<AgilityLiveDataResponse> {
    return this._agilityLiveDataUpdated$.asObservable();
  }

  get brugsLiveDataUpdated$(): Observable<BrugsLiveDataResponse> {
    return this._brugsLiveDataUpdated$.asObservable();
  }

  get lydighedLiveDataUpdated$(): Observable<LydighedLiveDataResponse> {
    return this._lydighedLiveDataUpdated$.asObservable();
  }

  get nordiskLiveDataUpdated$(): Observable<NordiskLiveDataResponse> {
    return this._nordiskLiveDataUpdated$.asObservable();
  }

  get noseworkLiveDataUpdated$(): Observable<NoseWorkLiveDataResponse> {
    return this._noseworkLiveDataUpdated$.asObservable();
  }

  get rallyLiveDataUpdated$(): Observable<RallyLiveDataResponse> {
    return this._rallyLiveDataUpdated$.asObservable();
  }

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  changeLiveResultaterGroup$(eventId: number): Observable<signalR.HubConnectionState> {
    return this.leaveGroup(this._groupInfo).pipe(mergeMap(a => {
      this._groupInfo = new WebSockectGroupInfo(this._groupInfo.konkurrenceType, eventId);
      return this.joinGroup(this._groupInfo);
    }));
  }

  unsubscribeLiveResultater$(): Observable<signalR.HubConnectionState> {
    console.log('unsubscribeLiveResultater: ' + KonkurrenceType[this._groupInfo.konkurrenceType] + ' ' + this._groupInfo.eventId);
    this.stopSubscribtion$.next();
    this.stopSubscribtion$.complete();

    if (this._groupInfo != undefined) {
      return this.leaveGroup(this._groupInfo)
        .pipe(
          map((a) => {
            this.stopIfNotAdmin();
            return a;
          }));
    } else {
      this.stopIfNotAdmin();
      return of(this.hubConnection.state);
    }
  }

  initLiveResultater$(konkurrenceType: KonkurrenceType, eventId: number): Observable<signalR.HubConnectionState | undefined> {
    this._groupInfo = new WebSockectGroupInfo(konkurrenceType, eventId);

    return this.hubConnectedState$
      .pipe(
        takeUntil(this.stopSubscribtion$),
        skipWhile(a => a !== signalR.HubConnectionState.Connected),
        tap(() => {
          this.joinGroup(this._groupInfo).subscribe();
        })
      );
  }

  connectToHub$(): Observable<signalR.HubConnectionState> {
    if (this.isConnected()) {
      console.log(`Already connected (${this.hubConnection.state})`);
      return of(this.hubConnection.state);
    }

    this.initWebSocket();
    return this.startConnection$()
      .pipe(
        tap(() => {
          this._hubConnectedState$.next(this.hubConnection.state);
        }),
        catchError((err: any) => {
          this._hubConnectedState$.next(this.hubConnection.state);
          return throwError(err);
        })
      );
  }

  stopConnection$(): Observable<signalR.HubConnectionState> {
    console.log('stopConnection');
    if (this.hubConnection != undefined) {
      this._hubConnectedState$.next(this.hubConnection.state);
      const promise = this.hubConnection.stop().catch((err) => console.error(err));
      return from(promise).pipe(map(a => this.hubConnection.state));
    } else {
      this._hubConnectedState$.next(signalR.HubConnectionState.Disconnected);
    }

    return of();
  }

  private stopIfNotAdmin(): Observable<signalR.HubConnectionState> {
    if (!this.authenticationService.getIsLoggedIn()) {
      return this.stopConnection$();
    }

    return of(this.hubConnection.state)
  }

  private isConnected(): boolean {
    return this.hubConnection != undefined &&
      this.hubConnection.state !== signalR.HubConnectionState.Disconnected &&
      this.hubConnection.state !== signalR.HubConnectionState.Disconnecting;
  }

  private startConnection$(): Observable<signalR.HubConnectionState> {
    const promise = this.hubConnection.start();
    return from(promise).pipe(map(a => this.hubConnection.state));
  }

  private joinGroup(groupInfo: WebSockectGroupInfo): Observable<signalR.HubConnectionState> {
    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      const promise = this.hubConnection.send('AddToGroup', groupInfo.konkurrenceType, groupInfo.action, groupInfo.eventId);
      return from(promise).pipe(map(a => this.hubConnection.state));
    }

    console.log(`joinGroup not connected: (${this.hubConnection.state})`);
    return of(this.hubConnection.state);
  }

  private leaveGroup(groupInfo: WebSockectGroupInfo): Observable<signalR.HubConnectionState> {

    if (this.hubConnection.state === signalR.HubConnectionState.Connected) {
      const promise = this.hubConnection.send('RemoveFromGroup', groupInfo.konkurrenceType, groupInfo.action, groupInfo.eventId);
      return from(promise).pipe(map(() => this.hubConnection.state));
    } else {
      console.log(`leaveGroup not connected: (${this.hubConnection.state})`);
    }

    return of(this.hubConnection.state);
  }

  private initWebSocket(): void {
    this.hubConnection?.stop().catch((err) => console.error(err));
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/dchhub')
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this._hubConnectedState$.next(this.hubConnection.state);

    this.hubConnection.on('FileUploaded', (dcHFileUploadData: DcHFileUploadData) => {
      console.log('FileUploaded');
      this._fileUploaded$.next(dcHFileUploadData);
    });

    this.hubConnection.on('SendMessage', (message: string) => {
      console.log(message);
    });

    this.hubConnection.on('HoopersLiveDataUpdated', (liveDataReply: HoopersLiveDataResponse) => {
      this._hoopersLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('AgilityLiveDataUpdated', (liveDataReply: AgilityLiveDataResponse) => {
      this._agilityLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('BrugsLiveDataUpdated', (liveDataReply: BrugsLiveDataResponse) => {
      this._brugsLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('LydighedLiveDataUpdated', (liveDataReply: LydighedLiveDataResponse) => {
      this._lydighedLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('NordiskLiveDataUpdated', (liveDataReply: NordiskLiveDataResponse) => {
      this._nordiskLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('NoseworkLiveDataUpdated', (liveDataReply: NoseWorkLiveDataResponse) => {
      this._noseworkLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('RallyLiveDataUpdated', (liveDataReply: RallyLiveDataResponse) => {
      this._rallyLiveDataUpdated$.next(liveDataReply);
    });

    this.hubConnection.on('NumberOfNotImportedFiles', (noOfNotImportedFiles: number) => {
      this._numberOfNotImportedFiles$.next(noOfNotImportedFiles);
    });

    this.hubConnection.onreconnecting((error?: Error) => {
      this._hubConnectedState$.next(this.hubConnection.state);
      console.log('WebSocket onreconnecting');
      if (error != undefined) {
        console.error(error);
      }
    });

    this.hubConnection.onreconnected(connectionId => {
      this._hubConnectedState$.next(this.hubConnection.state);
      console.log(`Connection reestablished for connectionId: "${connectionId}".`);
    });

    this.hubConnection.onclose((error?: Error) => {
      this._hubConnectedState$.next(this.hubConnection.state);
      console.log('WebSocket closed');
      if (error != undefined) {
        console.error(error);
      }
    });
  }
}
