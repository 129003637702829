import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { ModalResultComponent } from '../../../components.shared/modal-result/modal-result.component';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { DcHHoopersKlasseHelpers } from '../../../helpers/dch-hoopers-klasse-helpers';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { HoopersCompetitionResponse, HoopersKlasseDataResults, HoopersResult } from '../../../models.generated/hoopers';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { ModalResultDialogData } from '../../../models/modal-result-dialog-data';
import { SelectItem } from '../../../models/select-item';
import { DcHDurationPipe } from '../../../pipes/dch-duration.pipe';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';

@Component({
  selector: 'app-hoopers-results',
  templateUrl: './hoopers-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHKonkurrenceArtText, SelectCompetitionComponent, DcHDataTableComponent, DcHDurationPipe]
})
export class HoopersResultaterComponent implements OnInit {
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  competitionResponse: HoopersCompetitionResponse;
  klasseSelectItems: SelectItem<string>[];
  klasseSelectedValue: string;
  klasseResults: HoopersKlasseDataResults | undefined;
  dataTableDef = this.getDcHDataTable();
  private prevSelectedKonkId = 0;
  private hoopersKlasseHelpers = new DcHHoopersKlasseHelpers();

  @Input()
  importMode = false;

  @Input()
  competitionResponses: HoopersCompetitionResponse[];

  constructor(private readonly dialog: MatDialog, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {
    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.klasseResults = this.competitionResponse.klasseDataResults[0];
      this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.hoopersKlasseHelpers.getKlasseSelectItem(item.klasseKey));
      this.klasseSelectedValue = this.klasseSelectItems[0]?.value;
    }
  }

  selectionChangeHandler(): void {
    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasseKey === this.klasseSelectedValue);
    }
    else if (this.importMode) {
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.hoopersKlasseHelpers.getKlasseSelectItem(item.klasseKey));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      } else {
        this.klasseSelectItems = [];
        this.klasseSelectedValue = '';
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchHoopersCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.klasseResults = a.klasseDataResults[0];
        this.klasseSelectItems = a.klasseDataResults.map(item => this.hoopersKlasseHelpers.getKlasseSelectItem(item.klasseKey));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      });
    }
  }

  private openModal(data: HoopersResult): void {
    const modalResultDialogData: ModalResultDialogData = { ekvipage: data.dchEkvipage, eventId: this.competionDataSelectedValue.konkId, klasse: this.klasseSelectedValue, konkurrenceType: KonkurrenceType.HO, live: false };

    this.dialog.open(ModalResultComponent, {
      data: modalResultDialogData
    });
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.HO;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Tid', field: 'tid', textalign: 'center', dataType: 'duration', format: '1.2-2' },
      { headerText: 'Point i alt', field: 'pointIalt', textalign: 'center', dataType: 'number', format: '1.0-0', fnCellClicked: (a: HoopersResult) => { this.openModal(a) } }
    ]

    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: HoopersCompetitionResponse[]) {
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId
      }
    });
  }
}
