import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { DcHNordiskKlasseHelpers } from '../../../helpers/dch-nordisk-klasse-helpers';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { DcHKreds, DcHNordiskKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { NordiskDmCandidateResponse } from '../../../models.generated/nordisk';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { NordiskDmCandidateWrapper } from './nordisk-dm-candidate-wrapper';

@Component({
  selector: 'app-nordisk-dm-candidates',
  templateUrl: './nordisk-dm-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, AppMaterialModule, DcHDataTableComponent]
})
export class NordiskDmCandidatesComponent implements OnInit {
  nordiskDmCandidateResponse: NordiskDmCandidateResponse;
  nordiskDmCandidates: NordiskDmCandidateWrapper[] | undefined;
  regionSelectItems = new RegionHelpers().getRegionSelectItems(true);
  regionSelectedValue = this.regionSelectItems[0].value;
  klasseSelectItems: SelectItem<DcHNordiskKlasse>[];
  klasseSelectedValue: DcHNordiskKlasse;
  dataTableDef = this.getDcHDataTable();
  private nordiskKlasseHelpers = new DcHNordiskKlasseHelpers();
  private prevKlasseSelectedItem: DcHNordiskKlasse;

  constructor(private readonly httpDataService: HttpDataService) {
    this.klasseSelectItems = [DcHNordiskKlasse.NordiskSpor, DcHNordiskKlasse.NordiskRunder].map(a => this.nordiskKlasseHelpers.getKlasseSelectItem(a));
    this.klasseSelectedValue = this.klasseSelectItems[0].value;
    this.prevKlasseSelectedItem = this.klasseSelectedValue;
  }

  ngOnInit(): void {
    this.fetchData$(this.klasseSelectItems[0].value).subscribe(a => {
      this.nordiskDmCandidateResponse = a;
      this.nordiskDmCandidates = this.getFilteredData(this.nordiskDmCandidateResponse, this.regionSelectItems[0].value)
    });
  }

  selectionChangeHandler(): void {
    if (this.klasseSelectedValue === this.prevKlasseSelectedItem) {
      this.nordiskDmCandidates = this.getFilteredData(this.nordiskDmCandidateResponse, this.regionSelectedValue);
    }
    else {
      this.nordiskDmCandidates = undefined;
      this.prevKlasseSelectedItem = this.klasseSelectedValue;
      this.fetchData$(this.klasseSelectedValue).subscribe(a => {
        this.nordiskDmCandidateResponse = a;
        this.nordiskDmCandidates = this.getFilteredData(this.nordiskDmCandidateResponse, this.regionSelectedValue);
      });
    }
  }

  private fetchData$(klasse: DcHNordiskKlasse): Observable<NordiskDmCandidateResponse> {
    const url = `api/NordiskData/GetNordiskDmCandidates/${DcHNordiskKlasse[klasse]}`;
    return this.httpDataService.getData<NordiskDmCandidateResponse>(url)
  }

  private getFilteredData(nordiskDmCandidateResponse: NordiskDmCandidateResponse, dchKreds: DcHKreds): NordiskDmCandidateWrapper[] {
    let candidates = nordiskDmCandidateResponse.candidates;

    if (dchKreds !== DcHKreds.DcHLands) {
      candidates = nordiskDmCandidateResponse.candidates.filter(a => a.dchEkvipage?.dchKreds === dchKreds);
    }

    return candidates.map(a => (a as any) as NordiskDmCandidateWrapper);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.NO;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Point', field: 'dmResults[0].kvalPoint', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Point', field: 'dmResults[1].kvalPoint', textalign: 'center', dataType: 'number', format: '1.0-0', hideSmallScreen: true },
      { headerText: 'Sum', field: 'sumKvalPoint', textalign: 'center', bold: true, dataType: 'number', format: '1.0-0' },
      { headerText: 'Total', field: 'sumTotal', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' },
      { headerText: 'Udtaget', field: 'dchEkvipage.ekvipageNote' }
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.NO;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Point', field: 'kvalPoint', textalign: 'center', bold: true, dataType: 'number', format: '1.0-0' },
      { headerText: 'Total', field: 'total', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' }
    ]

    return dataTable;
  }
}
