import { DcHNordiskKlasseHelpers } from '../../../helpers/dch-nordisk-klasse-helpers';
import { DcHNordiskKlasse } from '../../../models.generated/dchEnums';
import { NordiskResult } from '../../../models.generated/nordisk';
import { SelectItem } from '../../../models/select-item';

export class NordiskLiveResultaterHelpers extends DcHNordiskKlasseHelpers {

  getKlasseSelectValue(items: SelectItem<DcHNordiskKlasse>[], currentItem: DcHNordiskKlasse): DcHNordiskKlasse {
    if (items.length === 0) {
      return DcHNordiskKlasse.DcHNordiskKlasseNone;
    }

    let myKlasse = items.find(a => a.value === currentItem)
    if (myKlasse == undefined) {
      return items[0].value;
    }
    else {
      return myKlasse.value;
    }
  }

  sortKlasseDataResults(results: NordiskResult[], sortOrder: 'placer' | 'startNo'): void {
    if (sortOrder === 'placer') {
      this.sortKlasseDataResultsByPlacer(results);
    } else {
      this.sortKlasseDataResultsByStartNo(results);
    }
  }

  private sortKlasseDataResultsByPlacer(results: NordiskResult[]): void {
    results.sort((a, b) => {
      if (a.placer === 250 && b.placer !== 250) {
        return 1;
      } else if (a.placer !== 250 && b.placer === 250) {
        return -1;
      } else if (a.placer < b.placer) {
        return -1;
      } else if (a.placer > b.placer) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private sortKlasseDataResultsByStartNo(results: NordiskResult[]): void {
    results.sort((a, b) => {
      if (a.startNo == undefined && b.startNo == undefined) {
        return 0;
      }

      if (a.startNo == undefined) {
        return -1;
      }

      if (b.startNo == undefined) {
        return 1;
      }

      if (a.startNo < b.startNo) {
        return -1;
      } else if (a.startNo > b.startNo) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
