import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHFileImportStatus, DcHFileModel, DcHFileModelResponse } from '../../../models.generated/misc';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { HttpDataService } from '../../../services/http-data.service';
import { MatSnackBarService } from '../../../services/mat-snack-bar.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';

@Component({
  selector: 'app-select-dchfile',
  templateUrl: './select-dchfile.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, DcHSelectComponent, DcHDataTableComponent]
})
export class SelectDcHFileComponent implements OnInit {
  selectedIndex = -1;
  yearSelectItems: SelectItem<number>[];
  yearSelectedValue: number;
  dcHFileImportStatus = DcHFileImportStatus;
  dchFiles: DcHFileModel[];
  dataTableDef = this.getDcHDataTable();
  _height: number;

  @Output()
  selectedChanged = new EventEmitter<DcHFileModel>();

  @Input()
  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = value > 100 ? value : 100;
  }

  constructor(readonly httpDataService: HttpDataService, private readonly matSnackBarService: MatSnackBarService, private readonly dialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.yearSelectItems = this.getYearSelectItems();
    this.yearSelectedValue = this.yearSelectItems[0].value;

    this.fetchData$(this.yearSelectedValue)
      .subscribe(a => this.dchFiles = a.dchFileModels);
  }


  selectedYearChangeHandler(): void {
    this.selectedChanged.emit(undefined);

    this.fetchData$(this.yearSelectedValue)
      .subscribe(a => this.dchFiles = a.dchFileModels);
  }

  selectedDcHFileChangeHandler(dchFile: DcHFileModel): void {
    this.selectedChanged.emit(dchFile);
  }

  downloadDcHFileClicked(dchFile: DcHFileModel): void {
    if (dchFile.competitionData == undefined) {
      console.error(dchFile);
      return;
    }

    const fileName = dchFile.fileName + '.dch';
    this.downloadFile$(dchFile.year, dchFile.competitionData.konkurrenceType, fileName).subscribe();
  }

  downloadJsonFileClicked(dchFile: DcHFileModel): void {
    if (dchFile.competitionData == undefined) {
      console.error(dchFile);
      return;
    }

    const fileName = dchFile.fileName + '.json';
    this.downloadFile$(dchFile.year, dchFile.competitionData.konkurrenceType, fileName).subscribe();
  }

  deleteClicked(dchFile: DcHFileModel): void {
    if (dchFile.competitionData == undefined) {
      console.error(dchFile);
      return;
    }

    this.dialogService.showDialog([dchFile.fileName, 'Slet fil'], 'Indlæs konkurrence', 'OkCancel')
      .subscribe(a => {
        if (a && dchFile.competitionData != undefined) {
          this.deleteFile$(dchFile.year, dchFile.competitionData.konkurrenceType, dchFile.fileName)
            .subscribe(() => {
              this.dchFiles = this.dchFiles.filter(a => a.fileName != dchFile.fileName);
              this.matSnackBarService.showOkMsg(dchFile.fileName + ' slettet');
            },
              () => this.matSnackBarService.showErrorMsg('Fejl i kommunikation med server')
            );
        }
      })
  }

  importFileClicked(dchFile: DcHFileModel): void {

    this.dialogService.showDialog([dchFile.fileName, 'Importer resultater'], 'Indlæs konkurrencer', 'OkCancel')
      .subscribe(a => {
        if (a) {
          this.matSnackBarService.showInfoMsg(dchFile.fileName + ' indlæser fil');

          this.postData$(dchFile)
            .subscribe((): void => {
              dchFile.dchFileImportStatus = DcHFileImportStatus.Imported;
              this.matSnackBarService.showOkMsg(dchFile.fileName + ' indlæst');
            },
              () => this.matSnackBarService.showErrorMsg('Fejl i kommunikation med server')
            );
        }
      });
  }

  private postData$(dchFile: DcHFileModel): Observable<number> {
    const url = 'api/DcHFile/ImportFile';
    return this.httpDataService.postData<DcHFileModel, number>(url, dchFile);
  }

  private fetchData$(year: number): Observable<DcHFileModelResponse> {
    const url = `api/DcHFile/GetDcHFiles/${year}`;
    return this.httpDataService.getData<DcHFileModelResponse>(url);
  }

  private deleteFile$(year: number, konkurrenceType: KonkurrenceType, fileName: string): Observable<boolean> {
    const url = `api/DcHFile/DeleteFiles/${year}/${konkurrenceType}/${fileName}`;
    return this.httpDataService.deleteData<boolean>(url);
  }

  private downloadFile$(year: number, konkurrenceType: KonkurrenceType, fileName: string): Observable<Blob> {

    const url = `api/DcHFile/DownloadFile/${year}/${konkurrenceType}/${fileName}`;

    return this.httpDataService.getBlob(url).pipe(
      tap(result => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(result);
        link.download = fileName;
        link.click();
      })
    );
  }

  private getYearSelectItems(): SelectItem<number>[] {
    const minYear = 2020;
    const maxYear = new Date().getFullYear();
    const yearArr = new Array<SelectItem<number>>();

    for (let i = maxYear; i >= minYear; i--) {
      yearArr.push(new SelectItem(i.toString(), i));
    }

    return yearArr;
  }

  private getDcHDataTable(): DcHDataTable {
    const dataTable = new DcHDataTable();
    dataTable.color = 'primary';
    dataTable.allowRowSelection = true;

    dataTable.columns = [
      { headerText: '', field: 'dchFileImportStatus', dataType: 'mat-icon', fnFormatter: (a: DcHFileImportStatus) => a === DcHFileImportStatus.Imported ? 'done' : '' },
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', dataType: 'timeStamp', format: 'dd.MMM' },
      { headerText: 'Type', field: 'competitionData.konkurrenceType', dataType: 'dchKonkurrenceType', hideSmallScreen: true },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Arrangør', field: 'competitionData.forening', hideSmallScreen: true },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn', hideSmallScreen: true, width: '30%', whiteSpace: 'normal' },
      { headerText: 'Filnavn', field: 'fileName' }
    ]

    return dataTable;
  }
}
