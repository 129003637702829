<div class="pageHeader">
  <h1>Oprykkere til B klassen</h1>
  <h4 *ngIf="lydighedOprykkereResponse$ | async as lydighedOprykkereResponse">
     Udtagelsesperiode: {{lydighedOprykkereResponse.startDate | dcHFullDate: lydighedOprykkereResponse.endDate}}
  </h4>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="oprykkere" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="dchTableResDetailsContainer">
    <app-dch-data-table [dataTable]="getDcHDataTableDetails()" [dataSource]="data?.results"></app-dch-data-table>
  </div>
</ng-template>
