import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { DcHRallyKlasseHelpers } from '../../../helpers/dch-rally-klasse-helpers';
import { DcHRallyKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { RallyCompetitionResponse, RallyKlasseDataResults } from '../../../models.generated/rally';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';

@Component({
  selector: 'app-rally-results',
  templateUrl: './rally-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHKonkurrenceArtText, SelectCompetitionComponent, DcHDataTableComponent]
})
export class RallyResultaterComponent implements OnInit {
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  competitionResponse: RallyCompetitionResponse;
  klasseResults: RallyKlasseDataResults | undefined;
  klasseSelectItems: SelectItem<DcHRallyKlasse>[];
  klasseSelectedValue: DcHRallyKlasse;
  dataTableDef = this.getDcHDataTable();
  private prevSelectedKonkId = 0;
  private rallyKlasseHelpers = new DcHRallyKlasseHelpers();
  
  @Input()
  importMode = false;

  @Input()
  competitionResponses: RallyCompetitionResponse[];

  constructor(private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {
    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.klasseResults = this.competitionResponse.klasseDataResults[0];
      this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.rallyKlasseHelpers.getKlasseSelectItem(item.klasse));
      this.klasseSelectedValue = this.klasseSelectItems[0].value;
    }
  }

  selectionChangeHandler(): void {
    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasse === this.klasseSelectedValue);
    }
    else if (this.importMode) {
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.rallyKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      } else {
        this.klasseSelectItems = [];
        this.klasseSelectedValue = DcHRallyKlasse.DcHRallyKlasseNone;
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchRallyCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.klasseResults = a.klasseDataResults[0];
        this.klasseSelectItems = a.klasseDataResults.map(item => this.rallyKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
      });
    }
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.RA;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Tid', field: 'tid', textalign: 'center', dataType: 'duration', format: 'mm:ss' },
      { headerText: 'Point', field: 'point', textalign: 'center' },
      { headerText: 'Bestået', field: 'bestaet', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: boolean) => a ? 'done' : '' }
    ];

    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: RallyCompetitionResponse[]) {
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId
      }
    });
  }
}
