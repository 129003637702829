import localeDa from '@angular/common/locales/da';
import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpDataService } from '../../services/http-data.service';
import { DcHCompetitionData } from '../../models.generated/shared';
import { KonkurrenceType } from '../../models.generated/dchEnums';
import { DcHCompetitionDataResponse } from '../../models.generated/misc';
import { DcHFullDatePipe } from '../../pipes/dch-full-date.pipe';
import { DcHKonkurrenceTypeText } from '../../pipes/dch-konkurrence-type-text.pipe';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../../app-material.module';
import { Observable } from 'rxjs';

registerLocaleData(localeDa);

@Component({
  selector: 'app-latest-competitions',
  templateUrl: './latest-competitions.component.html',
  styleUrls: ['./latest-competitions.component.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, DcHKonkurrenceTypeText, DcHFullDatePipe]
})
export class LatestCompetitionsComponent implements OnInit {
  dcHCompetitionDatas: DcHCompetitionData[];
  noCompetitionData = false;

  constructor(private readonly httpDataService: HttpDataService, private readonly router: Router) {
  }

  ngOnInit(): void {
    this.fetchData$()
      .subscribe(a => this.dcHCompetitionDatas = a.competitionDatas);
  }

  onClick(competitionData: DcHCompetitionData): void {
    const url = this.getUrl(competitionData.konkurrenceType);
    this.router.navigate([url, competitionData.konkId]);
  }

  private fetchData$(): Observable<DcHCompetitionDataResponse> {
    const url = 'api/KonkurrenceData/GetLatestCompetitionDatas';
    return this.httpDataService.getData<DcHCompetitionDataResponse>(url);
  }

  private getUrl(konkurrenceType: KonkurrenceType) {
    switch (konkurrenceType) {
      case KonkurrenceType.AG:
        return '/agilityResultater';
      case KonkurrenceType.BR:
        return '/brugsResultater';
      case KonkurrenceType.LY:
        return '/lydighedResultater';
      case KonkurrenceType.NO:
        return '/nordiskResultater';
      case KonkurrenceType.RA:
        return '/rallyResultater';
      case KonkurrenceType.NW:
        return '/noseWorkResultater';
      case KonkurrenceType.HO:
        return '/hoopersResultater';
    }

    throw new Error('Ugyldig konkurrenceType');
  }
}
