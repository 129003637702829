import { Location, CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBarService } from '../../services/mat-snack-bar.service';
import { DcHCompetitionData } from '../../models.generated/shared';
import { DcHFullDatePipe } from '../../pipes/dch-full-date.pipe';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '../../app-material.module';
import { combineLatest } from 'rxjs';
import { SelectItem } from '../../models/select-item';
import { CompetitionDataService } from '../../services/competition-data.service';
import { KonkurrenceType } from '../../models.generated/dchEnums';

@Component({
  selector: 'app-select-competition',
  templateUrl: './select-competition.component.html',
  standalone: true,
  imports: [CommonModule, AppMaterialModule, FormsModule, DcHFullDatePipe]
})
export class SelectCompetitionComponent implements OnInit, OnDestroy {

  private _selectItems: SelectItem<DcHCompetitionData>[];

  @Input()
  importMode: boolean;

  @Input()
  selectedValue: DcHCompetitionData | undefined;

  @Output()
  selectedValueChange = new EventEmitter<DcHCompetitionData>();

  @Input()
  konkurrenceType: string;

  @Input()
  baseUrl: string;

  @Input({ required: true })
  get selectItems(): SelectItem<DcHCompetitionData>[] {
    return this._selectItems;
  }

  set selectItems(value: SelectItem<DcHCompetitionData>[]) {
    this._selectItems = value;
    if (value?.length > 0) {
      this.selectedValue = value[0].value;
      //this.selectedValueChange.emit(this.selectedValue);
    }
  }

  constructor(private readonly location: Location, private readonly activatedRoute: ActivatedRoute, private readonly matSnackBarService: MatSnackBarService, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {
    if (this.importMode) {
      return;
    }

    var konkurrenceType: KonkurrenceType = KonkurrenceType[this.konkurrenceType as keyof typeof KonkurrenceType];

    if (konkurrenceType == undefined || konkurrenceType == KonkurrenceType.None) {
      console.error('KonkurrenceType missing');
      return;
    }

    if (this.selectItems?.length > 0) {
      return;
    }

    combineLatest([this.competitionDataService.fetchCompetitionDatasAsSelectItems$(konkurrenceType), this.activatedRoute.paramMap])
      .subscribe(response => {
        this.selectItems = response[0];
        const konkId = Number(response[1].get('konkId'));
        if (isNaN(konkId) || konkId === 0) {
          this.selectedValue = this.selectItems[0].value;
        } else {
          this.selectedValue = this.selectItems.find(a => a.value.konkId === konkId)?.value;
        }

        this.selectedValueChange.emit(this.selectedValue);
      });
  }

  ngOnDestroy(): void {
    this.matSnackBarService.dismissAll();
  }

  onSelectionChange(): void {
    this.matSnackBarService.dismissAll();
    this.selectedValueChange.emit(this.selectedValue);

    if (this.baseUrl?.length > 1 && !this.importMode) {
      const url = this.baseUrl + `/${this.selectedValue?.konkId}`;
      this.location.replaceState(url);
    }
  }
}
