<div *ngIf="dcHCompetitionDatas; else noCompetitionsYet" style="margin-top: 2px; margin-left: -10px; margin-right: -10px; margin-bottom: -12px; padding: 0;">
  <button *ngFor="let data of dcHCompetitionDatas" class="competitionCard" (click)="onClick(data)">
    <div style="font-weight: bold;">
      {{data.konkurrenceType | dcHKonkurrenceTypeText}}
    </div>
    <div>
      {{ data.forening }} d. {{ data.konkurrenceDato | dcHFullDate }}
    </div>
    <div>
      {{data.konkurrenceNavn}}
    </div>
  </button>
</div>

<ng-template #noCompetitionsYet>
  <div style="padding: 10px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</ng-template>
