import { formatDate } from '@angular/common';
import { Timestamp } from '../models.generated/google/protobuf/timestamp';

export class DcHDateHelpers {

  locale = 'da-DK';

  getFormattedDate(date: string | number | Date | Timestamp | undefined, format = 'd. MMM yyyy'): string {

    if (date == undefined) {
      return formatDate(new Date(1970, 1, 1, 1, 1, 1), format, this.locale);
    }

    const myDate = ((typeof date === 'string') || (typeof date == 'number') || (date instanceof Date)) ? date : new Date(date.seconds * 1000);
    return formatDate(myDate, format, this.locale);
  }

  getFormattedDates(date1: string | number | Date | Timestamp, date2: string | number | Date | Timestamp, format = 'd. MMM yyyy'): string {

    const myDate1 = ((typeof date1 === 'string') || (typeof date1 == 'number') || (date1 instanceof Date)) ? date1 : new Date(date1.seconds * 1000);
    const myDate2 = ((typeof date2 === 'string') || (typeof date2 == 'number') || (date2 instanceof Date)) ? date2 : new Date(date2.seconds * 1000);

    return this.getPeriod(myDate1, myDate2, format);
  }

  private getPeriod(date1: string | number | Date, date2: string | number | Date, format: string): string {

    const year1String = formatDate(date1, 'yyyy', this.locale);
    const year2String = formatDate(date2, 'yyyy', this.locale);

    let date1String = formatDate(date1, format, this.locale);
    const date2String = formatDate(date2, format, this.locale);

    if (year1String === year2String) {

      const month1String = formatDate(date1, 'MMM', this.locale);
      const month2String = formatDate(date2, 'MMM', this.locale);

      if (month1String === month2String) {
        return formatDate(date1, 'd', this.locale) + '-' + date2String;
      } else {
        date1String = formatDate(date1, 'd. MMM', this.locale);
      }
    }

    return date1String + ' - ' + date2String;
  }
}
