import { DcHBrugsKlasse } from "../models.generated/dchEnums";
import { SelectItem } from "../models/select-item";

export class DcHBrugsKlasseHelpers {

  getKlasseSelectItems(): SelectItem<DcHBrugsKlasse>[] {
    return [
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.BH], DcHBrugsKlasse.BH),
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.IGP1], DcHBrugsKlasse.IGP1),
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.IGP2], DcHBrugsKlasse.IGP2),
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.IGP3], DcHBrugsKlasse.IGP3),
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.IGPFH], DcHBrugsKlasse.IGPFH),
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.IFH1], DcHBrugsKlasse.IFH1),
      new SelectItem<DcHBrugsKlasse>(DcHBrugsKlasse[DcHBrugsKlasse.IFH2], DcHBrugsKlasse.IFH2)
    ];
  }

  getKlasseSelectItem(klasse: DcHBrugsKlasse): SelectItem<DcHBrugsKlasse> {
    return new SelectItem<DcHBrugsKlasse>(this.getKlasseText(klasse), klasse);
  }

  getKlasseText(klasse: string | DcHBrugsKlasse): string {
    let myKlasse = typeof klasse === "string" ? DcHBrugsKlasse[klasse as keyof typeof DcHBrugsKlasse] : klasse;

    switch (myKlasse) {
      case DcHBrugsKlasse.IFH1:
        return 'IFH 1';
      case DcHBrugsKlasse.IFH2:
        return 'IFH 2';
      case DcHBrugsKlasse.IGPFH:
        return 'IGP FH';
      case DcHBrugsKlasse.IGP1:
        return 'IGP 1';
      case DcHBrugsKlasse.IGP2:
        return 'IGP 2';
      case DcHBrugsKlasse.IGP3:
        return 'IGP 3';
      case DcHBrugsKlasse.IBGH1:
        return 'IBGH 1';
      case DcHBrugsKlasse.IBGH2:
        return 'IBGH 2';
      case DcHBrugsKlasse.IBGH3:
        return 'IBGH 3';

      default:
        return DcHBrugsKlasse[myKlasse];
    }
  }
}
