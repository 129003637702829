import { DcHCompetitionData } from '../models.generated/shared';
import { DcHDateHelpers } from './date.helpers';

export class DcHCompetitionDataHelpers {
  getCompetionText(competitionData: DcHCompetitionData | undefined): string {
    if (competitionData?.konkurrenceDato == undefined) {
      return 'competitionData undefined';
    }

    return new DcHDateHelpers().getFormattedDate(competitionData.konkurrenceDato, 'd. MMM yyyy') + ' ' + competitionData.forening;    
  }
}
