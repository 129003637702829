import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData } from '../../models/confirmation-dialog-data';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class ConfirmationDialogComponent {
  myData: ConfirmationDialogData
  lines: string[];

  constructor(readonly dialog: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) data: ConfirmationDialogData) {
    this.myData = data;

    if (Array.isArray(data.text)) {
      this.lines = data.text;
    }
    else {
      this.lines = [data.text];
    }
  }
}
