<div class="pageContent">
  <mat-card>
    <div class="hide-sm text-center" style="padding: 10px">
      <img mat-card-image src="images/logoround.jpg" width="300" height="300" alt="DcH logo" style="width: 100%; height: auto; max-height: 250px; max-width: 250px" />
    </div>
  </mat-card>

  <mat-card class="mt-15">
    <mat-card-header>
      <mat-card-title>DcH konkurrence resultater</mat-card-title>
    </mat-card-header>
    <mat-card-content style="max-height: 150px; overflow-y: auto">
      Her finder du resultater for Agility, Brugsprøver, DcH-Program, Hoopers, Nordisk, Nose Work og Rally konkurrencer afviklet i DcH regi, og du kan løbende se udtagelserne til DM.
      For at resultater gælder som udtagelse til DM, skal der være oprettet en profil på DcH klubmodul.
      Resultater fra en konkurrence skal indberettes fra lokal forening til konkurrenceudvalget via DcH beregnerprogrammet. Resultaterne skal herefter godkendes af konkurrenceudvalget inden de publiceres på denne hjemmeside.

      Konkurrence og DM udtagelses regler kan du finde på DcH Danmark
      <p>
        <a [routerLink]='["/beregnerprogrammer"]'>Download beregnerprogrammer</a>
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card class="mt-15">
    <mat-card-header>
      <mat-card-title>Seneste konkurrencer</mat-card-title>
    </mat-card-header>
    <mat-card-content style="max-height: 225px; overflow-y: auto">
      <app-latest-competitions></app-latest-competitions>
    </mat-card-content>
  </mat-card>

  <mat-card class="mt-15">
    <mat-card-header>
      <mat-card-title>Information fra LKU</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-lku-information>Vent..</app-lku-information>
    </mat-card-content>
  </mat-card>
</div>
