import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { KlasseTypeHelpers } from '../../../helpers/klasse-type-helpers';
import { DcHKlasseType } from '../../../models.generated/dchEnums';
import { DmQualificationPeriod, DmQualificationPeriodResponse } from '../../../models.generated/misc';
import { DcHDataTable } from '../../../models/dch-data-table';
import { HttpDataService } from '../../../services/http-data.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';

@Component({
  selector: 'app-admin-dch-calendar',
  templateUrl: './dch-calendar.component.html',
  styleUrls: ['./dch-calendar.component.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, ReactiveFormsModule, DcHDataTableComponent]
})
export class AdminDcHCalendarComponent implements OnInit {
  private calendarFormGroup: FormGroup;
  dmQualificationPeriods: DmQualificationPeriod[];
  dataTableDef = this.getDcHDataTable();
  startDateFormControl: FormControl<Date>;
  endDateFormControl: FormControl<Date>;
  disableSaveButton = true;
  prevId = -1;

  constructor(readonly httpDataService: HttpDataService, private formBuilder: FormBuilder, private readonly dialogService: ConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.fetchData$().subscribe(a => {
      this.dmQualificationPeriods = a.periods;
    });
  }

  onFormSubmit(data: DmQualificationPeriod): void {
    const msg = 'Gem ændringer';

    this.dialogService.showDialog(msg, 'DM udtagelse periode', 'OkCancel')
      .subscribe(a => {
        if (a) {
          data.startDate = { seconds: this.startDateFormControl.value.getTime() / 1000, nanos: 0 };
          data.endDate = { seconds: this.endDateFormControl.value.getTime() / 1000, nanos: 0 };
          this.postQualificationPeriod$(data).subscribe(() => this.disableSaveButton = true);
        }
      });
  }

  getFormGroup(data: DmQualificationPeriod): FormGroup {

    if (data.rowId === this.prevId) {
      return this.calendarFormGroup;
    }

    const startDate = data.startDate == undefined ? new Date(1970, 1, 1) : new Date(data.startDate.seconds * 1000);
    const endDate = data.endDate == undefined ? new Date(1970, 1, 1) : new Date(data.endDate.seconds * 1000);
    this.prevId = data.rowId;
    this.startDateFormControl = new FormControl<Date>(startDate, { nonNullable: true });
    this.endDateFormControl = new FormControl<Date>(endDate, { nonNullable: true });

    this.calendarFormGroup = this.formBuilder.group({
      startDate: this.startDateFormControl,
      endDate: this.endDateFormControl
    });

    this.disableSaveButton = true;
    this.calendarFormGroup.valueChanges.subscribe(() => { this.disableSaveButton = false });

    return this.calendarFormGroup;
  }

  private fetchData$(): Observable<DmQualificationPeriodResponse> {
    const url = `api/DmQualificationPeriod/GetDmQualificationPeriods`;
    return this.httpDataService.getData<DmQualificationPeriodResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    const klasseTypeHelpers = new KlasseTypeHelpers();
    const dataTable = new DcHDataTable();
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '', field: 'edit', dataType: 'rowCommand', width: '15px' },
      { headerText: 'Klasse', field: 'dcHKlasseType', fnFormatter: (a: DcHKlasseType) => klasseTypeHelpers.getKlasseTypeText(a) },
      { headerText: 'Start dato', field: 'startDate', dataType: 'timeStamp' },
      { headerText: 'Slut dato', field: 'endDate', dataType: 'timeStamp' }
    ]

    return dataTable;
  }

  private postQualificationPeriod$(data: DmQualificationPeriod): Observable<DmQualificationPeriod> {
    const url = 'api/DmQualificationPeriod/UpdateDmQualificationPeriods';
    return this.httpDataService.postData<DmQualificationPeriod, DmQualificationPeriod>(url, data);
  }
}
