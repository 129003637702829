import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { ConfirmationDialogComponent } from '../components.shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogData } from '../models/confirmation-dialog-data';


@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {

  constructor(public matDialog: MatDialog) { }

  showDialog(confirmationText: string | string[], title = '', dialogType: 'OkOnly' | 'OkCancel' = 'OkCancel'): Observable<boolean> {
    const dialogData = new ConfirmationDialogData();
    dialogData.title = title;
    dialogData.text = confirmationText;
    dialogData.dialogType = dialogType;

    return this.matDialog.open(ConfirmationDialogComponent, { data: dialogData })
      .afterClosed()
      .pipe(map(a => a === true));
  }
}
