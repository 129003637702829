import { Type } from "@angular/core";
import { DcHDetailsComponent } from "./dch-details.component";
import { KonkurrenceType } from "../models.generated/dchEnums";

export class DcHDataTable {
  constructor() {
    this.columns = new Array<DataColumn>();
  }

  color: 'primary' | 'secondary' = 'primary';
  cssClass?= '';
  stickyHeader = true;
  konkurrenceType: KonkurrenceType;
  noDataText = 'Inden data';
  columns: DataColumn[];
  height = 'auto';
  width = 'auto';
  allowRowSelection?= false;
  tableLayout?: 'fixed' | '' = '';
  autoCloseDetails?= false;
}

export class DataColumn {
  constructor() {
  }

  headerText: string;
  field: string;
  freeze?= false;
  width?= 'auto'
  dataType?: 'number' | 'timeStamp' | 'duration' | 'string' | 'ekvipageLink' | 'klubModulStatus' | 'dchPlacer' | 'dchKonkurrenceArt' | 'dchKonkurrenceType' | 'mat-icon' | 'rowCommand' = 'string';
  textalign?: 'left' | 'center' | 'right' = 'center';
  format?= '';
  whiteSpace?: 'nowrap' | 'normal' = 'nowrap';
  fnCellClicked?: (a: any) => void;
  fnFormatter?: (a: any) => string;
  fnDetailsClicked?: (a: any) => Type<DcHDetailsComponent>;
  fnRowCommandClicked?: (a: any) => void;
  fnHeaderFormatter?: (a: any) => string;
  headerToolTip?= '';
  toolTip?= '';
  cssClass?= '';
  bold?= false;
  hideSmallScreen?= false;
}
