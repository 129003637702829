import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { DcHHoopersKlasseHelpers } from '../../../helpers/dch-hoopers-klasse-helpers';
import { KredsOverViewPivotHelper, PivotRow } from '../../../helpers/kreds-overview-pivot-helpers';
import { RegionHelpers } from '../../../helpers/region.helpers';
import { DcHKreds, KonkurrenceType } from '../../../models.generated/dchEnums';
import { Timestamp } from '../../../models.generated/google/protobuf/timestamp';
import { HoopersKlassesResponse } from '../../../models.generated/hoopers';
import { DcHKredsOverViewResponse } from '../../../models.generated/shared';
import { SelectItem } from '../../../models/select-item';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';

@Component({
  selector: 'app-hoopers-kreds-overview',
  templateUrl: './hoopers-kreds-overview.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHFullDatePipe, DcHDataTableComponent]
})
export class HoopersKredsOverViewComponent implements OnInit {
  startDate: Timestamp | undefined;
  endDate: Timestamp | undefined;
  regionSelectItems = new RegionHelpers().getRegionSelectItems(false);
  regionSelectedValue = this.regionSelectItems[0].value;
  klasseSelectItems = new Array<SelectItem<string>>();
  klasseSelectedValue: string;
  pivotResults: PivotRow[];
  dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.HO);
  private prevRegionSelectedValue = this.regionSelectedValue;
  private hoopersKlasseHelpers = new DcHHoopersKlasseHelpers();

  constructor(private readonly httpDataService: HttpDataService, private readonly kredsOverViewPivotHelper: KredsOverViewPivotHelper) {
  }

  ngOnInit(): void {
    this.fetchData$(this.regionSelectedValue, '').subscribe(a => {
      this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a?.dcHKredsOverViews);
      this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.HO, this.pivotResults[0]);
      this.pivotResults.shift();
    });
  }

  selectionChangeHandler(): void {
    if (this.regionSelectedValue != this.prevRegionSelectedValue) {
      this.prevRegionSelectedValue = this.regionSelectedValue;
      this.fetchData$(this.regionSelectedValue, this.klasseSelectedValue).subscribe(a => {
        this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a?.dcHKredsOverViews);
        this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.HO, this.pivotResults[0]);
        this.pivotResults.shift();
      });
    }
    else {
      this.fetchResultsData$(this.regionSelectedValue, this.klasseSelectedValue).subscribe(a => {
        this.pivotResults = this.kredsOverViewPivotHelper.getPivot(a.dcHKredsOverViews);
        this.dataTableDef = this.kredsOverViewPivotHelper.getDcHDataTable(KonkurrenceType.HO, this.pivotResults[0]);
        this.pivotResults.shift();
      });
    }
  }

  private fetchData$(region: DcHKreds, klasse: string): Observable<DcHKredsOverViewResponse | undefined> {
    return this.fetchKlasseData$(this.regionSelectItems[0].value).pipe(
      mergeMap(a => {
        this.startDate = a.startDate;
        this.endDate = a.endDate;

        if (a.hoopersKlasses.length > 0) {
          this.klasseSelectItems = a.hoopersKlasses.map(b => this.hoopersKlasseHelpers.getKlasseSelectItem(b));
          this.klasseSelectedValue = this.hoopersKlasseHelpers.getKlasseSelectValue(this.klasseSelectItems, klasse);
          return this.fetchResultsData$(region, this.klasseSelectedValue);
        }

        return of(undefined);
      })
    );
  }

  private fetchResultsData$(region: DcHKreds, klasse: string): Observable<DcHKredsOverViewResponse> {
    const url = `api/HoopersData/getHoopersKredsOverView/${DcHKreds[region]}/${klasse}`;
    return this.httpDataService.getData<DcHKredsOverViewResponse>(url);
  }

  private fetchKlasseData$(region: DcHKreds): Observable<HoopersKlassesResponse> {
    const url = `api/HoopersData/GetDcHHoopersKlassesByKreds/${region}`;
    return this.httpDataService.getData<HoopersKlassesResponse>(url);
  }
}
