<div class="pageHeader">
  <h1 *ngIf="!importMode">DcH Hoopers konkurrence resultater</h1>

  <div class="flex-container wrap">
    <app-select-competition class="competitionSelector" baseUrl="/hoopersResultater" [importMode]="importMode" konkurrenceType="HO" [(selectedValue)]="competionDataSelectedValue" [selectItems]="competionDataSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-select-competition>
    <app-dch-select class="dchKlasseSelector" [(selectedValue)]="klasseSelectedValue" [selectItems]="klasseSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>

  <div *ngIf="competionDataSelectedValue != undefined && competionDataSelectedValue.konkId>0 && klasseResults != undefined">
    <h2>
      {{competionDataSelectedValue.konkurrenceNavn}} ({{competionDataSelectedValue.konkurrenceArt | dcHKonkurrenceArtText}})
    </h2>
    <h4>
      <span *ngIf="klasseResults.dommer">{{klasseResults.dommer}}</span> -
      StandardBaneTid/MBT: {{klasseResults.standardBaneTid | dcHDuration: '1.0-0'}}/{{klasseResults.maxBaneTid | dcHDuration: '1.0-0'}}s
    </h4>
  </div>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="klasseResults?.hoopersResult"></app-dch-data-table>
</div>
