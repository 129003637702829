import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { ModalResultComponent } from '../../../components.shared/modal-result/modal-result.component';
import { SelectCompetitionComponent } from '../../../components.shared/select-competition/select-competition.component';
import { DcHNordiskKlasseHelpers } from '../../../helpers/dch-nordisk-klasse-helpers';
import { DcHNordiskKlasse, KonkurrenceType, NordiskGodkendt } from '../../../models.generated/dchEnums';
import { NordiskCompetitionResponse, NordiskKlasseDataResults, NordiskResult } from '../../../models.generated/nordisk';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { ModalResultDialogData } from '../../../models/modal-result-dialog-data';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';

@Component({
  selector: 'app-nordisk-results',
  templateUrl: './nordisk-resultater.component.html',
  standalone: true,
  imports: [CommonModule, DcHSelectComponent, DcHKonkurrenceArtText, SelectCompetitionComponent, DcHDataTableComponent]
})
export class NordiskResultaterComponent implements OnInit {
  competionDataSelectItems: SelectItem<DcHCompetitionData>[];
  competionDataSelectedValue: DcHCompetitionData;
  competitionResponse: NordiskCompetitionResponse;
  klasseResults: NordiskKlasseDataResults | undefined;
  klasseSelectItems: SelectItem<DcHNordiskKlasse>[];
  klasseSelectedValue: DcHNordiskKlasse;
  godkendtNordisk = NordiskGodkendt;
  dataTableDef = this.getDcHDataTable();
  private nordiskKlasseHelpers = new DcHNordiskKlasseHelpers();
  private prevSelectedKonkId = 0;

  @Input()
  importMode = false;

  @Input()
  competitionResponses: NordiskCompetitionResponse[];

  constructor(private readonly dialog: MatDialog, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit(): void {

    if (this.importMode) {
      this.copyEventIdToKonkId(this.competitionResponses);
      this.competitionResponse = this.competitionResponses[0];
      this.competionDataSelectItems = this.competitionResponses.map(a => this.competitionDataService.getCompetitionDataSelectItem(a.competitionData));
      this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.klasseResults = this.competitionResponse.klasseDataResults[0];
      this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.nordiskKlasseHelpers.getKlasseSelectItem(item.klasse));
      this.klasseSelectedValue = this.klasseSelectItems[0].value;
      this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
    }
  }

  selectionChangeHandler(): void {

    if (this.prevSelectedKonkId === this.competionDataSelectedValue.konkId) {
      this.klasseResults = this.competitionResponse.klasseDataResults.find(a => a.klasse === this.klasseSelectedValue);
      this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
    }
    else if (this.importMode) {
      let newCompetitionResponse = this.competitionResponses.find(a => a.competitionData?.konkId == this.competionDataSelectedValue.konkId);
      if (newCompetitionResponse != undefined) {
        this.competitionResponse = newCompetitionResponse;
        this.klasseSelectItems = this.competitionResponse.klasseDataResults.map(item => this.nordiskKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
        this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
      } else {
        this.klasseSelectItems = [];
        this.klasseSelectedValue = DcHNordiskKlasse.DcHNordiskKlasseNone;
      }
    }
    else {
      this.prevSelectedKonkId = this.competionDataSelectedValue.konkId;
      this.competitionDataService.fetchNordiskCompetitionData$(this.competionDataSelectedValue.konkId).subscribe(a => {
        this.competitionResponse = a;
        this.klasseResults = a.klasseDataResults[0];
        this.klasseSelectItems = a.klasseDataResults.map(item => this.nordiskKlasseHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.klasseSelectItems[0].value;
        this.dataTableDef = this.getDcHDataTable(this.klasseSelectedValue);
      });
    }
  }

  private openModal(data: NordiskResult): void {
    const modalResultDialogData: ModalResultDialogData = { ekvipage: data.dchEkvipage, eventId: this.competionDataSelectedValue.konkId, klasse: DcHNordiskKlasse[this.klasseSelectedValue], konkurrenceType: KonkurrenceType.NO, live: false };

    this.dialog.open(ModalResultComponent, {
      data: modalResultDialogData
    });
  }

  private getDcHDataTable(klasse: DcHNordiskKlasse | undefined = undefined): DcHDataTable {
    let isLilleNordisk = klasse === DcHNordiskKlasse.LilleNordiskRunder || klasse === DcHNordiskKlasse.LilleNordiskSpor;
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.NO;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Point', field: 'total', textalign: 'center', dataType: 'number', format: '1.2-2', fnCellClicked: (a: NordiskResult) => { this.openModal(a) } }
    ];

    if (isLilleNordisk) {
      dataTable.columns.push({
        headerText: 'Oprykning', field: 'oprykning', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: boolean) => a ? 'done' : ''
      });
    }

    dataTable.columns.push({ headerText: 'Godkendt', field: 'godkendt', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: NordiskGodkendt) => a == NordiskGodkendt.G ? 'done' : '' });
    return dataTable;
  }

  private copyEventIdToKonkId(competitionResponses: NordiskCompetitionResponse[]) {
    competitionResponses.flatMap(a => a.competitionData).forEach(a => {
      if (a != undefined) {
        a.konkId = a.dchEventId
      }
    });
  }
}
