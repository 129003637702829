import { SelectItem } from "../models/select-item";

export class DcHHoopersKlasseHelpers {

  getKlasseSelectValue(items: SelectItem<string>[], currentItem: string): string {
    if (items.length === 0) {
      return '';
    }

    let myKlasse = items.find(a => a.value === currentItem)
    if (myKlasse == undefined) {
      return items[0].value;
    }
    else {
      return myKlasse.value;
    }
  }

  getKlasseSelectItem(klasseKey: string): SelectItem<string> {
    let displayText = this.klasseKeyToText(klasseKey, false);
    return new SelectItem(displayText, klasseKey);
  }

  klasseKeyToText(klasseKey: string, shortFormat: boolean): string {

    let klasse: string;

    if (shortFormat) {
      return DcHHoopersKlasseHelpers.klasseKeyToTextShortFormat(klasseKey);
    }
    if (klasseKey.includes('Beginner')) {
      klasse = 'Begynder';
    } else if (klasseKey.includes('Spring')) {
      klasse = 'Spring';
    } else if (klasseKey.includes('Practiced')) {
      klasse = 'Øvede';
    } else if (klasseKey.includes('Expert')) {
      klasse = 'Ekspert';
    } else if (klasseKey.includes('Champion')) {
      klasse = 'Champion';
    } else if (klasseKey.includes('Open')) {
      klasse = 'Åben';
    } else {
      return klasseKey;
    }

    if (klasseKey.includes('Run1')) {
      klasse += ' Løb 1';
    } else if (klasseKey.includes('Run2')) {
      klasse += ' Løb 2';
    }
    else if (klasseKey.includes('Run3')) {
      klasse += ' Løb 3';
    }
    else if (klasseKey.includes('Run4')) {
      klasse += ' Løb 4';
    }
    else if (klasseKey.includes('Run5')) {
      klasse += ' Løb 5';
    }
    else if (klasseKey.includes('Run6')) {
      klasse += ' Løb 6';
    } else {
      return klasseKey;
    }

    if (klasseKey.includes('Junior')) {
      klasse += ' - Junior';
    } else if (klasseKey.includes('Senior')) {
      klasse += ' - Senior';
    }

    if (klasseKey.includes('Small')) {
      klasse += ' - Lille';
    } else if (klasseKey.includes('Large')) {
      klasse += ' - Stor';
    } else {
      return klasseKey;
    }

    return klasse;
  }

  private static klasseKeyToTextShortFormat(klasseKey: string): string {
    return klasseKey;
  }
}
