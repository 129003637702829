import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class MatSnackBarService {

  okSnackBarRef: MatSnackBarRef<TextOnlySnackBar>;
  infoSnackBarRef: MatSnackBarRef<TextOnlySnackBar>;
  errorSnackBarRef: MatSnackBarRef<TextOnlySnackBar>;

  constructor(private readonly snackBar: MatSnackBar) {
  }

  dismissAll(): void {
    this.okSnackBarRef?.dismiss();
    this.infoSnackBarRef?.dismiss();
    this.errorSnackBarRef?.dismiss();
  }

  showErrorMsg(errorMsg: string, duration = 5000): void {
    this.errorSnackBarRef = this.snackBar.open(errorMsg, 'Luk', this.getErrorMatSnackBarConfig(duration));
  }

  showInfoMsg(infoMsg: string, duration = 2000): void {
    if (duration > 9999) {
      this.infoSnackBarRef = this.snackBar.open(infoMsg, 'Luk', this.getInfoMatSnackBarConfig(duration));
    }
    else {
      this.infoSnackBarRef = this.snackBar.open(infoMsg, '', this.getInfoMatSnackBarConfig(duration));
    }
  }

  showOkMsg(okMsg: string, duration = 2000): void {
    this.okSnackBarRef = this.snackBar.open(okMsg, '', this.getOkMatSnackBarConfig(duration));
  }

  private getOkMatSnackBarConfig(duration: number): MatSnackBarConfig<any> {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.panelClass = 'successSnackBar';
    return config;
  }

  private getErrorMatSnackBarConfig(duration: number): MatSnackBarConfig<any> {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.panelClass = 'errorSnackBar';
    return config;
  }

  private getInfoMatSnackBarConfig(duration: number): MatSnackBarConfig<any> {
    const config = new MatSnackBarConfig();
    config.duration = duration;
    config.panelClass = '';
    return config;
  }
}
