import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHDateHelpers } from '../../../helpers/date.helpers';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { LydighedETurResponse, LydighedEturEkvipageWithResults, LydighedEturResult } from '../../../models.generated/lydighed';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DataColumn, DcHDataTable } from '../../../models/dch-data-table';
import { HttpDataService } from '../../../services/http-data.service';

@Component({
  selector: 'app-etur-results',
  templateUrl: './lydighed-etur-results.component.html',
  styleUrls: ['./lydighed-etur-results.component.css'],
  standalone: true,
  imports: [CommonModule, DcHDataTableComponent]
})
export class LydighedETurResultsComponent implements OnInit {
  eturResults: LydighedEturEkvipageWithResults[];
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  ngOnInit(): void {
    this.fetchData$().subscribe(a => {
      this.eturResults = a.ekvipagesWithResults;
      let competitionDatas = a.ekvipagesWithResults[0].eturResults.map(a => a.competitionData as DcHCompetitionData);
      this.dataTableDef = this.getDcHDataTable(competitionDatas);
    });
  }

  private fetchData$(): Observable<LydighedETurResponse> {
    const url = 'api/LydighedData/GetETurResults';
    return this.httpDataService.getData<LydighedETurResponse>(url);
  }

  private getDcHDataTable(competitionDatas?: DcHCompetitionData[] | undefined): DcHDataTable {
    const emptyColumn: DataColumn = { headerText: '-' + '\n' + '-', field: '', textalign: 'center' };
    let dateHelpers = new DcHDateHelpers();
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' },
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true }
    ]

    if (competitionDatas != undefined) {
      for (let i = 0; i < competitionDatas.length; i++) {
        if (competitionDatas[i].konkId > 0) {
          let headerText = competitionDatas[i].forening + '\n' + dateHelpers.getFormattedDate(competitionDatas[i].konkurrenceDato, 'd.MMM');
          let column: DataColumn = { headerText: headerText, field: `eturResults[${i}].point`, textalign: 'center', dataType: 'number', format: '1.0-0' };
          dataTable.columns.push(column);
        }
        else {
          dataTable.columns.push(emptyColumn);
        }
      }
    }

    dataTable.columns.push({ headerText: '4xPoint', field: 'sum4Point', textalign: 'center', dataType: 'number', format: '1.0-0' });
    dataTable.columns.push({ headerText: 'Point', field: 'sumPoint', bold: true, textalign: 'center', dataType: 'number', format: '1.0-0' });
    return dataTable;
  }

  getDcHDataTableDetails(results: LydighedEturResult[] | undefined = undefined): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.width = "80%";
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', dataType: 'timeStamp', textalign: 'center' },
      { headerText: 'Arrangør', field: 'competitionData.forening', dataType: 'string' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Point', field: 'point', textalign: 'center', bold: true, dataType: 'number', format: '1.0-0' },
      { headerText: 'Total', field: 'total', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' },
    ]

    return dataTable;
  }
}
