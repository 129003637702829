import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DcHCompetitionData } from '../models.generated/shared';
import { HttpDataService } from './http-data.service';
import { KonkurrenceType } from '../models.generated/dchEnums';
import { DcHCompetitionDataResponse, DcHLiveDataToken, DcHLiveDataTokenResponse } from '../models.generated/misc';
import { RallyCompetitionResponse } from '../models.generated/rally';
import { AgilityCompetitionResponse } from '../models.generated/agility';
import { BrugsCompetitionResponse } from '../models.generated/brugs';
import { HoopersCompetitionResponse } from '../models.generated/hoopers';
import { LydighedCompetitionResponse } from '../models.generated/lydighed';
import { NordiskCompetitionResponse } from '../models.generated/nordisk';
import { NoseWorkCompetitionResponse } from '../models.generated/nosework';
import { SelectItem } from '../models/select-item';
import { DcHCompetitionDataHelpers } from '../helpers/dch-competition-data.helpers';

@Injectable({ providedIn: 'root' })
export class CompetitionDataService {

  private competitionDataHelpers = new DcHCompetitionDataHelpers();

  constructor(private readonly httpDataService: HttpDataService) {
  }

  updateLiveDataToken(dcHLiveDataToken: DcHLiveDataToken): Observable<DcHLiveDataToken> {
    const url = 'api/LiveData/UpdateLiveDataToken';
    return this.httpDataService.postData<DcHLiveDataToken, DcHLiveDataToken>(url, dcHLiveDataToken)
  }

  newLiveDataToken(dchEventId: number): Observable<DcHLiveDataToken> {
    const url = `api/LiveData/NewLiveDataToken?eventId=${dchEventId}`;
    return this.httpDataService.postData<number, DcHLiveDataToken>(url, dchEventId)
  }

  getDcHLiveDataTokens(): Observable<DcHLiveDataToken[]> {
    const url = 'api/LiveData/GetDcHLiveDataTokens';
    return this.httpDataService.getData<DcHLiveDataTokenResponse>(url).pipe(map(a => a.dchLiveDataTokens));
  }

  fetchLiveCompetitionDatasAsSelectItems$(konkurrenceType: KonkurrenceType): Observable<SelectItem<DcHCompetitionData>[]> {
    return this.fetchLiveCompetitionDatas$(konkurrenceType)
      .pipe(
        map(a => a.map(b => this.getCompetitionDataSelectItem(b))
        ));
  }

  fetchCompetitionDatasAsSelectItems$(konkurrenceType: KonkurrenceType): Observable<SelectItem<DcHCompetitionData>[]> {
    return this.fetchCompetitionDatas$(konkurrenceType)
      .pipe(
        map(a => a.map( b=> this.getCompetitionDataSelectItem(b))
      ));
  }

  private fetchLiveCompetitionDatas$(konkurrenceType: KonkurrenceType): Observable<DcHCompetitionData[]> {
    const url = `api/LiveResults/GetLiveCompetitions/${KonkurrenceType[konkurrenceType]}`;
    return this.httpDataService.getData<DcHCompetitionDataResponse>(url)
      .pipe(
        map(a => a.competitionDatas)
      );
  }

  private fetchCompetitionDatas$(konkurrenceType: KonkurrenceType): Observable<DcHCompetitionData[]> {
    const url = `api/KonkurrenceData/GetCompetitionDatasByKonkurrenceType/${KonkurrenceType[konkurrenceType]}`;
    return this.httpDataService.getData<DcHCompetitionDataResponse>(url)
      .pipe(
        map(a => a.competitionDatas)
      );
  }

  fetchAgilityCompetitionData$(konkId: number): Observable<AgilityCompetitionResponse> {
    const url = `api/AgilityData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<AgilityCompetitionResponse>(url);
  }

  fetchBrugsCompetitionData$(konkId: number): Observable<BrugsCompetitionResponse> {
    const url = `api/BrugsData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<BrugsCompetitionResponse>(url);
  }

  fetchHoopersCompetitionData$(konkId: number): Observable<HoopersCompetitionResponse> {
    const url = `api/HoopersData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<HoopersCompetitionResponse>(url);
  }

  fetchLydighedCompetitionData$(konkId: number): Observable<LydighedCompetitionResponse> {
    const url = `api/LydighedData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<LydighedCompetitionResponse>(url);
  }

  fetchNordiskCompetitionData$(konkId: number): Observable<NordiskCompetitionResponse> {
    const url = `api/NordiskData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<NordiskCompetitionResponse>(url);
  }

  fetchNoseWorkCompetitionData$(konkId: number): Observable<NoseWorkCompetitionResponse> {
    const url = `api/NoseWorkData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<NoseWorkCompetitionResponse>(url);
  }

  fetchRallyCompetitionData$(konkId: number): Observable<RallyCompetitionResponse> {
    const url = `api/RallyData/GetCompetitionResults/${konkId}`;
    return this.httpDataService.getData<RallyCompetitionResponse>(url);
  }

  getCompetitionDataSelectItem(item: DcHCompetitionData | undefined): SelectItem<DcHCompetitionData> {
    if (item == undefined) {
      throw new Error('item is undefined');
    }

    return new SelectItem<DcHCompetitionData>(this.competitionDataHelpers.getCompetionText(item), item);
  }
}
