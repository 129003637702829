import { DcHNordiskKlasse } from '../models.generated/dchEnums';
import { SelectItem } from '../models/select-item';

export class DcHNordiskKlasseHelpers {

  getKlasseSelectItems(): SelectItem<DcHNordiskKlasse>[] {
    return [
      new SelectItem<DcHNordiskKlasse>(this.getDcHNordiskKlasseText(DcHNordiskKlasse.LilleNordiskRunder), DcHNordiskKlasse.LilleNordiskRunder),
      new SelectItem<DcHNordiskKlasse>(this.getDcHNordiskKlasseText(DcHNordiskKlasse.LilleNordiskSpor), DcHNordiskKlasse.LilleNordiskSpor),
      new SelectItem<DcHNordiskKlasse>(this.getDcHNordiskKlasseText(DcHNordiskKlasse.NordiskRunder), DcHNordiskKlasse.NordiskRunder),
      new SelectItem<DcHNordiskKlasse>(this.getDcHNordiskKlasseText(DcHNordiskKlasse.NordiskSpor), DcHNordiskKlasse.NordiskSpor),
      new SelectItem<DcHNordiskKlasse>(this.getDcHNordiskKlasseText(DcHNordiskKlasse.NordiskSkydd), DcHNordiskKlasse.NordiskSkydd)
    ];
  }

  getKlasseSelectItem(klasse: DcHNordiskKlasse): SelectItem<DcHNordiskKlasse> {
    return new SelectItem<DcHNordiskKlasse>(this.getDcHNordiskKlasseText(klasse), klasse);
  }

  stringToNordiskKlasse(klasse: string): DcHNordiskKlasse {
    return DcHNordiskKlasse[klasse as keyof typeof DcHNordiskKlasse];
  }

  getDcHNordiskKlasseText(nordiskKlasse: DcHNordiskKlasse | string): string {

    let myKlasse = typeof nordiskKlasse === "string" ? DcHNordiskKlasse[nordiskKlasse as keyof typeof DcHNordiskKlasse] : nordiskKlasse;

    switch (myKlasse) {
      case DcHNordiskKlasse.LilleNordiskRunder:
        return 'Lille nordisk runder';
      case DcHNordiskKlasse.LilleNordiskSpor:
        return 'Lille nordisk spor';
      case DcHNordiskKlasse.NordiskRunder:
        return 'Nordisk runder';
      case DcHNordiskKlasse.NordiskSpor:
        return 'Nordisk spor';
      case DcHNordiskKlasse.NordiskSkydd:
        return 'Nordisk skydd';
      default:
        return DcHNordiskKlasse.toString();
    }
  }
}
