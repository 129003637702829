<div [ngSwitch]="hubConnectionState">
  <div *ngSwitchCase="undefined"></div>
  <div *ngSwitchCase="HubConnectionState.Connected"></div>
  <div *ngSwitchCase="HubConnectionState.Disconnected" class="httpErrorInfo">
    Server off-line
  </div>
  <div *ngSwitchDefault class="statusMessage">
    {{hubConnectionState}}...
  </div>
</div>
