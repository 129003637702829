import { DcHRallyKlasse, Severity } from "../models.generated/dchEnums";
import { SelectItem } from "../models/select-item";

export class DchSeverityHelpers {

  getSelectItem(severity: Severity): SelectItem<Severity> {
    return new SelectItem<Severity>(this.getSeverityText(severity), severity);
  }


  getSelectItems(): SelectItem<Severity>[] {

    return [
      new SelectItem<Severity>(this.getSeverityText(Severity.SeverityMinor), Severity.SeverityMinor),
      new SelectItem<Severity>(this.getSeverityText(Severity.SeverityMajor), Severity.SeverityMajor)
    ];
  }

  stringToSeverity(klasse: string): Severity {
    return Severity[klasse as keyof typeof Severity];
  }

  getSeverityText(severity: string | Severity): string {

    let mySeverity = typeof severity === "string" ? Severity[severity as keyof typeof Severity] : severity;

    switch (mySeverity) {
      case Severity.SeverityMinor:
        return 'Minor';
      case Severity.SeverityMajor:
        return 'Major';
      default:
        return 'ukendt: ' + mySeverity?.toString();
    }
  }
}
