import { CommonModule } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { KlasseTypeHelpers } from '../../../helpers/klasse-type-helpers';
import { DcHKlasseType, KonkurrenceType, Severity } from '../../../models.generated/dchEnums';
import { BpMessage, DmQualificationPeriod, DmQualificationPeriodResponse, ImportantInformationResponse } from '../../../models.generated/misc';
import { DcHDataTable } from '../../../models/dch-data-table';
import { HttpDataService } from '../../../services/http-data.service';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { DchSeverityHelpers } from '../../../helpers/dch-severity-helpers';
import { KonkurrenceTypeHelpers } from '../../../helpers/konkurrenceTypeHelpers';
import { MatSnackBarService } from '../../../services/mat-snack-bar.service';

@Component({
  selector: 'app-breaking-news',
  templateUrl: './breaking-news.component.html',
  styleUrls: ['./breaking-news.component.css'],
  standalone: true,
  imports: [CommonModule, AppMaterialModule, ReactiveFormsModule, DcHDataTableComponent]
})
export class BreakingNewsComponent implements OnInit {
  private bpMessageFormGroup: FormGroup;
  bpMessages: BpMessage[];
  dataTableDef = this.getDcHDataTable();
  konkurrenceTypeFormControl: FormControl<KonkurrenceType>;
  versionFormControl: FormControl<string | undefined>;
  severityFormControl: FormControl<Severity>;
  headerFormControl: FormControl<string | undefined>;
  messageFormControl: FormControl<string | undefined>;
  htmlContentFormControl: FormControl<string | undefined>;
  disableSaveButton = true;
  prevId = -1;

  konkurrenceTypeSelectItems = new KonkurrenceTypeHelpers().getKonkurrenceTypeSelectItems();
  severitySelectItems = new DchSeverityHelpers().getSelectItems();

  @ViewChild('datatable') dataTable: DcHDataTableComponent;

  constructor(readonly httpDataService: HttpDataService, private formBuilder: FormBuilder, private readonly dialogService: ConfirmationDialogService, private readonly matSnackBarService: MatSnackBarService) {
  }

  ngOnInit(): void {
    this.fetchData$().subscribe(a => {
      this.bpMessages = a.bpMessages;
    });
  }

  onFormSubmit(data: BpMessage): void {
    let msg = 'Gem ændringer';

    this.dialogService.showDialog(msg, 'Breaking news', 'OkCancel')
      .subscribe(a => {
        if (a) {
          data.konkurrenceType = this.konkurrenceTypeFormControl.value;
          data.version = this.versionFormControl.value;
          data.severity = this.severityFormControl.value;
          data.header = this.headerFormControl.value;
          data.message = this.messageFormControl.value;
          data.htmlContent = this.htmlContentFormControl.value;
          this.postQualificationPeriod$(data).subscribe(() => this.disableSaveButton = true);
          this.dataTable.collapseAll();
        }
      });
  }

  createRowHandler(): void {
    let newRowData: BpMessage = { konkurrenceType: KonkurrenceType.AG, version: '1.0.0', severity: Severity.SeverityMinor, message: '<message>', header: '<header>' } as BpMessage;

    if (this.dataTable?.dataSource != undefined) {
      this.dataTable.dataSource.push(newRowData);
      this.dataTable.openEditRowData(this.dataTable.dataSource.length-1);
    }
  }

  getFormGroup(data: BpMessage): FormGroup {

    if (data.rowId === this.prevId) {
      return this.bpMessageFormGroup;
    }

    this.prevId = data.rowId;
    this.konkurrenceTypeFormControl = new FormControl<KonkurrenceType>(data.konkurrenceType, { nonNullable: true });
    this.versionFormControl = new FormControl<string | undefined>(data.version, { nonNullable: true });
    this.severityFormControl = new FormControl<Severity>(data.severity, { nonNullable: true });
    this.headerFormControl = new FormControl<string | undefined>(data.header, { nonNullable: true });
    this.messageFormControl = new FormControl<string | undefined>(data.message, { nonNullable: true });
    this.htmlContentFormControl = new FormControl<string | undefined>(data.htmlContent, { nonNullable: true });

    this.bpMessageFormGroup = this.formBuilder.group({
      version: this.versionFormControl,
      konkurrenceType: this.konkurrenceTypeFormControl,
      severity: this.severityFormControl,
      header: this.headerFormControl,
      message: this.messageFormControl,
      htmlContent: this.htmlContentFormControl
    });

    this.disableSaveButton = true;
    this.bpMessageFormGroup.valueChanges.subscribe(() => { this.disableSaveButton = false });

    return this.bpMessageFormGroup;
  }

  editClicked(bpMessage: BpMessage): void {
    let index = this.dataTable.dataSource?.indexOf(bpMessage);

    if (index == undefined || index == -1) {
      return;
    }

    this.dataTable.openEditRowData(index);
  }

  deleteClicked(bpMessage: BpMessage): void {

    if (bpMessage == undefined) {
      console.error(bpMessage);
      return;
    }

    this.dialogService.showDialog(['Slet'], 'Breaking news', 'OkCancel')
      .subscribe(a => {
        if (a) {
          this.deleteData$(bpMessage)
            .subscribe(() => {
              this.bpMessages = this.bpMessages.filter(a => a.rowId != bpMessage.rowId);
              this.matSnackBarService.showOkMsg('slettet');
            },
              () => this.matSnackBarService.showErrorMsg('Fejl i kommunikation med server')
            );
        }
      })
  }

  private deleteData$(bpMessage: BpMessage): Observable<number> {
    const url = `api/BreakingNews/DeleteBrekingNews?rowId=${bpMessage.rowId}`;
    return this.httpDataService.deleteData<number>(url);
  }

  private fetchData$(): Observable<ImportantInformationResponse> {
    const url = `api/BreakingNews/GetImportantInformation`;
    return this.httpDataService.getData<ImportantInformationResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    var dchSeverityHelpers = new DchSeverityHelpers();
    const dataTable = new DcHDataTable();
    dataTable.color = 'primary';

    dataTable.columns = [
      { headerText: 'Type', field: 'konkurrenceType', dataType: 'dchKonkurrenceType' },
      { headerText: 'Version', field: 'version' },
      { headerText: 'Severity', field: 'severity', fnFormatter: (a: Severity) => dchSeverityHelpers.getSeverityText(a) },
      { headerText: 'Header', field: 'header' },
      { headerText: 'Message', field: 'message' },
      { headerText: 'HtmlContent', field: 'htmlContent' },
    ]

    return dataTable;
  }

  private postQualificationPeriod$(data: BpMessage): Observable<BpMessage> {
    const url = 'api/BreakingNews/UpdateBreakingNews';
    return this.httpDataService.postData<BpMessage, BpMessage>(url, data);
  }
}
