import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { DcHSelectComponent } from '../../../components.shared/dch-select/dch-select.component';
import { HubConnectionStatusComponent } from '../../../components.shared/hub-connection-status/hub-connection-status.component';
import { LiveMessageComponent } from '../../../components.shared/live-message/live-message.component';
import { ModalResultComponent } from '../../../components.shared/modal-result/modal-result.component';
import { BrugsKlasseDataResults, BrugsLiveDataResponse, BrugsResult } from '../../../models.generated/brugs';
import { DcHBrugsKlasse, KonkurrenceType } from '../../../models.generated/dchEnums';
import { DcHCompetitionData } from '../../../models.generated/shared';
import { DcHDataTable } from '../../../models/dch-data-table';
import { ModalResultDialogData } from '../../../models/modal-result-dialog-data';
import { SelectItem } from '../../../models/select-item';
import { DcHKonkurrenceArtText } from '../../../pipes/dch-konkurrence-art-text.pipe';
import { CompetitionDataService } from '../../../services/competition-data.service';
import { WebSocketService } from '../../../services/web-socket-service';
import { BrugsLiveResultaterHelpers } from './brugs-live-resultater.helpers';

@Component({
  selector: 'app-brugs-live-results',
  templateUrl: './brugs-live-resultater.component.html',
  standalone: true,
  imports: [CommonModule, MatRadioModule, DcHKonkurrenceArtText, LiveMessageComponent, DcHSelectComponent, HubConnectionStatusComponent, DcHDataTableComponent]
})
export class BrugsLiveResultaterComponent implements OnInit, OnDestroy {
  liveDataResponse: BrugsLiveDataResponse | undefined;
  competionDataSelectItems = new Array<SelectItem<DcHCompetitionData>>();
  competionDataSelectedValue: DcHCompetitionData;
  klasseSelectItems: SelectItem<DcHBrugsKlasse>[];
  klasseSelectedValue = DcHBrugsKlasse.DcHBrugsKlasseNone;
  klasseResults: BrugsKlasseDataResults | undefined;
  sortField: 'placer' | 'startNo' = 'placer';
  dataTableDef = this.getDcHDataTable(this.sortField);
  private prevSelectedEventId = 0;
  private liveResultaterHelpers = new BrugsLiveResultaterHelpers();

  constructor(private readonly location: Location, private readonly webSocketService: WebSocketService, private readonly dialog: MatDialog, private readonly competitionDataService: CompetitionDataService) {
  }

  ngOnInit() {

    this.webSocketService.connectToHub$().subscribe();

    this.competitionDataService.fetchLiveCompetitionDatasAsSelectItems$(KonkurrenceType.BR)
      .pipe(
        mergeMap(selectItems => {
          console.log(selectItems);
          this.competionDataSelectItems = selectItems;

          if (selectItems.length > 0) {
            this.competionDataSelectedValue = this.competionDataSelectItems[0].value;
            this.prevSelectedEventId = this.competionDataSelectedValue.dchEventId;
            return this.webSocketService.initLiveResultater$(KonkurrenceType.BR, this.competionDataSelectedValue.dchEventId);
          }
          else {
            this.klasseResults = this.getEmptyKlasseResults();
            return of();
          }
        })
      ).subscribe();

    this.webSocketService.brugsLiveDataUpdated$
      .subscribe(liveDataReply => {
        console.log('BrugsLiveDataUpdated: ' + liveDataReply.liveDataResponse?.dchEventId);

        this.liveDataResponse = liveDataReply;
        this.klasseSelectItems = liveDataReply.klasseDataResults.map(item => this.liveResultaterHelpers.getKlasseSelectItem(item.klasse));
        this.klasseSelectedValue = this.liveResultaterHelpers.getKlasseSelectValue(this.klasseSelectItems, this.klasseSelectedValue);
        this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
        this.dataTableDef = this.getDcHDataTable(this.sortField, this.klasseSelectedValue);
      });
  }

  async ngOnDestroy() {
    this.webSocketService.unsubscribeLiveResultater$().subscribe();
  }

  selectionChangeHandler(): void {
    let currentEventId = this.competionDataSelectedValue.dchEventId;

    if (this.prevSelectedEventId === currentEventId) {
      this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
      this.dataTableDef = this.getDcHDataTable(this.sortField, this.klasseSelectedValue);
    } else {
      this.liveDataResponse = undefined;
      this.klasseResults = this.getEmptyKlasseResults();
      this.webSocketService.changeLiveResultaterGroup$(currentEventId).subscribe();
      this.prevSelectedEventId = currentEventId;
    }

    this.updateUrl(currentEventId);
  }

  private getEmptyKlasseResults(): BrugsKlasseDataResults {
    let a = {} as BrugsKlasseDataResults;
    a.brugsResults = [];
    return a;
  }

  private openModal(data: BrugsResult): void {
    const modalResultDialogData: ModalResultDialogData = { ekvipage: data.dchEkvipage, eventId: this.competionDataSelectedValue.dchEventId, klasse: DcHBrugsKlasse[this.klasseSelectedValue], konkurrenceType: KonkurrenceType.BR, live: true };

    this.dialog.open(ModalResultComponent, {
      data: modalResultDialogData
    });
  }

  onClickMe(sortField: 'placer' | 'startNo' = 'placer'): void {
    this.sortField = sortField;
    this.klasseResults = this.getFilteredData(this.klasseSelectedValue, this.sortField);
    this.dataTableDef = this.getDcHDataTable(this.sortField, this.klasseSelectedValue);
  }

  private updateUrl(konkId: number): void {
    const url = `/brugsLiveResultater/${konkId}`;
    this.location.replaceState(url);
  }

  private getFilteredData(klasse: DcHBrugsKlasse, sortOrder: 'placer' | 'startNo'): BrugsKlasseDataResults | undefined {

    if (this.liveDataResponse == undefined || klasse == undefined) {
      return undefined;
    }

    const klasseDataResults = this.liveDataResponse.klasseDataResults.find(a => a.klasse === klasse);

    if (klasseDataResults?.brugsResults != undefined) {
      this.liveResultaterHelpers.sortKlasseDataResults(klasseDataResults.brugsResults, sortOrder);
    }

    return klasseDataResults;
  }

  private getDcHDataTable(columnChooser: 'placer' | 'startNo', klasse: DcHBrugsKlasse | undefined = undefined): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.BR;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen resultater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
    ];

    if (klasse == undefined) {
      return dataTable;
    }

    let isBestaetKlasse = klasse === DcHBrugsKlasse.BH || klasse === DcHBrugsKlasse.AD;

    if (isBestaetKlasse) {
      dataTable.columns.push(
        { headerText: 'Bestået', field: 'total', textalign: 'center', dataType: 'mat-icon', fnFormatter: (a: number) => a === 1 ? 'done' : '' }
      );
    }
    else {
      dataTable.columns.unshift({ headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' });
      dataTable.columns.push({ headerText: 'Total', field: 'total', textalign: 'center', dataType: 'number', format: '1.2-2', fnCellClicked: (a: BrugsResult) => { this.openModal(a) } });
    }

    if (columnChooser === 'startNo') {
      dataTable.columns.unshift({ headerText: '#', field: 'startNo', dataType: 'number', textalign: 'center' });
    } else {
      dataTable.columns.unshift({ headerText: '#', field: 'placer', dataType: 'dchPlacer', textalign: 'center' });
    }

    return dataTable;
  }
}
