<div class="pageHeader">
  <h1>Admin konkurrencer</h1>
  <div class="flex-container">
    <app-dch-select [(selectedValue)]="yearSelectedValue" [selectItems]="yearSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="dchCompetitionDatas"></app-dch-data-table>
</div>
