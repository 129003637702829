<div class="pageHeader">
  <h1>Årets hund</h1>
  <div class="flex-container wrap">
    <app-dch-select style="flex-grow: 2; min-width: 350px" [(selectedValue)]="klasseTypeSelectedValue" [selectItems]="klasseTypeSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
    <app-dch-select style="flex-grow: 1; min-width: 250px" [(selectedValue)]="agilitySizeSelectedValue" [selectItems]="agilitySizeSelectItems" (selectedValueChange)="selectionChangeHandler()"></app-dch-select>
  </div>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Vandrepokal skænket af Michael Houmann<span class="hide-sm"> gives til den ekvipage som opnår flest point i landsdækkende stævner</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="klasseTypeSelectedValue===1">
        <p>
          Titlen og vandrepokalen (skænket af Michael Houmann) til alle størrelser, gives til den ekvipage som opnår flest point i landsdækkende stævner.<br />
          Kun agility klasse 3 er gældende for at opnå point.
        </p>
        <p>
          Nr. 1 i klassen får 15 point, nr. 2 får 12 point, nr. 3 får 10 point, nr. 4 får 8 point osv.med 7, 6, 5, 4, 3, 2, og 1 point.<br />
          Alle fejlfrie placeringer regnes om til point. Ekvipagen skal have deltaget i mindst 3 stævner med 3 x opnåede point.<br />
          Ved pointlighed af flere ekvipager tælles der indtil 5 stævneresultater. Hvis der stadig er pointlighed, vinder den yngste hund. <br />
          Ekvipagen, hund og fører, skal være de samme i hele sæsonen.
        </p>
      </div>

      <div *ngIf="klasseTypeSelectedValue===2">
        <p>
          Titlen og vandrepokalen (skænket af Michael Houmann) til alle størrelser, gives til den ekvipage som opnår flest point i landsdækkende stævner. <br />
          Kun springklassen (åben)er gældende for at opnå point. Hunden skal som minimum være fyldt 7 år på stævnedagen.
        </p>
        <p>
          Nr. 1 i klassen får 15 point, nr. 2 får 12 point, nr. 3 får 10 point, nr. 4 får 8 point osv.med 7, 6, 5, 4, 3, 2, og 1 point.<br />
          Alle placeringer regnes om til point, uanset antal fejl – dog ikke ved en diskvalifikation.<br />
          Ekvipagen skal have deltaget i mindst 3 stævner med 3 x opnåede point.<br />
          Ved pointlighed af flere ekvipager tælles der indtil 5 stævneresultater. Er der stadig pointlighed, vinder den ældste hund. Hund og fører, skal være de samme i hele sæsonen.
        </p>
        <p>
          Gældende fra 2013:<br />
          AGU har besluttet at ensrette reglerne for Årets hund, dvs. at Årets senior hund skal være fejlfri i Spring åben for at få point.
        </p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <h4 *ngIf="agilityDogOfTheYearResponse">
    Udtagelsesperiode: {{agilityDogOfTheYearResponse.startDate | dcHFullDate: agilityDogOfTheYearResponse.endDate}}
  </h4>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="agilityDogOfTheYear" [dataRowDetails]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="dchTableResDetailsContainer">
    <app-dch-data-table [dataTable]="getDcHDataTableDetails()" [dataSource]="data?.results"></app-dch-data-table>
  </div>
</ng-template>
