<div class="pageHeader">
  <h1>Indlæs konkurrencer</h1>
</div>

<div class="pageBody">
  <app-select-dchfile #selectDchFile (selectedChanged)="updateData($event)" [height]="height/3"></app-select-dchfile>
</div>

<div *ngIf="importFileResponse" >
  <div *ngIf="importFileResponse.agilityCompetitionResponses.length>0">
    <app-agility-results [importMode]="true" [competitionResponses]="importFileResponse.agilityCompetitionResponses"></app-agility-results>
  </div>
  <div *ngIf="importFileResponse.brugsCompetitionResponses.length>0">
    <app-brugs-results [importMode]="true" [competitionResponses]="importFileResponse.brugsCompetitionResponses"></app-brugs-results>
  </div>
  <div *ngIf="importFileResponse.lydighedCompetitionResponses.length>0">
    <app-lydighed-results [importMode]="true" [competitionResponses]="importFileResponse.lydighedCompetitionResponses"></app-lydighed-results>
  </div>
  <div *ngIf="importFileResponse.nordiskCompetitionResponses.length>0">
    <app-nordisk-results [importMode]="true" [competitionResponses]="importFileResponse.nordiskCompetitionResponses"></app-nordisk-results>
  </div>
  <div *ngIf="importFileResponse.noseWorkCompetitionResponses.length>0">
    <app-nosework-results [importMode]="true" [competitionResponses]="importFileResponse.noseWorkCompetitionResponses"></app-nosework-results>
  </div>
  <div *ngIf="importFileResponse.rallyCompetitionResponses.length>0">
    <app-rally-results [importMode]="true" [competitionResponses]="importFileResponse.rallyCompetitionResponses"></app-rally-results>
  </div>
  <div *ngIf="importFileResponse.hoopersCompetitionResponses.length>0">
    <app-hoopers-results [importMode]="true" [competitionResponses]="importFileResponse.hoopersCompetitionResponses"></app-hoopers-results>
  </div>
</div>
