<div class="pageHeader">
  <h1>DM udtagelse periode</h1>
</div>

<div class="pageBody">
  <app-dch-data-table [dataTable]="dataTableDef" [dataSource]="dmQualificationPeriods" [editRowData]="dataRowDetails"></app-dch-data-table>
</div>

<ng-template #dataRowDetails let-data>
  <div class="dchCalendarDetailsContainer">
    <h3>Udtagelse periode</h3>
    <div class="dchCalendarDetailsForm">
      <form (ngSubmit)="onFormSubmit(data)" [formGroup]="getFormGroup(data)">
        <div class="flex-container wrap">
          <mat-form-field appearance="fill">
            <mat-label>Start dato</mat-label>
            <input matInput [matDatepicker]="pickerStartDate" [formControl]="startDateFormControl">
            <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerStartDate></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Slut dato</mat-label>
            <input matInput [matDatepicker]="pickerEndDate" [formControl]="endDateFormControl">
            <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerEndDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="saveDchCalendarButton">
          <button mat-raised-button color="primary" type="submit" [disabled]="disableSaveButton || (httpDataService.loading$ | async)"><mat-icon>save</mat-icon> Gem</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
