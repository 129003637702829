import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '../models.generated/google/protobuf/timestamp';
import { DcHDateHelpers } from '../helpers/date.helpers';

@Pipe({
    name: 'dcHFullDate',
    standalone: true
})
export class DcHFullDatePipe implements PipeTransform {

  locale = 'da-DK';

  transform(date1: string | number | Date | Timestamp | undefined, date2: string | number | Date | Timestamp | undefined = undefined, format = 'd. MMM yyyy'): string {

    if (date1 == undefined) {
      return '';
    }
    
    if (date2 == undefined) {
      return new DcHDateHelpers().getFormattedDate(date1, format)
    }

    return new DcHDateHelpers().getFormattedDates(date1, date2, format)
  }
}
