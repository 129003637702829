import { UserInfo } from '../models/user-info';

export class JwtHelper {

  getUserInfo(token: string): UserInfo | null {
    try {
      if (token?.length > 0) {
        const decodedToken = this.decodeToken(token);
        const userInfo = new UserInfo();
        userInfo.username = decodedToken['sub'];
        userInfo.roles = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        return userInfo;
      }
    } catch (error) {
      return null;
    }

    return null;
  }

  private decodeToken(token = ''): any {
    if (token === null || token === '') {
      return { "upn": '' };
    }
    const parts = token.split('.');
    if (parts.length !== 3) {

      throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      // eslint-disable-next-line no-throw-literal
      throw 'Illegal base64url string!';
    }
    return decodeURIComponent((window as any).escape(window.atob(output)));
  }
}
