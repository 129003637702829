import { Component } from '@angular/core';
import { LkuInformationComponent } from '../lku-information/lku-information.component';
import { LatestCompetitionsComponent } from '../latest-competitions/latest-competitions.component';
import { RouterLink } from '@angular/router';
import { AppMaterialModule } from '../../app-material.module';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [AppMaterialModule, RouterLink, LatestCompetitionsComponent, LkuInformationComponent]
})
export class HomeComponent {
}
