import { EkvipageCompetitionResults, EkvipageResults } from '../models.generated/shared';

export class EkvipageResultsMappers {

  static mapToEkvipageCompetitionResults(ekvipageCompetitionResults: EkvipageCompetitionResults[]): EkvipageCompetitionResults[] {
    const classes = EkvipageResultsMappers.getClasses(ekvipageCompetitionResults);

    for (let n = 0; n < ekvipageCompetitionResults.length; n++) {
      ekvipageCompetitionResults[n] = EkvipageResultsMappers.mapToEkvipageWithResult(ekvipageCompetitionResults[n], classes);
    }

    return ekvipageCompetitionResults;
  }

  private static mapToEkvipageWithResult(ekvipageCompetitionResult: EkvipageCompetitionResults, classes: string[]): EkvipageCompetitionResults {

    const myResults = classes.sort().map(a => { return { klasse: a, resultat: '' } as EkvipageResults });

    for (let n = 0; n < myResults.length; n++) {
      const myClass = ekvipageCompetitionResult.results.find(a => a.klasse === myResults[n].klasse);

      if (myClass != undefined) {
        myResults[n].resultat = myClass.resultat;
      }
    }

    ekvipageCompetitionResult.results = myResults;
    return ekvipageCompetitionResult;
  }

  private static getClasses(ekvipageCompetitionResults: EkvipageCompetitionResults[]): string[] {
    let classes = new Array<string>();

    for (let n = 0; n < ekvipageCompetitionResults.length; n++) {
      classes = classes.concat(ekvipageCompetitionResults[n].results.map(a => a.klasse));
    }

    return [...new Set(classes)];
  }
}

