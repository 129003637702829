import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AppMaterialModule } from '../../../app-material.module';
import { DcHDataTableComponent } from '../../../components.shared/dch-data-table/dch-data-table.component';
import { KonkurrenceType } from '../../../models.generated/dchEnums';
import { LydighedETurCandidateResponse } from '../../../models.generated/lydighed';
import { DcHDataTable } from '../../../models/dch-data-table';
import { DcHFullDatePipe } from '../../../pipes/dch-full-date.pipe';
import { HttpDataService } from '../../../services/http-data.service';
import { LydighedETurCandidateWrapper } from './lydighed-etur-candidate-wrapper';

@Component({
  selector: 'app-lydighed-etur-candidates',
  templateUrl: './lydighed-etur-candidates.component.html',
  standalone: true,
  imports: [CommonModule, DcHFullDatePipe, AppMaterialModule, DcHDataTableComponent]
})
export class LydighedETurCandidatesComponent implements OnInit {
  lydighedETurCandidateResponse$: Observable<LydighedETurCandidateResponse>;
  eTurCandidates: LydighedETurCandidateWrapper[];
  dataTableDef = this.getDcHDataTable();

  constructor(private readonly httpDataService: HttpDataService) {

  }

  ngOnInit(): void {
    this.lydighedETurCandidateResponse$ = this.fetchData$()
      .pipe(
        tap(a => this.eTurCandidates = a.eTurCandidates.map(a => (a as any) as LydighedETurCandidateWrapper)));
  }

  private fetchData$(): Observable<LydighedETurCandidateResponse> {
    const url = 'api/LydighedData/GetETurCandidates';
    return this.lydighedETurCandidateResponse$ = this.httpDataService.getData<LydighedETurCandidateResponse>(url);
  }

  private getDcHDataTable(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'primary';
    dataTable.noDataText = 'Ingen kandidater';

    dataTable.columns = [
      { headerText: 'Ekvipage', field: 'dchEkvipage', dataType: 'ekvipageLink' },
      { headerText: 'Race', field: 'dchEkvipage.race', hideSmallScreen: true },
      { headerText: 'Forening', field: 'dchEkvipage.forening', hideSmallScreen: true },
      { headerText: 'Res1', field: 'eResults[0].total', textalign: 'center', dataType: 'number', format: '1.2-2', hideSmallScreen: true },
      { headerText: 'Res2', field: 'eResults[1].total', textalign: 'center', dataType: 'number', format: '1.2-2', hideSmallScreen: true },
      { headerText: 'Res3', field: 'eResults[2].total', textalign: 'center', dataType: 'number', format: '1.2-2', hideSmallScreen: true },
      { headerText: 'Sum', field: 'sumEResults', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' }
    ]

    return dataTable;
  }

  getDcHDataTableDetails(): DcHDataTable {
    let dataTable = new DcHDataTable();
    dataTable.konkurrenceType = KonkurrenceType.LY;
    dataTable.color = 'secondary';

    dataTable.columns = [
      { headerText: 'Dato', field: 'competitionData.konkurrenceDato', textalign: 'center', dataType: 'timeStamp' },
      { headerText: 'Arrangør', field: 'competitionData.forening' },
      { headerText: 'Konkurrencenavn', field: 'competitionData.konkurrenceNavn' },
      { headerText: 'Art', field: 'competitionData.konkurrenceArt', dataType: 'dchKonkurrenceArt', hideSmallScreen: true },
      { headerText: 'Total', field: 'total', textalign: 'center', bold: true, dataType: 'number', format: '1.2-2' }
    ]

    return dataTable;
  }
}
