import { DcHNoseWorkKlasse } from '../models.generated/dchEnums';
import { SelectItem } from '../models/select-item';

export class DcHNoseWorkKlasseHelpers {
  getKlasseSelectItems(): SelectItem<DcHNoseWorkKlasse>[] {

    return [
      new SelectItem<DcHNoseWorkKlasse>(this.getNoseWorkKlasseText(DcHNoseWorkKlasse.NW1), DcHNoseWorkKlasse.NW1),
      new SelectItem<DcHNoseWorkKlasse>(this.getNoseWorkKlasseText(DcHNoseWorkKlasse.NW2), DcHNoseWorkKlasse.NW2),
      new SelectItem<DcHNoseWorkKlasse>(this.getNoseWorkKlasseText(DcHNoseWorkKlasse.NW3), DcHNoseWorkKlasse.NW3),
      new SelectItem<DcHNoseWorkKlasse>(this.getNoseWorkKlasseText(DcHNoseWorkKlasse.NWElite), DcHNoseWorkKlasse.NWElite),
      new SelectItem<DcHNoseWorkKlasse>(this.getNoseWorkKlasseText(DcHNoseWorkKlasse.NWOpen), DcHNoseWorkKlasse.NWOpen),
      new SelectItem<DcHNoseWorkKlasse>(this.getNoseWorkKlasseText(DcHNoseWorkKlasse.NWSenior), DcHNoseWorkKlasse.NWSenior)
    ];
  }

  getNoseWorkKlasseTextAndMomentText(noseworkKlasse: string | DcHNoseWorkKlasse, moment: number): string {
    let text = this.getNoseWorkKlasseText(noseworkKlasse);

    if (moment == 0) {
      return text + ' (total)'
    }

    return text + ` (moment${moment})`;
  }


  getNoseWorkKlasseText(noseworkKlasse: string | DcHNoseWorkKlasse): string {
    let myKlasse = typeof noseworkKlasse === "string" ? DcHNoseWorkKlasse[noseworkKlasse as keyof typeof DcHNoseWorkKlasse] : noseworkKlasse;

    switch (myKlasse) {
      case DcHNoseWorkKlasse.NW1:
        return 'Nose work 1';
      case DcHNoseWorkKlasse.NW2:
        return 'Nose work 2';
      case DcHNoseWorkKlasse.NW3:
        return 'Nose work 3';
      case DcHNoseWorkKlasse.NWElite:
        return 'Nose work Elite';
      case DcHNoseWorkKlasse.NWOpen:
        return 'Nose work Åben';
      case DcHNoseWorkKlasse.NWSenior:
        return 'Nose work Senior';

      default:
        return noseworkKlasse.toString();
    }
  }
}
