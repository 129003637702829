import { DcHBrugsKlasse, DcHLydighedKlasse, DcHNoseWorkKlasse, DcHNoseWorkKlasseMoment, DcHRallyKlasse, KonkurrenceType } from '../models.generated/dchEnums';
import { SelectItem } from '../models/select-item';

export class KonkurrenceTypeHelpers {

  getKonkurrenceTypeSelectItems(): SelectItem<KonkurrenceType>[] {
  
    return [
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.AG), KonkurrenceType.AG),
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.BR), KonkurrenceType.BR),
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.HO), KonkurrenceType.HO),
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.LY), KonkurrenceType.LY),
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.NO), KonkurrenceType.NO),
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.NW), KonkurrenceType.NW),
      new SelectItem<KonkurrenceType>(this.getKonkurrenceTypeText(KonkurrenceType.RA), KonkurrenceType.RA),
    ];
  }

  getKonkurrenceTypeText(konkurrenceType: KonkurrenceType): string {

    switch (konkurrenceType) {
      case KonkurrenceType.AG:
        return 'Agility';
      case KonkurrenceType.BR:
        return 'Brugsprøver';
      case KonkurrenceType.NO:
        return 'Nordisk';
      case KonkurrenceType.LY:
        return 'DcH-Program';
      case KonkurrenceType.RA:
        return 'Rally';
      case KonkurrenceType.NW:
        return 'NoseWork';
      case KonkurrenceType.HO:
        return 'Hoopers';

      default:
        return `(Ukendt: ${konkurrenceType})`;
    }
  }

  getKonkurrenceTypeFromKlasse(klasse: string): KonkurrenceType {

    if (klasse === undefined) {
      return KonkurrenceType.None;
    }

    if (this.isAgilityKlasse(klasse)) {
      return KonkurrenceType.AG;
    }

    if (this.isNordiskKlasse(klasse)) {
      return KonkurrenceType.NO;
    }

    if (this.isLydighedKlasse(klasse)) {
      return KonkurrenceType.LY;
    }

    if (this.isRallyKlasse(klasse)) {
      return KonkurrenceType.RA;
    }

    if (this.isNoseWorkKlasse(klasse)) {
      return KonkurrenceType.NW;
    }

    if (this.isBrugsKlasse(klasse)) {
      return KonkurrenceType.BR;
    }

    return KonkurrenceType.None;
  }

  private isNordiskKlasse(klasse: string) {
    return klasse.toUpperCase().includes('NORDISK');
  }

  private isAgilityKlasse(klasse: string) {
    const klasseUpper = klasse.toUpperCase();
    return klasseUpper.includes('AGILITY') || klasseUpper.includes('SPRING') || klasseUpper.includes('B2O') || klasseUpper.includes('AFINALE') || klasseUpper.includes('BFINALE');
  }

  private isBrugsKlasse(klasse: string): boolean {

    return klasse === DcHBrugsKlasse[DcHBrugsKlasse.IGP1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IGP2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IGP3] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IGPFH] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IFH1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IFH2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IFHV] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.BH] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.AD] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.BegynderB] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.BegynderAB] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IBGH1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IBGH2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.IBGH3] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.FPr1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.FPr2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.FPr3] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.UPr1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.UPr2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.UPr3] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.SPr1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.SPr2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.SPr3] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.StPr1] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.StPr2] ||
      klasse === DcHBrugsKlasse[DcHBrugsKlasse.StPr3];
  }

  private isLydighedKlasse(klasse: string): boolean {

    return klasse === DcHLydighedKlasse[DcHLydighedKlasse.A] ||
      klasse === DcHLydighedKlasse[DcHLydighedKlasse.B] ||
      klasse === DcHLydighedKlasse[DcHLydighedKlasse.C] ||
      klasse === DcHLydighedKlasse[DcHLydighedKlasse.E];
  }

  private isRallyKlasse(klasse: string): boolean {

    return klasse === DcHRallyKlasse[DcHRallyKlasse.Beginner] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.Practiced] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.Expert] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.Open] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.DM1Run1] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.DM1Run2] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.DM1Sum] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.DMFinale] ||
      klasse === DcHRallyKlasse[DcHRallyKlasse.DMTotal];
  }

  private isNoseWorkKlasse(klasse: string): boolean {

    return klasse === DcHNoseWorkKlasse[DcHNoseWorkKlasse.NW1] ||
      klasse === DcHNoseWorkKlasse[DcHNoseWorkKlasse.NW2] ||
      klasse === DcHNoseWorkKlasse[DcHNoseWorkKlasse.NW3] ||
      klasse === DcHNoseWorkKlasse[DcHNoseWorkKlasse.NWElite] ||
      klasse === DcHNoseWorkKlasse[DcHNoseWorkKlasse.NWOpen] ||
      klasse === DcHNoseWorkKlasse[DcHNoseWorkKlasse.NWSenior] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW1Total] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW1Moment1] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW1Moment2] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW1Moment3] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW1Moment4] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW2Total] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW2Moment1] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW2Moment2] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW2Moment3] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW2Moment4] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW3Total] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW3Moment1] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW3Moment2] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW3Moment3] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NW3Moment4] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWEliteTotal] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWEliteMoment1] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWEliteMoment2] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWEliteMoment3] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWEliteMoment4] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWOpenTotal] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWOpenMoment1] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWOpenMoment2] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWOpenMoment3] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWOpenMoment4] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWSeniorTotal] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWSeniorMoment1] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWSeniorMoment2] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWSeniorMoment3] ||
      klasse === DcHNoseWorkKlasseMoment[DcHNoseWorkKlasseMoment.NWSeniorMoment4];
  }
}
